$compFormMap : (
	'.form-group + .form-group, .ligne_form + .ligne_form' : (
		'margin-top' : (
			16px,
			24px
		),
	),
	'.ligne_form.margin' : (
		'margin-bottom' : (
			16px,
			24px
		),
	),
	'.form-group + .form-group.little_margin, .ligne_form + .ligne_form.little_margin' : (
		'margin-top' : (
			8px,
			12px
		),
	),
	'.select_role' : (
		'margin-bottom' : (
			16px,
			24px
		),
	),
	'.form-group label, .form-group mat-label' : (
		'font-size' : (
			13px,
			14px
		),
	),
	'.form-group input[type="text"], .form-group input[type="password"], .form-group input[type="email"], .form-group input[type="tel"], .form-group input[type="number"], .form-group select, .form-group textarea' : (
		'height' : (
			42px,
			48px
		),
		'font-size' : (
			13px,
			14px
		),
	),
	'.form-group input[type="text"] ~ label, .form-group input[type="password"] ~ label, .form-group input[type="email"] ~ label, .form-group input[type="tel"] ~ label, .form-group input[type="number"] ~ label, .form-group select ~ label, .form-group textarea ~ label' : (
		'font-size' : (
			13px,
			14px
		),
		'top' : (
			13px,
			16px
		),
	),
	'.ligne_form .col_4 .form-group input[type=text] ~ label, .ligne_form .col_4 .form-group input[type=password] ~ label, .ligne_form .col_4 .form-group input[type=email] ~ label, .ligne_form .col_4 .form-group input[type=tel] ~ label, .ligne_form .col_4 .form-group input[type=number] ~ label, .ligne_form .col_4 .form-group select ~ label, .ligne_form .col_4 .form-group textarea ~ label' : (
		'font-size' : (
			11px,
			12px
		),
		'top' : (
			6px,
			8px
		),
	),
	'.form-group mat-select' : (
		'height' : (
			42px,
			48px
		),
		'line-height' : (
			40px,
			46px
		),
		'font-size' : (
			13px,
			14px
		),
	),
	'.form-group mat-select ~ mat-label' : (
		'font-size' : (
			13px,
			14px
		),
		'top' : (
			13px,
			16px
		),
	),
	'app-input-date .mat-mdc-form-field-flex .mat-mdc-form-field-infix input.mat-mdc-input-element' : (
		'height' : (
			42px,
			48px
		),
		'font-size' : (
			13px,
			14px
		),
	),
	'app-input-date .mat-mdc-form-field-flex .mat-mdc-form-field-infix input ~ .mat-mdc-form-field-label-wrapper' : (
		'font-size' : (
			13px,
			14px
		),
		'top' : (
			13px,
			16px
		),
	),
	'.form-group.color .show_color' : (
		'height' : (
			42px,
			48px
		),
		'width' : (
			42px,
			48px
		),
	),
	'.form-group.color' : (
		'padding-right' : (
			50px,
			56px
		),
	),
	'.form-group .addon_input' : (
		'height' : (
			42px,
			48px
		),
		'font-size' : (
			13px,
			14px
		),
	),
	'.form-group .autocomplete-trigger' : (
		'height' : (
			42px,
			48px
		),
		'width' : (
			42px,
			48px
		),
	)
);

@include fluid($compFormMap);

.form-group, .ligne_form {
	&:empty {
		display : none;
		margin  : 0;
	}
}

.form-group {
	display    : block;
	width      : 100%;
	position   : relative;
	text-align : left;
	min-height : 20px;

	label {
		display       : block;
		line-height   : 16px;
		color         : $noir;
		margin-bottom : 8px;
		@include font_lato_bold;

		&.required {
			&:after {
				content : ' *';
				color   : $danger;
			}
		}

		.green {
			color : $vert;
		}

		.modify {
			color : $rouge;
		}
	}

	.ck-voice-label {
		display : none;
	}

	input[type="text"], input[type="password"], input[type="email"], input[type="tel"], input[type="number"], select, textarea {
		display    : block;
		width      : 100%;
		position   : relative;
		z-index    : 2;
		border     : 1px solid rgba($grisfonce, 0.5);
		@include font_lato_regular;
		color      : $noir;
		@include radius(4px);
		padding    : 0 16px;
		@include transition($transition);
		box-sizing : border-box;

		~ label {
			position    : absolute;
			z-index     : 1;
			left        : 16px;
			right       : 8px;
			line-height : 16px;
			color       : rgba($noir, 0.5);
			@include font_lato_regular;
			margin      : 0;
			@include transition($transition);
		}

		&:required {
			~ label {
				&:after {
					content : ' *';
					color   : $danger;
				}
			}
		}

		&:focus {
			border-color : $grisfonce;
		}

		&:disabled {
			cursor     : default;
			background : $gris;

			~ label {
				z-index : 2;
			}

			&:focus {
				border-color : rgba($grisfonce, 0.5);
			}
		}
	}

	input[type="text"], input[type="password"], input[type="email"], input[type="tel"], input[type="number"] {
		&:not(.datetimepicker) {
			&:read-only {
				cursor     : default;
				background : $gris;

				~ label {
					z-index : 2;
				}

				&:focus {
					border-color : rgba($grisfonce, 0.5);
				}
			}
		}
	}

	textarea {
		max-width  : 100%;
		min-width  : 100%;
		min-height : 150px;
	}

	select {
		background      : transparent url("../../images/chevron.svg") no-repeat center right 16px;
		background-size : 10px;
		padding-right   : 24px;
	}

	input[type='password'] {
		letter-spacing : 2.5px;
	}

	input[type='text']:not(:placeholder-shown), input[type='text']:focus, input[type='password']:not(:placeholder-shown), input[type='password']:focus, input[type='email']:not(:placeholder-shown), input[type='email']:focus, input[type='tel']:not(:placeholder-shown), input[type='tel']:focus, input[type='number']:not(:placeholder-shown), input[type='number']:focus, select:not(:invalid), select:focus {
		padding-top : 10px;

		~ label {
			top       : 6px;
			font-size : 11px;
		}
	}

	textarea {
		padding-top : 20px;

		&:focus, &:not(:placeholder-shown) {
			~ label {
				top       : 6px;
				font-size : 11px;
			}
		}
	}

	input[type='text']:not(:-ms-input-placeholder), input[type='password']:not(:-ms-input-placeholder), input[type='email']:not(:-ms-input-placeholder), input[type='tel']:not(:-ms-input-placeholder), input[type='number']:not(:-ms-input-placeholder), textarea:not(:-ms-input-placeholder) {
		padding-top : 10px;

		~ label {
			top       : 6px;
			font-size : 11px;
		}
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow : 0 0 0 1000px transparent inset !important;
		transition         : background-color 5000s ease-in-out 0s !important;
	}
	mat-select {
		display  : block;
		width    : 100%;
		position : relative;
		z-index  : 2;
		border   : 1px solid rgba($grisfonce, 0.5);
		color    : $noir;
		@include radius(4px);
		padding  : 0;
		@include transition($transition);
		.mat-mdc-select-trigger {
			display : block;
			width   : 100%;
			height  : 100%;
			padding : 0 16px;
			.mat-mdc-select-value {
				display        : inline-block;
				vertical-align : middle;
				width          : 100%;
				max-width      : inherit;
				line-height    : 18px;
				overflow       : hidden;
				white-space    : nowrap;
				text-overflow  : ellipsis;
				.mat-mdc-select-placeholder {
					display : none;
				}
			}
			.mat-mdc-select-arrow-wrapper {
				display : none;
			}
		}

		~ mat-label {
			position    : absolute;
			z-index     : 1;
			left        : 16px;
			line-height : 16px;
			color       : rgba($noir, 0.5);
			@include transition($transition);

			&.required {
				&:after {
					content        : ' *';
					color          : $danger;
					font-size      : 0.7em;
					display        : inline-block;
					vertical-align : middle;
					margin-left    : 4px;
					margin-top     : -4px;
				}
			}
		}
		&:not(.mat-mdc-select-empty) {
			.mat-mdc-select-trigger {
				padding-top : 6px;
			}

			~ mat-label {
				top       : 6px;
				font-size : 11px;
			}
		}
		&.no_label .mat-mdc-select-trigger {
			padding-top : 0 !important;
		}
	}

	.radio, .checkbox {
		display    : block;
		margin-top : 8px;
		position   : relative;
		min-height : 18px;

		&:not(:last-child) {
			margin-right : 32px;
		}

		&.min-size {
			width : max-content;
		}

		label {
			margin       : 0;
			@include font_lato_regular;
			padding-left : 30px;
			position     : relative;
			z-index      : 2;
			cursor       : pointer;

			&:before {
				content : '';
				@include y_align_absolute;
				left    : 0;
				width   : 20px;
				height  : 20px;
				border  : 1px solid $grisfonce;
				@include transition($transition);
			}

			&:after {
				content    : '';
				@include y_align_absolute;
				left       : 4px;
				width      : 12px;
				height     : 12px;
				background : $vert;
				opacity    : 0;
				@include transition($transition);
			}

			&.extra {
				&:before {
					transform : translateY(0);
				}
				&:after {
					transform : translateY(4px);
				}
			}

		}

		input {
			position : absolute;
			left     : 0;
			top      : 0;
			z-index  : 1;
			width    : 0;
			height   : 0;
			padding  : 0;
			margin   : 0;

			&:checked {
				~ label {
					&:before {
						border-color : $vert;
					}

					&:after {
						opacity : 1;
					}
				}
			}
		}

		a {
			text-decoration : underline;
			@include hover {
				color : $vert;
			}
		}

		mat-icon {
			vertical-align : middle;
			margin-left    : 4px;
			margin-top     : -2px;
		}

		&.disabled label, input:disabled ~ label {
			cursor  : default;
			opacity : 0.4;

			&:before {
				border-color : rgba($grisfonce, 0.6) !important;
			}

			&:after {
				background : rgba($grisfonce, 0.6) !important;
			}
		}

		+ .message-help {
			margin-top : 16px;

			mat-icon {
				width        : 20px;
				height       : 20px;
				margin-left  : -1px;
				font-size    : 22px;
				margin-right : 12px;
			}
		}

	}

	.radio {
		display        : inline-block;
		vertical-align : middle;

		label {
			&:before, &:after {
				@include radius(50%);
			}
		}

		&.block {
			display : block;
		}
	}
	mat-radio-group {
		display    : block;
		margin-top : 8px;
		position   : relative;
		min-height : 18px;
		mat-radio-button {
			label {
				margin  : 0;
				display : flex;
			}
			.mat-ripple {
				display : none !important;
			}
			.mdc-radio {
				padding : 0;
			}
			.mdc-radio__background {
				&:before {
					display : none;
				}
				.mdc-radio__outer-circle {
					width        : 18px;
					height       : 18px;
					border-color : rgba($grisfonce, 0.5)
				}
				.mdc-radio__inner-circle {
					width  : 18px;
					height : 18px;
					top    : -1px;
					left   : -1px;
				}
			}
			&.mat-mdc-radio-checked, &:active {
				.mdc-radio__background {
					.mdc-radio__outer-circle {
						border-color : $vert !important;
					}
					.mdc-radio__inner-circle {
						border-color : $vert !important;
					}
				}
			}
		}
	}

	.form_password {
		position : relative;

		mat-icon {
			position  : absolute;
			right     : 10px;
			top       : 50%;
			transform : translateY(-50%);
			cursor    : pointer;
			z-index   : 2;
		}
	}

	app-toggle, .app-toggle {
		.mat-mdc-slide-toggle {
			width : 100%;

			.mdc-switch__shadow, .mdc-switch__ripple, .mdc-switch__icons {
				display : none !important;
			}

			.mdc-switch {
				width : 48px;
				.mdc-switch__track {
					display        : inline-block;
					vertical-align : middle;
					width          : 100%;
					height         : 8px;
					@include radius(4px);
					background     : rgba($grisfonce, 0.05);
					margin         : 0 2px;
					opacity        : 1;
					&:before, &:after {
						display : none;
					}
				}
				.mdc-switch__handle {
					width      : 20px;
					height     : 20px;
					top        : -6px;
					left       : -2px;
					@include radius(50%);
					background : $vertclair;
					@include transform(translateX(0) !important);
					@include transition($transition);
					&:before, &:after {
						display : none;
					}
				}
				&.mdc-switch--disabled {
					&, ~ label {
						opacity : 0.4;
					}
				}
			}
			.mat-mdc-slide-toggle-content {
				display        : inline-block;
				vertical-align : middle;
				font           : inherit;
				margin-left    : 8px;
				white-space    : normal;
				max-width      : calc(100% - 80px);
			}

			label {
				margin : 0;
			}

			&.mat-mdc-slide-toggle-checked {

				.mdc-switch {
					.mdc-switch__track {
						background : rgba($grisfonce, 0.05);
					}
					.mdc-switch__handle {
						background : $vert;
					}
				}
			}
		}
	}

	.checkbox.inline {
		display        : inline-block;
		vertical-align : middle;
	}

	&.date_range {
		.mat-date-range-input {
			display : block;
			width   : 100%;

			.mat-date-range-input-container {
				@extend .row;
				@extend .x_between;

				.mat-date-range-input-separator, .mat-date-range-input-mirror {
					display : none;
				}

				input[type='text'] {
					padding-top : 0 !important;
				}

				> div {
					@extend .col_6;
					max-width : calc(50% - 12px);
				}
			}
		}
	}

	&.color {
		.show_color {
			position : absolute;
			right    : 0;
			top      : 0;
			border   : 1px solid rgba($grisfonce, 0.5);
			@include radius(4px);
		}
	}

	media-file {
		media-file-upload {
			display : inline-block;

			.btn_cta {
				display        : inline-block;
				vertical-align : middle;
				width          : auto;
				padding-bottom : 0;

				.mat-icon {
					display        : inline-block;
					vertical-align : middle;
					margin-right   : 12px;
					font-size      : 20px;
					width          : 20px;
					height         : 20px;
					margin-top     : -2px;
				}
			}

			.upload_file {
				margin-bottom : 0 !important;

				.info {
					font-size : 13px;
					color     : $grisfonce;
				}
			}

			.loader-files .file {
				margin-top  : 8px;
				margin-left : 8px;

				.mat-mdc-progress-bar {
					height   : 100%;
					position : absolute;
					left     : 0;
					top      : 0;
					.mdc-linear-progress__buffer {
						height : 100%;
						.mdc-linear-progress__buffer-bar {
							background : $grisclair;
							height     : 100%;
						}
					}
					.mdc-linear-progress__bar {
						background : $grisclair;
						height     : 100%;
					}
					.mdc-linear-progress__bar-inner {
						height       : 100%;
						border-color : $vert;
						background   : $vert;
					}
				}
			}
		}

		.file-component {
			display        : inline-block;
			vertical-align : middle;
			padding        : 5px;
			background     : $gris;
			margin-left    : 16px;
			@include radius(4px);

			input {
				font-size  : 12px;
				height     : 35px;
				padding    : 0 8px !important;
				background : $blanc;
			}

			button {
				display        : inline-block;
				vertical-align : middle;

				.mat-icon {
					font-size   : 20px;
					line-height : 24px;
					opacity     : 0.6;
					@include hover {
						opacity : 1;
					}
				}
			}
		}

		~ mat-error {
			padding : 0;
		}
	}

	.ck.ck-editor__main > .ck-editor__editable {
		min-height : 150px;

		//ul, ol {
		//    list-style   : initial;
		//    padding-left : 20px;
		//
		//    li {
		//        display    : list-item;
		//        list-style : initial;
		//    }
		//}
		//
		//ol li {
		//    list-style : decimal;
		//}
	}

	.message {
		display        : flex;
		flex-direction : column-reverse;
	}

	.message-help {
		@include d_flex;
		@include y_items_center;
		font-size     : 13px;
		color         : $grisfonce;
		margin        : 0;
		margin-top    : 8px;
		margin-bottom : 8px;

		mat-icon {
			display        : inline-block;
			vertical-align : middle;
			margin-right   : 5px;
			margin-top     : -2px;
			overflow       : visible;
		}
		strong {
			font-weight : 500;
		}
		&.background {
			border     : 1px solid #666666;
			margin-top : 15px;
			background : #eaeaea;
			padding    : 10px;
		}
		&.error {
			color : $danger;
		}
		.modify, .no_modify {
			font-size      : 1.1em;
			padding-bottom : 0.1em;
		}

		a {
			color           : $noir;
			text-decoration : underline;
		}
	}

	mat-error {
		padding : 0 16px;
		&:before {
			display : none;
		}

		div {
			font-size   : 11px;
			line-height : 12px;
			margin-top  : 6px;
		}
		&:empty {
			display : none;
		}
	}

	.btn_cta:not(.not_full) {
		width : 100%;
	}

	.title-form {
		@include font_lato_bold;
		font-size     : 18px;
		line-height   : 26px;
		color         : $grisfonce;
		margin-bottom : 16px;
	}

	.read {
		font-size   : 14px;
		line-height : 20px;
		margin      : 0;

		+ .read {
			margin-top : 8px;
		}

		strong {
			@include font_lato_black;
		}

		.subtitle_green {
			color : $vert;
		}

		.list {
			display    : block;
			margin-top : 4px;
		}

		&.confirm {
			color : $vert;
		}

		&.failed {
			color : $rouge;
		}

		&.title {
			font-size   : 16px;
			line-height : 24px;
		}
	}

	.read_composition_option,
	.read_composition_child,
	.read_affected_gift_voucher {
		display : block;
		padding : 8px 12px;
		border  : 1px solid rgba($grisfonce, 0.3);
		margin  : 8px 0;
	}

	.affected_gift_vouchers {
		background : $gris;
		padding    : 8px 16px;
		margin-top : 15px;
	}

	.total {
		font-size   : 15px;
		line-height : 22px;
		margin      : 0;

		span {
			@include font_lato_bold;
			font-size : 24px;
		}

		span.total_ht {
			display   : inline-block;
			font-size : 0.7em;
			color     : $noir;
		}
	}

	.bold {
		@include font_lato_bold;
	}

	.addon_input {
		border     : 1px solid rgba($grisfonce, 0.5);
		background : $gris;
		@include font_lato_regular;
		color      : $noir;
		@include radius(4px);
		padding    : 0 16px;
		cursor     : default;

		&:first-child {
			@include radius(4px 0 0 4px);
			border-right : 0;
		}

		&:last-child {
			@include radius(0 4px 4px 0);
			border-left : 0;
		}

		span {
			font-weight : bold;
		}
	}

	.content_input {
		position : relative;

		&:not(:first-child) input {
			border-top-left-radius    : 0;
			border-bottom-left-radius : 0;
		}

		&:not(:last-child) input {
			border-top-right-radius    : 0;
			border-bottom-right-radius : 0;
		}

		.tooltip_icon {

			&_edit {
				position : absolute;
				top      : 25%;
				right    : 10px;
				z-index  : 2;
				cursor   : pointer;

				&:hover {
					color : $vert;
				}
			}
		}
	}

	.status {
		margin-top    : 8px;
		margin-bottom : 0;
		font-size     : 13px;
		line-height   : 16px;
		span {
			font-weight : bold;
		}
		&.waiting, &.created {
			span {
				color : $warning;
			}
		}
		&.validated {
			span {
				color : $success;
			}
		}
		&.refused, &.outdated {
			span {
				color : $danger;
			}
		}
	}

	label.phone-label {
		position    : absolute;
		z-index     : 1;
		left        : 80px;
		right       : 8px;
		line-height : 16px;
		color       : rgba($noir, 0.5);
		@include font_lato_regular;
		margin      : 0;
		@include transition($transition);
		top         : 6px;
		font-size   : 11px;
		~ ngx-intl-tel-input {
			input {
				padding-top : 10px !important;
			}
		}
	}

	ngx-intl-tel-input {
		input {
			height        : 44px;
			margin-bottom : 20px;
			padding       : 10px;
			border-style  : solid;
			border-width  : 1px;
			border-color  : #c7cace;
			border-radius : 4px;
			font-size     : 18px;
		}
	}

	ngx-intl-tel-input input:hover {
		box-shadow : 0 1px 0 0 rgba(0, 0, 0, 0.24);
	}

	ngx-intl-tel-input input:focus {
		outline      : none !important;
		border-color : #3498db;
		box-shadow   : 0 0 0 0 #000000;
	}

	ngx-intl-tel-input input::-webkit-input-placeholder {
		color : #bac2c7;
	}

	ngx-intl-tel-input input:-ms-input-placeholder {
		color : #bac2c7;
	}

	ngx-intl-tel-input input::-ms-input-placeholder {
		color : #bac2c7;
	}

	ngx-intl-tel-input input::placeholder {
		color : #bac2c7;
	}

	ngx-intl-tel-input input[disabled] {
		background-color : #e5eaf1;
	}

	&.indent_left {
		padding-left : 29px;
	}

	&.align_insurance {
		margin-top : 14px;
	}

	mat-select[ng-reflect-name="responsibleUser"] .mat-mdc-select-trigger, mat-select[ng-reflect-name="offerCreatorResponsibleUser"] .mat-select-trigger {
		padding-top : 0;
	}

	mat-form-field {
		width : 100%;
		.mat-mdc-text-field-wrapper {
			padding    : 0;
			background : transparent;
			.mat-mdc-form-field-focus-overlay, .mdc-line-ripple {
				display : none;
			}
			.mat-mdc-form-field-infix {
				border  : none;
				padding : 0;
				.mdc-floating-label {
					left        : 16px;
					right       : 8px;
					line-height : 16px;
					color       : rgba($noir, 0.5) !important;
					@include font_lato_regular;
					margin      : 0;
					font-size   : 14px;
					top         : 23px;
					width       : auto;
					@include transition($transition);
					.mat-mdc-form-field-required-marker {
						color : $danger;
					}
					&.mdc-floating-label--float-above {
						font-size : 11px;
						transform : none;
						top       : 6px;
						mat-label {
							font-size : inherit;
						}
					}
				}
			}
			.mat-mdc-form-field-label-wrapper {
				top : 0;

				label {
					left        : 16px;
					right       : 8px;
					line-height : 16px;
					color       : rgba($noir, 0.5) !important;
					@include font_lato_regular;
					margin      : 0;
					font-size   : 14px;
					top         : 16px;
					width       : auto;
					@include transition($transition);
					.mat-mdc-form-field-required-marker {
						color : $danger;
					}
					&.mdc-floating-label--float-above {
						font-size : 11px;
						transform : none;
						top       : 6px;
					}
				}
			}
			.mat-mdc-form-field-underline {
				display : none;
			}
		}
		.mat-mdc-form-field-subscript-wrapper {
			display : none;
		}
	}
}

.form_part {
	display : block;
	width   : 100%;

	+ .form_part {
		margin-top : 48px;
	}

	.title_part {
		display       : block;
		@include font_lato_bold;
		font-size     : 18px;
		line-height   : 24px;
		color         : $grisfonce;
		margin-bottom : 24px;
		padding       : 16px;
		background    : rgba($vert, 0.2);

		span {
			display     : block;
			font-size   : 14px;
			line-height : 18px;
			@include font_lato_regular;
			margin-top  : 8px;
		}

		&.gradient {
			@include degrade(rgba($vert, 0.2), rgba($vert, 0), 70%, 100%);
		}

		&.form_panel {
			cursor   : pointer;
			position : relative;
			&.open {
				&:after {
					content       : "\e004";
					font-family   : "ty-win";
					position      : absolute;
					margin-top    : 6px;
					right         : 0;
					font-size     : 12px;
					height        : 12px;
					line-height   : 12px;
					width         : 12px;
					text-align    : center;
					color         : #1d2028;
					padding-right : 20px;
					transition    : all 0.25s ease-in-out;
					transform     : rotateX(-180deg);
				}
			}
			&.closed {
				&:after {
					content       : "\e004";
					font-family   : "ty-win";
					position      : absolute;
					margin-top    : 6px;
					right         : 0;
					font-size     : 12px;
					height        : 12px;
					line-height   : 12px;
					width         : 12px;
					text-align    : center;
					color         : #1d2028;
					padding-right : 20px;
					transition    : all 0.25s ease-in-out;
				}
			}
			> div {
				&.valid {
					&:after {
						position      : absolute;
						padding-right : 40px;
						right         : 0;
						content       : 'check';
						font-family   : "Material Icons";
						color         : $success;
					}
				}

				&.invalid {
					&:after {
						position      : absolute;
						padding-right : 40px;
						right         : 0;
						content       : 'close';
						font-family   : "Material Icons";
						color         : $danger;
					}
				}
			}
		}

	}

	.mb_24 {

		margin-bottom : 24px;
	}

	&.limited {
		width     : 95%;
		max-width : 1200px;
		mat-expansion-panel {
			padding-right : 5px;
		}
		.btn_cta.remove_item {
			padding : 0 12px;
			width   : 48px;
			mat-icon {
				width     : 20px;
				height    : 20px;
				font-size : 20px;
			}
		}
	}

}

app-input-date {
	display : block;
	width   : 100%;
	.mat-mdc-form-field {
		width : 100%;
	}
	.mat-mdc-form-field-underline, .mat-mdc-form-field-subscript-wrapper, .mdc-line-ripple, .mat-mdc-form-field-focus-overlay {
		display : none !important;
	}
	.mat-mdc-form-field-wrapper, .mat-mdc-text-field-wrapper {
		padding    : 0;
		background : transparent !important;
	}
	.mat-mdc-form-field-flex {
		display : block;
		.mat-mdc-form-field-infix {
			width      : 100%;
			margin     : 0;
			border     : none;
			padding    : 0 !important;
			min-height : inherit;
			input.mat-mdc-input-element {
				display    : block;
				width      : 100%;
				position   : relative;
				z-index    : 1;
				border     : 1px solid rgba($grisfonce, 0.5);
				@include font_lato_regular;
				color      : $noir;
				@include radius(4px);
				padding    : 0 40px 0 16px;
				@include transition($transition);
				box-sizing : border-box;
				~ .mat-mdc-form-field-label-wrapper {
					position : absolute;
					top      : 0;
					padding  : 0;
					height   : auto;
					overflow : visible;
					z-index  : 2;
					.mat-placeholder-required {
						display : none;
					}
				}
				~ .mat-mdc-form-field-label-wrapper label {
					position    : absolute;
					z-index     : 1;
					left        : 16px;
					right       : 8px;
					top         : 6px;
					font-size   : 11px;
					line-height : 16px;
					width       : auto;
					color       : rgba($noir, 0.5);
					@include font_lato_regular;
					margin      : 0;
					@include transition($transition);
					transform   : scale(1);
				}

				&:not(:placeholder-shown) {
					padding-top : 10px;
				}
				&::placeholder {
					color : transparent !important;
				}

				&:required {
					~ .mat-mdc-form-field-label-wrapper label {
						&:after {
							content : ' *';
							color   : $danger;
						}
					}
				}

				&:focus {
					border-color : $grisfonce;
				}

				&:disabled {
					cursor     : default;
					background : $gris;
					~ .mat-mdc-form-field-label-wrapper label {
						z-index : 2;
					}

					&:focus {
						border-color : rgba($grisfonce, 0.5);
					}
				}
			}
			.mdc-floating-label {
				left        : 16px;
				right       : 8px;
				line-height : 16px;
				color       : rgba($noir, 0.5);
				@include font_lato_regular;
				margin      : 0;
				font-size   : 14px;
				top         : 23px;
				width       : auto;
				@include transition($transition);
				.mat-mdc-form-field-required-marker {
					color : $danger;
				}
				&.mdc-floating-label--float-above {
					font-size : 11px;
					transform : none;
					top       : 6px;
				}
			}
		}
		.mat-mdc-form-field-icon-suffix {
			@include y_align_absolute;
			right     : 8px;
			z-index   : 3;
			font-size : 16px;
			.mat-mdc-button-persistent-ripple, .mat-ripple {
				display : none;
			}
		}
	}
}

app-select, app-select-search {
	.form-group {

		mat-form-field {
			width : 100%;
			.mat-mdc-text-field-wrapper {
				padding    : 0;
				background : transparent;
				.mat-mdc-form-field-focus-overlay, .mdc-line-ripple {
					display : none;
				}
				.mat-mdc-form-field-infix {
					border  : none;
					padding : 0;
					.mdc-floating-label {
						left        : 16px;
						right       : 8px;
						line-height : 16px;
						color       : rgba($noir, 0.5);
						@include font_lato_regular;
						margin      : 0;
						font-size   : 14px;
						top         : 23px;
						width       : auto;
						@include transition($transition);
						.mat-mdc-form-field-required-marker {
							color : $danger;
						}
						&.mdc-floating-label--float-above {
							font-size : 11px;
							transform : none;
							top       : 6px;
						}
					}
				}
				.mat-mdc-form-field-label-wrapper {
					top : 0;

					label {
						left        : 16px;
						right       : 8px;
						line-height : 16px;
						color       : rgba($noir, 0.5);
						@include font_lato_regular;
						margin      : 0;
						font-size   : 14px;
						top         : 16px;
						width       : auto;
						@include transition($transition);
						.mat-mdc-form-field-required-marker {
							color : $danger;
						}
						&.mdc-floating-label--float-above {
							font-size : 11px;
							transform : none;
							top       : 6px;
						}
					}
				}
				.mat-mdc-form-field-underline {
					display : none;
				}
			}
			.mat-mdc-form-field-subscript-wrapper {
				display : none;
			}
		}
	}
}

mat-error {
	display : block;

	div {
		display     : block;
		@include font_lato_bold;
		font-size   : 14px;
		line-height : 18px;

		&.invalid-feedback {
			color : $danger;
		}
	}
}

.ligne_form .col_4 .form-group {
	input[type='text']:not(:placeholder-shown), input[type='text']:focus, input[type='password']:not(:placeholder-shown), input[type='password']:focus, input[type='email']:not(:placeholder-shown), input[type='email']:focus, input[type='tel']:not(:placeholder-shown), input[type='tel']:focus, input[type='number']:not(:placeholder-shown), input[type='number']:focus, select:not(:invalid), select:focus, textarea:focus, textarea:not(:placeholder-shown) {
		~ label {
			top           : 6px;
			white-space   : nowrap;
			overflow      : hidden;
			text-overflow : ellipsis;
			font-size     : 10px;
		}
	}

	input[type='text']:not(:-ms-input-placeholder), input[type='password']:not(:-ms-input-placeholder), input[type='email']:not(:-ms-input-placeholder), input[type='tel']:not(:-ms-input-placeholder), input[type='number']:not(:-ms-input-placeholder), textarea:not(:-ms-input-placeholder) {
		~ label {
			top           : 6px;
			white-space   : nowrap;
			overflow      : hidden;
			text-overflow : ellipsis;
			font-size     : 10px;
		}
	}
}

.ligne_form .col_6 .form-group {
	.radio, .checkbox {
		margin-top : 0;
	}
}

div.mat-mdc-select-panel {
	padding    : 0;
	min-width  : 100%;
	width      : auto;
	max-height : 300px;
}

mat-option.mat-mdc-option {
	@extend .d_flex;
	padding    : 0 16px;
	min-height : 2.5em;
	> span {
		display        : block;
		width          : 100%;
		height         : 100%;
		font-size      : 12px;
		letter-spacing : normal;
	}
	ngx-mat-select-search {
		display    : block;
		width      : 100%;
		height     : 45px;
		position   : relative;
		background : $blanc;

		&:not(:first-child) input {
			border-left : 1px solid rgba($grisfonce, 0.3);
		}

		input {
			display       : block;
			width         : 100%;
			height        : 100% !important;
			padding       : 0 !important;
			border-bottom : 1px solid rgba($grisfonce, 0.3);
			&.mat-select-search-hidden {
				position : absolute;
				top      : 0;
				left     : 0;
				right    : 0;
				bottom   : 0;
			}
		}
		.mat-select-search-inner, .mat-select-search-inner-row {
			display  : block;
			position : relative !important;
			width    : 100% !important;
			height   : 100%;
		}
	}
	.mat-ripple, .mat-divider {
		display : none;
	}
}

mat-option {
	font-size      : 14px;
	line-height    : 2em;
	min-height     : 3em;
	padding-top    : 0.5em;
	padding-bottom : 0.5em;
	.mdc-list-item__primary-text {
		line-height : 18px;
	}
	.mat-ripple {
		display : none !important;
	}
	&:not(.mat-mdc-option-multiple) {
		mat-pseudo-checkbox {
			display : none;
		}
	}
	.flag + .text {
		margin-left : 4px;
	}
	&.mdc-list-item--selected {
		span {
			color       : $noir !important;
			font-weight : 500;
		}
	}
	&[data-cf-mat-option-value*="font-"] {
		font-size   : 18px !important;
		line-height : 40px !important;
		height      : 40px !important;
		padding     : 0 16px;
		span {
			display        : inline-block;
			vertical-align : middle;
			line-height    : 20px;
			font-size      : 16px;
			height         : auto;
		}
	}
	&[data-cf-mat-option-value="font-lato"] {
		@include font_lato_regular;
	}
	&[data-cf-mat-option-value="font-roboto"] {
		@include font_roboto;
	}
	&[data-cf-mat-option-value="font-source"] {
		@include font_source;
	}
	&[data-cf-mat-option-value="font-nunito"] {
		@include font_nunito;
	}
	&[data-cf-mat-option-value="font-ubuntu"] {
		@include font_ubuntu;
	}
	&[data-cf-mat-option-value="font-raleway"] {
		@include font_raleway;
	}
	&[data-cf-mat-option-value="font-caveat"] {
		@include font_caveat;
	}
	&[data-cf-mat-option-value="font-amatic"] {
		@include font_amatic;
	}
	&[data-cf-mat-option-value="font-poiretone"] {
		@include font_poiretone;
	}
	&[data-cf-mat-option-value="font-opensans"] {
		@include font_opensans;
	}
	&[data-cf-mat-option-value="font-montserrat"] {
		@include font_montserrat;
	}
	&[data-cf-mat-option-value="font-oswald"] {
		@include font_oswald;
	}
	&[data-cf-mat-option-value="font-slabo"] {
		@include font_slabo;
	}
	&[data-cf-mat-option-value="font-ptsans"] {
		@include font_ptsans;
	}
	&[data-cf-mat-option-value="font-merriweather"] {
		@include font_merriweather;
	}
	&[data-cf-mat-option-value="font-notosans"] {
		@include font_notosans;
	}
	&[data-cf-mat-option-value="font-concertone"] {
		@include font_concertone;
	}
	&[data-cf-mat-option-value="font-prompt"] {
		@include font_prompt;
	}
	&[data-cf-mat-option-value="font-worksans"] {
		@include font_worksans;
	}
	&[data-cf-mat-option-value="font-ooohbaby"] {
		@include font_ooohbaby;
	}
	&[data-cf-mat-option-value="font-badscript"] {
		@include font_badscript;
	}
	&[data-cf-mat-option-value="font-dancingscript"] {
		@include font_dancingscript;
	}
	&[data-cf-mat-option-value="font-grotesk"] {
		@include font_grotesk;
	}
	&[data-cf-mat-option-value="font-abel"] {
		@include font_abel;
	}
	&[data-cf-mat-option-value="font-adventpro-light"] {
		@include font_adventpro_light;
	}
	&[data-cf-mat-option-value="font-josh"] {
		@include font_jost;
	}
	&[data-cf-mat-option-value="font-lora"] {
		@include font_lora;
	}
	&[data-cf-mat-option-value="font-montserrat-light"] {
		@include font_montserrat_light;
	}
	&[data-cf-mat-option-value="font-montserrat-sbold"] {
		@include font_montserrat_sbold;
	}
	&[data-cf-mat-option-value="font-robotocondensed"] {
		@include font_robotocondensed;
	}
	&[data-cf-mat-option-value="font-robotomono"] {
		@include font_robotomono;
	}
	&[data-cf-mat-option-value="font-robotoslab"] {
		@include font_robotoslab;
	}
	&[data-cf-mat-option-value="font-rubik"] {
		@include font_rubik;
	}
	&[data-cf-mat-option-value="font-dosis"] {
		@include font_dosis;
	}
	&[data-cf-mat-option-value="font-oxygen"] {
		@include font_oxygen;
	}
	&[data-cf-mat-option-value="font-dmsans"] {
		@include font_dmsans;
	}
	&[data-cf-mat-option-value="font-firasans"] {
		@include font_firasans;
	}
	&[data-cf-mat-option-value="font-siliguri"] {
		@include font_siliguri;
	}
	&[data-cf-mat-option-value="font-inconsolata"] {
		@include font_inconsolata;
	}
	&[data-cf-mat-option-value="font-kanit"] {
		@include font_kanit;
	}
	&[data-cf-mat-option-value="font-karla"] {
		@include font_karla;
	}
	&[data-cf-mat-option-value="font-manrope"] {
		@include font_manrope;
	}
	&[data-cf-mat-option-value="font-mukta"] {
		@include font_mukta;
	}
	&[data-cf-mat-option-value="font-mulish"] {
		@include font_mulish;
	}
	&[data-cf-mat-option-value="font-kanit"] {
		@include font_kanit;
	}
	&[data-cf-mat-option-value="font-nanumgothic"] {
		@include font_nanumgothic;
	}
	&[data-cf-mat-option-value="font-quicksand"] {
		@include font_quicksand;
	}
	&[data-cf-mat-option-value="font-titillium"] {
		@include font_titillium;
	}
	&[data-cf-mat-option-value="font-avenir-next"] {
		@include font_avenir_next;
	}
}

.add_picture {
	> .ligne_form + .ligne_form {
		padding-top : 24px;
		border-top  : 1px solid $gris;
	}

	form-field-image {
		media-image {
			.row {
				margin : 0;
			}

			.mat-label {
				display : none;
			}

			.btn_cta {
				width : auto;
			}
		}
	}

	.ligne_form.removable {
		position      : relative;
		padding-right : 60px;

		.remove_item, .move_item {
			position : absolute;
			right    : 0;
			bottom   : 0;
			width    : 48px;
			padding  : 0;
		}

		.move_item {
			height : 32px;

			&.down {
				bottom : 56px;
			}

			&.up {
				bottom : 92px;
			}
		}
	}

	.removable_item {
		position      : relative;
		padding-right : 60px;

		.remove_item, .move_item {
			position : absolute;
			right    : 0;
			bottom   : 0;
			width    : 48px;
			padding  : 0;
		}

		.move_item {
			height : 32px;

			&.down {
				top : 92px;
			}

			&.up {
				top : 56px;
			}
		}
	}

	&:not(.big) {
		form-field-image {
			media-image {
				.btn_cta {
					@extend .btn_small;
				}
			}
		}
	}
}

.show_tooltip {
	.tooltip_icon {
		color : $grisfonce;
	}

	.tooltip_info {
		display     : block;
		background  : $grisfonce;
		@include radius(4px);
		padding     : 6px 12px;
		color       : $blanc;
		font-size   : 13px;
		line-height : 16px;
		position    : relative;

		&:before {
			content       : '';
			width         : 0;
			height        : 0;
			border-top    : 10px solid transparent;
			border-right  : 12px solid $grisfonce;
			border-bottom : 10px solid transparent;
			@include y_align_absolute;
			left          : -12px;
		}
	}
}

cms-root {
	.mat-icon {
		width       : 18px;
		height      : 18px;
		line-height : 18px;
		font-size   : 18px;
		margin      : 0;
	}

	.cms_component .nav-cms .translation-list .translation-item button.mat-mdc-outlined-button {
		background : $blanc;
		border     : 1px solid $grisfonce;
		color      : $grisfonce;
		@include radius(4px !important);
		&.mat-accent {
			border-color : $vert !important;
			color        : $vert;
		}

		@include hover {
			background : $gris;
		}
	}

	.cms_component .nav-cms .translation-list .translation-item {
		margin-right : 0 !important;
		button {
			border-bottom : 1px solid $gris;
			font-family   : "Lato", sans-serif;
			font-weight   : 400;
			font-size     : 14px;
			line-height   : 18px;
			color         : #1d2028 !important;
			opacity       : 1;
			padding       : 8px 16px;
			&.active {
				font-family   : "Lato", sans-serif;
				font-weight   : 700;
				color         : #000000 !important;
				border-bottom : 1px solid $vert;
			}
		}
	}
	.ck.ck-editor__main > .ck-editor__editable {
		min-height : 300px;
	}

	media-image, media-image-collection, app-button, app-file {
		.btn_cta {
			display : inline-flex;
			width   : auto !important;
		}
	}
}

translation-create-dialog .input-checkbox .label {
	color : $grisfonce !important;

	&:after {
		background : $vert;
	}
}

.cms_component .info_cms .icon_see_below {
	color : $vert !important;
}

cms-root {
	display        : block;
	width          : 100%;
	padding-bottom : 100px;

	.input-checkbox input:checked + .label {
		color : $noir;

		&:after {
			background : $vert;
		}
	}
}

.autocomplete-trigger {
	position        : absolute;
	z-index         : 3;
	top             : 0;
	right           : 0;
	background      : url("../../images/search.svg") no-repeat center;
	background-size : 50%;
	opacity         : 0.7;
	@include transition($transition);
	@include hover {
		opacity : 1;
	}
}

/** style input tel international **/
.iti {
	display : block !important;
}

.iti .dropdown-menu.country-dropdown {
	border-top-left-radius  : 0px;
	border-top-right-radius : 0px;
	border-color            : #c7cace;
	margin-top              : -1px;
}

.iti .iti__country-list {
	box-shadow  : none;
	font-size   : 14px;
	margin-left : 0;
	width       : 244px;
	max-height  : 170px;
	.iti__country {
		display : block;
	}
}

.iti__flag-container {
	z-index : 1000;
	.iti__selected-flag {
		padding-right : 0 !important;
		width         : 75px !important;
	}
	&.required {
		&:after {
			content   : " *";
			position  : absolute;
			color     : #f80000;
			left      : 78px;
			top       : 4px;
			font-size : 9px;
		}
	}
}

.iti__flag-container.open + input {
	border-bottom-left-radius  : 0px;
	border-bottom-right-radius : 0px;
}

.iti__flag-container + input {
	padding-top  : 0 !important;
	padding-left : 80px !important;
}

.iti .search-container input {
	font-size     : 14px;
	border-color  : #c7cace;
	border-radius : 0;
	padding       : 5px 10px;
}

.iti .search-container input:focus {
	outline : none;
}

@media screen and (max-width : 479px) {
	.iti .iti__country-list {
		width : 88.3vw;
	}
}

mat-option mat-pseudo-checkbox, .mat-mdc-optgroup-label .container_checkbox {
	width         : 16px !important;
	height        : 16px !important;
	border        : 1px solid $grisfonce !important;
	border-radius : 0 !important;
	background    : transparent !important;
	@include transition($transition !important);
	margin-right  : 8px !important;
	margin-left   : 0 !important;
	order         : -1;
	&:after {
		display : none;
	}
	&:before {
		content    : '';
		@include y_align_absolute;
		left       : 20%;
		width      : 60%;
		height     : 60%;
		background : $vert;
		opacity    : 0;
		@include transition($transition);
	}

	&.mat-pseudo-checkbox-checked {
		border-color : $vert !important;
		&:before {
			opacity : 1;
		}
		~ span {
			color : $noir;
		}
	}
}

.mat-mdc-optgroup-label {
	padding-left : 40px !important;
	position     : relative;
	@include font_lato_regular;
	color        : $noir;
	cursor       : pointer !important;
	@include hover {
		background : rgba(0, 0, 0, 0.04);
	}
	> input {
		display : none;
		&:checked {
			~ .container_checkbox {
				border-color : $vert !important;
				&:before {
					opacity : 1;
				}
			}
		}
	}

	.container_checkbox {
		@include y_align_absolute;
		left : 16px;
	}

}

.mat-mdc-optgroup.canCheckGroup {
	.mat-mdc-option {
		padding-left : 30px;
	}
}

.cdk-overlay-container {
	.flag {
		display        : inline-block;
		vertical-align : middle;
		width          : 24px;
		height         : 16px;
		+ .flag {
			margin-top : 6px;
		}

		&.flag-fr {
			background      : url("../../images/icons/icon_flag_fr.svg") no-repeat center;
			background-size : contain;
		}

		&.flag-en {
			background      : url("../../images/icons/icon_flag_en.svg") no-repeat center;
			background-size : cover;
		}

		&.flag-pt {
			background      : url("../../images/icons/icon_flag_pt.svg") no-repeat center;
			background-size : contain;
		}

		&.flag-it {
			background      : url("../../images/icons/icon_flag_it.svg") no-repeat center;
			background-size : contain;
		}

		&.flag-es {
			background      : url("../../images/icons/icon_flag_es.svg") no-repeat center;
			background-size : contain;
		}

		&.flag-de {
			background      : url("../../images/icons/icon_flag_de.svg") no-repeat center;
			background-size : contain;
		}

		&.flag-nl {
			background      : url("../../images/icons/icon_flag_nl.svg") no-repeat center;
			background-size : contain;
		}
	}
	.text {
		display        : inline-block;
		vertical-align : middle;
		font-size      : 15px;
		line-height    : 17px;
		margin-left    : 15px;
		font-weight    : 500;
	}
}

mat-select.flag {
	background      : url("../../images/chevron.svg") no-repeat center right 12px;
	background-size : 12px;
	.mat-mdc-select-value {
		width      : 24px !important;
		height     : 16px !important;
		margin-top : -12px;
		span {
			display : none;
		}
	}
	&.flag-fr .mat-mdc-select-value {
		background      : url("../../images/icons/icon_flag_fr.svg") no-repeat center;
		background-size : contain;
	}
	&.flag-en .mat-mdc-select-value {
		background      : url("../../images/icons/icon_flag_en.svg") no-repeat center;
		background-size : cover;
	}
	&.flag-pt .mat-mdc-select-value {
		background      : url("../../images/icons/icon_flag_pt.svg") no-repeat center;
		background-size : contain;
	}
	&.flag-it .mat-mdc-select-value {
		background      : url("../../images/icons/icon_flag_it.svg") no-repeat center;
		background-size : contain;
	}
	&.flag-es .mat-mdc-select-value {
		background      : url("../../images/icons/icon_flag_es.svg") no-repeat center;
		background-size : contain;
	}
	&.flag-de .mat-mdc-select-value {
		background      : url("../../images/icons/icon_flag_de.svg") no-repeat center;
		background-size : contain;
	}
	&.flag-nl .mat-mdc-select-value {
		background      : url("../../images/icons/icon_flag_nl.svg") no-repeat center;
		background-size : contain;
	}
}

.ck.ck-color-picker__row {
	.ck.ck-input-text {
		z-index    : 1;
		height     : 32px;
		box-shadow : none !important;
		padding    : 0 12px;
	}
	.ck.ck-label {
		z-index : 2;
	}
}

&:has(.hashtag_filter_item mat-select[aria-expanded="true"]) {
	.cdk-overlay-container .cdk-overlay-pane {
		width     : auto !important;
		max-width : 400px;
	}
}
