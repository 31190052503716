app-core-loader {
	position : relative;
	z-index  : 2;
	.overlay {
		position   : fixed;
		top        : 80px;
		left       : 275px;
		bottom     : 0;
		right      : 0;
		background : rgba($blanc, 0.5);
		> div {
			@include center_align_absolute;
		}
	}
	mat-progress-spinner {
		width  : 80px !important;
		height : 80px !important;
		.mdc-circular-progress__circle-clipper {
			width : 100%;
		}
		svg {
			width  : 100% !important;
			height : 100% !important;
			circle {
				stroke : $vert !important;
			}
		}
	}
}

.layout + app-core-loader {
	z-index : 5;
	.overlay {
		left : 0;
		top  : 0;
	}
}