/* ---------------------------------------------------------------------------------------------------------------------
---- Stgs Arabica
--------------------------------------------------------------------------------------------------------------------- */

/* - fluid Mode
..................................................................................................................... */

$fluidMode : true; // True - False

/* - Breakpoints (colonnes, taille des boxes et modales)
..................................................................................................................... */

$breakpointMap : (
  // class="col_xs" - class="box_xs" - class="modale_xs"
        'xs': 0440px,
  // class="col_sm" - class="box_sm" - class="modale_sm"
        'sm': 0576px,
  // class="col_md" - class="box_md" - class="modale_md"
        'md': 0768px,
  // class="col_lg" - class="box_lg" - class="modale_lg"
        'lg': 992px,
  // class="col_xl" - class="box_xl" - class="modale_xl"
        'xl': 1170px,
  // class="col_full" - class="box_full" - class="modale_full"
        'full': 0100%
);

/* - Marges (Entre les les colonnes : class="marges" sur la row parente)
..................................................................................................................... */

$marges : 12px;
$margesBreakpoint : 'md';
$margesMobile : ($marges * 0.5) + 2;

/* - Nombre de colonnes de la grille
..................................................................................................................... */

$nombreColonnes : 12;

/* - Modales
..................................................................................................................... */

$modalePadding : 24px;
$modalePaddingBreakpoint : 'md';
$modalePaddingMobile : $modalePadding * 0.5;
$modaleBackgroundColor : #FFFFFF;
$overlayBackgroundColor : #000000;
$overlayOpacity : .75;

/* - Init Arabica
..................................................................................................................... */

@import '../../../../node_modules/@cafefrappe/arabica/lib/css/arabica';
