app-page-promotion-subscription-create, app-page-promotion-subscription-read{
    p.title{
      font-weight: bold;
      margin-bottom:20px;
    }
  .border{
    border:1px solid #eaeaea;
    padding: 10px;
  }
  .margin-bottom{
    margin-bottom: 20px;
  }
}
