.page_offer_creator {
	max-width : 1200px;
	overflow  : visible;
	.offer_creator_item {
		padding-bottom : 16px;
		.title_offer {
			margin : 32px 0;
			img {
				max-width  : 200px;
				max-height : 60px;
			}
		}
		.infos {

			border-radius : 10px;
			@include shadow(0 0 8px 0 rgba(0, 0, 0, 0.3));
			background    : $blanc;
			padding       : 24px;

			&.green {
				border     : 1px solid $vert;
				box-shadow : none;
			}

			&.no_border {
				border     : none;
				box-shadow : none;
			}

			&.no_padding {
				padding : 0;
			}

			.flags {
				//display   : flex;
				//flex-wrap : wrap;
				.flag_item {
					position  : relative;
					max-width : 100px;
					.flag {
						display        : inline-block;
						vertical-align : middle;
						width          : 18px;
						height         : 12px;
					}

					.count {
						margin-left : 24px;
						margin-top  : -18px;
						position    : absolute;

						a {
							cursor : pointer;

							&:hover {
								color : $vert;
							}
						}
					}

					.flag-pending {
						opacity : 0.2;
					}

					.flag-fr {
						background      : url("../../images/icons/icon_flag_fr.svg") no-repeat center;
						background-size : contain;
					}

					.flag-en {
						background      : url("../../images/icons/icon_flag_en.svg") no-repeat center;
						background-size : cover;
					}

					.flag-pt {
						background      : url("../../images/icons/icon_flag_pt.svg") no-repeat center;
						background-size : contain;
					}

					.flag-it {
						background      : url("../../images/icons/icon_flag_it.svg") no-repeat center;
						background-size : contain;
					}

					.flag-es {
						background      : url("../../images/icons/icon_flag_es.svg") no-repeat center;
						background-size : contain;
					}

					.flag-de {
						background      : url("../../images/icons/icon_flag_de.svg") no-repeat center;
						background-size : contain;
					}

					.flag-nl {
						background      : url("../../images/icons/icon_flag_nl.svg") no-repeat center;
						background-size : contain;
					}
				}
			}

			textarea {
				border : none;
			}
		}

		.terms_and_conditions {

			.terms_and_conditions_locale_item {

				border-right  : 1px solid $noir;
				padding-right : 5px;
				margin-right  : 5px;

				&:hover {
					cursor : pointer;
					color  : $vert;
				}
			}

			.terms_and_conditions_locale_item:last-child {
				border-right : none;
			}
		}

		.actions {
			.btn_cta {
				+ .btn_cta {
					margin-left : 16px;
				}
			}
		}

		span.info {
			font-size : x-small;
		}
	}
}

.content_offer_creators {
	margin-top : -24px;
	app-core-offer-creator-search {
		display    : block;
		position   : relative;
		z-index    : 1;
		margin-top : 24px;
	}
	> .row {
		position    : sticky;
		z-index     : 2;
		top         : -24px;
		left        : 0;
		background  : $blanc;
		padding-top : 24px;
		padding-bottom : 12px;
	}
}

.cliquable_file{
    text-decoration: underline;
    &:hover {
        cursor : pointer;
        color  : $vert;
    }
}
