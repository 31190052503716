@mixin shadow($shadow : 0 0 16px 0 rgba($noir,0.15)) {
	-webkit-box-shadow : $shadow;
	-moz-box-shadow    : $shadow;
	box-shadow         : $shadow;
}

@mixin imageFit() {
	object-fit         : cover;
	-o-object-fit      : cover;
	object-position    : center;
	-o-object-position : center;
}

@mixin degrade($color1, $color2, $percentLeft : 0%, $percentRight : 100%) {
    background: $color1;
    background : -moz-linear-gradient(90deg, $color1 $percentLeft, $color2 $percentRight);
    background : -webkit-linear-gradient(90deg, $color1 $percentLeft, $color2 $percentRight);
    background : linear-gradient(90deg, $color1 $percentLeft, $color2 $percentRight);
    filter     : progid:DXImageTransform.Microsoft.gradient(startColorstr='$color1', endColorstr='$color2', GradientType=1);
}
