.page_offer {
	display    : block;
	width      : 100%;
	max-width  : 1020px;
	margin     : auto;
	overflow-x : hidden;
	app-core-offer-date-engine, app-core-offer-item {
		display : block;
		width   : 100%;
	}
	.languages {
		margin-bottom : 24px;
	}
	app-core-offer-item {
		border-top : 1px solid $txt;
		padding    : 24px 0;
	}
	.btn_booking {
		margin-left : 10px;
	}
	.back_to_list, .back_to_compo {
		span {
			font-size       : 12px;
			line-height     : 16px;
			text-transform  : uppercase;
			color           : $grisfonce;
			text-decoration : underline;
			cursor          : pointer;
			@include transition($transition);
			&:before {
				display        : inline-block;
				vertical-align : middle;
				@include transform(rotate(90deg));
				margin-right   : 12px;
				color          : $vert;
				font-size      : 1em;
				margin-top     : -1px;
			}
			@include hover {
				color : $vert;
			}
		}
	}
	.back_to_list {
		border-bottom  : 1px solid $txt;
		padding-bottom : 24px;
	}
	.back_to_compo {
		padding : 16px 0;
		span {
			text-transform  : initial;
			text-decoration : none;
			&:before {
				color     : inherit;
				font-size : 0.8em;
			}
		}
	}
	.quotation_composition {

		border-bottom : 1px solid $txt;
		margin-bottom : 24px;
	}
	.voucher_composition {
		.info_step {
			display     : block;
			width       : 100%;
			margin-top  : 8px !important;
			font-size   : 13px;
			line-height : 16px;
			&.error {
				color : $danger;
			}
			&.icon_info {
				position     : relative;
				padding-left : 24px;
				&:before {
					content         : '';
					@include y_align_absolute;
					left            : 0;
					width           : 20px;
					height          : 20px;
					background      : transparent url("../../images/icon_info.png") no-repeat center;
					background-size : 22px;
				}
			}
		}
	}
	.choice_date {
		position : relative;
		.item_date {
			display       : block;
			width         : 100%;
			margin-bottom : 16px;
			.composition-step-label {
				display       : block;
				width         : 100%;
				height        : 32px;
				line-height   : 32px;
				padding       : 0 16px;
				background    : $blanc;
				color         : $noir;
				font-size     : 16px;
				border        : 1px solid $noir;
				border-bottom : none;
				@include radius(3px 3px 0 0);
				&.active {
					background : $noir;
					color      : $blanc;
					+ .composition-step-content {
						@include radius(0 0 3px 3px);
						border-width     : 2px;
						border-top-width : 0;
					}
				}
			}
			.composition-step-content {
				display : block;
				width   : 100%;
				@include radius(0 0 3px 3px);
				border  : 1px solid $noir;
			}
			&.disabled {
				.composition-step-label {
					opacity : 0.6;
				}
				.composition-step-content {
					opacity        : 0.6;
					pointer-events : none;
				}
			}
			.choice_item {
				position : relative;
				z-index  : 1;
				&:after {
					content       : '';
					position      : absolute;
					top           : 0;
					left          : 0;
					bottom        : -1px;
					right         : -1px;
					border-right  : 1px solid $noir;
					border-bottom : 1px solid $noir;
					z-index       : 1;
				}
				.content_item {
					display  : block;
					width    : 100%;
					height   : 56px;
					position : relative;
					z-index  : 2;
					label, .label {
						position    : absolute;
						left        : 12px;
						right       : 12px;
						top         : 8px;
						font-size   : 13px;
						line-height : 16px;
						z-index     : 1;
					}
					select, .value, .input, .mat-datepicker-input {
						display       : block;
						width         : 100%;
						height        : 100%;
						padding-left  : 12px;
						padding-right : 12px;
						padding-top   : 20px;
						font-size     : 14px;
						line-height   : 30px;
						opacity       : 0.8;
						position      : relative;
						z-index       : 2;
						cursor        : pointer;
						border        : none;
					}
				}
				&.choice_people {
					background      : transparent url("../../images/chevron.svg") no-repeat top 36px right 12px;
					background-size : 13px;
				}
				&.choice_children {
					.content_item {
						height : auto;
						.input, .mat-datepicker-input {
							height : 56px;
						}
						.fv-plugins-message-container, .invalid-feedback {
							padding     : 8px 12px;
							padding-top : 0;
							margin      : 0;
							div {
								margin : 0;
							}
						}
					}
				}
				&.disabled {
					background : $gris;
					z-index    : 0;
					.content_item {
						select, .value, .input, .mat-datepicker-input {
							cursor : default;
						}
					}
				}
				.flatpickr-calendar {
					top       : 100% !important;
					left      : 0 !important;
					min-width : 290px;
				}
			}
			.info_step {
				display     : block;
				width       : 100%;
				margin-top  : 8px !important;
				font-size   : 13px;
				line-height : 16px;
				&.error {
					color : $danger;
				}
				&.icon_info {
					position     : relative;
					padding-left : 24px;
					&:before {
						content         : '';
						@include y_align_absolute;
						left            : 0;
						width           : 20px;
						height          : 20px;
						background      : transparent url("../../images/icon_info.png") no-repeat center;
						background-size : 22px;
					}
				}
			}
		}
		#composition-step-date:not(.col_lg_6) {
			.item_date {
				&:not(.disabled) {
					margin-top : 24px;
				}
				&.disabled {
					display : none;
				}
			}
		}
		#child-informations-container {
			//&:not(:empty) {
			//    + div {
			//        flex-basis              : 100%;
			//        -webkit-flex-basis      : 100%;
			//        -ms-flex-preferred-size : 100%;
			//        max-width               : 100%;
			//        padding-right           : 12px;
			//    }
			//}
			.composition-data-wrapper {
				.composition-step-content {
					border        : none;
					padding-right : 1px;
					.choice_item:after {
						border-top  : 1px solid $noir;
						border-left : 1px solid $noir;
					}
				}
			}
		}
		.recap_date {
			display    : block;
			width      : 100%;
			margin-top : 12px;
			.name {
				display        : block;
				font-size      : 14px;
				line-height    : 18px;
				color          : $grisfonce;
				padding-bottom : 2px;
				@include media_x_max('sm') {
					font-size   : 13px;
					line-height : 16px;
				}
			}
			.price {
				display     : block;
				font-weight : bold;
				font-size   : 18px;
				line-height : 24px;
				color       : $vert;
				@include media_x_max('sm') {
					font-size   : 16px;
					line-height : 22px;
				}
			}
			.price-ht {
				display        : inline-block;
				font-size      : 0.7em;
				color          : $noir;
				opacity        : 0.5;
				text-align     : right;
				vertical-align : middle;
			}
			.total {
				.name {
					opacity : 0.8;
				}
				.price {
					font-size   : 24px;
					line-height : 28px;
					display     : block;
					@include media_x_max('sm') {
						font-size   : 20px;
						line-height : 26px;
					}
					.old_price {
						text-decoration : line-through;
						font-size       : 0.8em;
						color           : $grisfonce;

						span {
							text-decoration : line-through;
						}
					}
				}
			}
			> div {
				+ div {
					margin-top : 6px;
				}
			}
		}
		.validate_date {
			margin-top : 24px;
			@include media_x_max('lg') {
				> div + div {
					margin-top : 16px;
				}
			}
		}
		.mat-mdc-form-field-error {
			padding : 0;
			div {
				margin-top  : 16px;
				font-size   : 12px;
				line-height : 14px;
			}
		}
		&.origin_step_form {
			padding-bottom : 16px;
			.item_date {
				.choice_item.choice_people.choice_children {
					@extend .col_6;
					@extend .col_lg_6;
					@extend .col_xl_4;
				}
			}
			.content-col-date {
				&:before {
					display : none;
				}
			}
			.semi-col-date {
				padding : 0 12px !important;
				@extend .col_12;
				@extend .col_lg_12;
				&#composition-step-date {
					.item_date {
						&:not(.disabled) {
							margin-top : 24px;
						}
						&.disabled {
							display : none;
						}
					}
				}
			}
		}
		@include media_x_min('lg') {
			.content-col-date {
				position : relative;
				&:before {
					content         : '';
					position        : absolute;
					width           : 32px;
					height          : 32px;
					top             : 44px;
					left            : 50%;
					margin-left     : -16px;
					background      : transparent url("../../images/chevron.svg") no-repeat center;
					background-size : 24px;
					@include transform(rotate(-90deg));
					opacity         : 0.6;
				}
			}
			.semi-col-date {
				padding-right : 40px;
				+ .semi-col-date {
					padding-left  : 40px;
					padding-right : 12px;
				}
			}
		}
		@include media_x_max('lg') {
			.item_date {
				.composition-step-label {
					font-size : 15px;
				}
				.choice_item {
					.content_item {
						height : 50px;
						label, .label {
							left        : 8px;
							right       : 8px;
							top         : 5px;
							font-size   : 12px;
							line-height : 14px;
						}
						select, .value, .input, .mat-datepicker-input {
							padding-left  : 8px;
							padding-right : 8px;
							padding-top   : 17px;
							font-size     : 14px;
							line-height   : 28px;
						}
					}
					&.choice_people {
						background-position : top 31px right 10px;
						background-size     : 11px;
					}
					&.choice_children {
						.content_item {
							.input, .mat-datepicker-input {
								height : 50px;
							}
							.fv-plugins-message-container {
								padding     : 6px 8px;
								padding-top : 0;
							}
						}
					}
				}
			}
			#composition-step-date {
				.item_date {
					&:not(.disabled) {
						margin-top : 24px;
					}
					&.disabled {
						display : none;
					}
				}
			}
		}
	}
	.recap {
		display       : block;
		margin-bottom : 24px;
		.title {
			@include font_lato_bold;
			font-size   : 16px;
			line-height : 20px;
		}
		.ck-content {
			display    : block;
			margin-top : 16px;
			padding    : 8px 16px;
			border     : 1px solid $gris;
			background : $grisclair;
			@include radius(4px);
		}

		&_title {
			margin      : 0;
			padding     : 20px 10px;
			font-weight : bold;
			text-align  : center;
			background  : #dbe2df;
		}

		&_summary {

			&_item {
				border        : 1px solid $noir;
				border-radius : 5px;
				overflow      : hidden;
				p.recap_title {
					text-align    : start;
					margin-bottom : 0 !important;
				}
				.row {
					padding : 0 10px;
					&:last-child {
						padding-bottom : 10px;
					}
					.col_label {
						color : #696767;
					}
					.col_content {
						text-align : end;
					}
				}
			}
		}

		&_item {
			margin-top    : 20px;
			border-radius : 8px;
			overflow      : hidden;
			@include font_lato_regular;
			@include shadow(0 0 4px 0 rgba(0, 0, 0, 0.3));

			.visual {
				height   : 150px;
				overflow : hidden;

				img {
					display  : block;
					position : relative;
					z-index  : 1;
					width    : 100%;
					height   : 100%;
					@include imageFit;
				}
			}
			.presential {
				.col_content:first-child {
					position : relative;

					&::after {
						content       : "";
						display       : block;
						height        : 6px;
						width         : 6px;
						background    : $vert;
						position      : absolute;
						top           : 0;
						right         : 0;
						border-radius : 10px;
						transform     : translate(3px, 8px);
					}
				}
			}
			.description {
				padding : 10px 20px;
				p {
					margin : 0;
				}
			}
			.item {
				border-bottom : 1px solid rgba(238, 238, 238, 0.7);
				padding       : 10px 20px;
				font-size     : 13px;
				@include font_lato_bold;
				& > span {
					color     : #c3c3c3;
					font-size : 16px;
				}
			}

		}
	}
	.form_booking {
		margin-bottom : 24px;
	}
	.title_offer {
		margin-bottom : 40px;
		.tag {
			display     : block;
			font-size   : 12px;
			line-height : 15px;
			color       : $blanc;
			background  : $noir;
			padding     : 4px 16px;
			@include radius(6px);
		}
		.title {
			@include font_lato_bold;
			font-size     : 26px;
			line-height   : 32px;
			color         : $grisfonce;
			margin-bottom : 0;
		}
		.infos {
			margin-top : 12px;
			span {
				display        : inline-block;
				vertical-align : middle;
				font-size      : 14px;
				line-height    : 17px;
				color          : rgba($grisfonce, 0.8);
			}
			.offer_type {
				&:before {
					display        : inline-block;
					vertical-align : middle;
					margin-right   : 8px;
					color          : $vert;
					font-size      : 1.2em;
					margin-top     : -2px;
				}
				&:after {
					content        : '';
					display        : inline-block;
					vertical-align : middle;
					width          : 8px;
					height         : 2px;
					@include radius(2px);
					background     : $vert;
					margin         : 0 8px;
				}
			}
		}
		.infos_canaux {
			padding-bottom : 16px;
			margin-bottom  : 16px;
			border-bottom  : 1px solid $noir;
			p {
				strong {
					font-weight : bold;
				}
			}
			.mat-accordion {
				margin-bottom: 16px;
				.mat-expansion-panel {
					border        : 1px solid $vert;
					background    : rgba($vert, 0.2);
					margin-bottom : 0 !important;
					margin-top    : 5px;
					text-align    : center;
					@include radius(4px);
					.mat-expansion-panel-header-title {
						font-weight : bold;
					}
					.mat-expansion-panel-body {
						text-align : left;
						.txt_offer {
							margin-bottom : 0;
							&:not(:last-child) {
								margin-bottom : 16px;
							}
							p {
								margin-bottom : 0 !important;
							}
						}
					}
				}
			}
			.choice_language {
				margin-bottom : 16px;
			}
		}
	}
	.hashtags {
		display    : flex;
		flex-wrap  : wrap;
		margin-top : 18px;
		.hashtag {
			margin-right : 12px;
			display      : flex;
			align-items  : center;
			img {
				width : 12px;
			}
			span {
				font-size : 14px;
				color     : rgba($grisfonce, 0.8);
			}
		}
	}
	.offer_pictures {
		margin        : 0 -4px;
		margin-bottom : 40px;
		img {
			display    : block;
			width      : 100%;
			height     : 100%;
			@include imageFit;
			background : $grisclair;
		}
		.col_left {
			padding : 0 4px;
			img {
				@include radius(15px 0 0 15px)
			}
			&:only-child {
				img {
					@include radius(15px)
				}
			}
		}
		.col_right {
			padding : 0 4px;
			.row {
				margin : 0 -4px;
				.col_6 {
					padding : 0 4px;
				}
			}
			.col_6 {
				height   : calc(50% - 4px);
				position : relative;
				img {
					position : relative;
					z-index  : 1;
				}
				.see_more {
					position   : absolute;
					top        : 0;
					left       : 4px;
					bottom     : 0;
					right      : 4px;
					z-index    : 2;
					background : rgba($grisfonce, 0.6);
					@include radius(0 0 15px 0);
					cursor     : pointer;
					@include transition($transition);
					span {
						@include center_align_absolute;
						color       : $blanc;
						@include font_lato_bold;
						font-size   : 15px;
						line-height : 18px;
						width       : 100%;
						text-align  : center;
						&:before {
							content       : '+';
							display       : block;
							margin-bottom : 8px;
							font-size     : 1.4em;
						}
					}
					@include hover {
						background : rgba($grisfonce, 0.8);
					}
				}
				&:nth-of-type(3), &:nth-of-type(4) {
					margin-top : 8px;
				}
				&:nth-of-type(2) img {
					@include radius(0 15px 0 0)
				}
				&:nth-of-type(4) img {
					@include radius(0 0 15px 0)
				}
			}
		}
	}
	.txt_offer {
		display       : block;
		width         : 100%;
		margin-bottom : 24px;
		+ .txt_offer {
			&.little_border{
                padding-top : 24px;
                position: relative;
                &:before{
                    content:'';
                    position:absolute;
                    left:0;
                    top:0;
                    width: 50px;
                    height:1px;
                    background: #008151;
                }
            }
		}
		> p {
			@include font_lato_regular;
			font-size     : 14px;
			line-height   : 21px;
			color         : $grisfonce;
			margin-bottom : 0;
			&:not(:last-child) {
				margin-bottom : 16px;
			}
			strong {
				@include font_lato_bold;
			}
		}
		.argument {
			color : $txt;
		}
		.title {
			@include font_lato_bold;
			font-size     : 22px;
			line-height   : 27px;
			color         : $grisfonce;
			margin-bottom : 16px !important;
			+ .condition {
				margin-top : -8px;
			}
		}
		.title_2 {
			@include font_lato_bold;
			font-size     : 16px;
			line-height   : 22px;
			color         : $grisfonce;
			margin-bottom : 8px !important;
		}
		.condition {
			font-size     : 13px;
			line-height   : 16px;
			margin-bottom : 8px !important;
			&.green {
				color : $vert;
			}
		}
		.interest {
			span {
				display        : inline-block;
				vertical-align : middle;
				position       : relative;
				padding-left   : 16px;
				&:not(:last-child) {
					margin-right : 24px;
				}
				&:before {
					content     : "\e003";
					font-family : "ty-win";
					@include y_align_absolute;
					left        : 0;
					color       : $vert;
					font-size   : 0.9em;
				}
			}
		}
		.description_offer {
			max-height : 168px;
			overflow   : hidden;
			&.open {
				max-height : inherit;
			}
		}
		.see_more {
			display  : block;
			width    : 100%;
			position : relative;
			span {
				@include font_lato_bold;
				font-size   : 14px;
				line-height : 21px;
				color       : $vert;
				cursor      : pointer;
				position    : relative;
				z-index     : 2;
				@include hover {
					text-decoration : underline;
				}
			}
			&:before {
				content    : '';
				position   : absolute;
				left       : 0;
				bottom     : 0;
				right      : 0;
				height     : 32px;
				background : $blanc;
				background : linear-gradient(180deg, rgba($blanc, 0) 0%, rgba($blanc, 1) 100%);
			}
		}
		&.confirmation {
			padding    : 16px;
			border     : 1px solid $vert;
			background : rgba($vert, 0.2);
			@include radius(4px);
		}
		&.failed {
			padding    : 16px;
			border     : 1px solid $danger;
			background : rgba($danger, 0.1);
			@include radius(4px);
		}
		&.little {
			margin-bottom : 12px;
			p {
				font-size   : 13px;
				line-height : 18px;
			}
			.title_2 {
				@include font_lato_regular;
				font-size     : 15px;
				line-height   : 20px;
				margin-bottom : 4px !important;
			}
		}
	}
	a.terms_and_condition {
		text-decoration : underline;
		cursor          : pointer;
		@include hover {
			color : $vert;
		}
	}
	.nav_slider_program {
		margin-bottom : 24px;
		button {
			display        : inline-block;
			vertical-align : middle;
			+ button {
				margin-left : 16px;
			}
		}
	}
	.map_container {
		svg {
			.line_map {
				stroke : rgba($grisfonce, 0.4);
			}
		}
		.leaflet-tooltip {
			padding     : 2px 8px;
			font-weight : bold;
		}
		.leaflet-popup-content {
			p {
				margin      : 0;
				font-size   : 13px;
				line-height : 17px;
				@include font_lato_regular;
				&:nth-of-type(1) {
					font-style    : italic;
					font-size     : 0.9em;
					margin-bottom : 6px;
				}
				&:nth-of-type(2) {
					@include font_lato_bold;
					font-size     : 1.2em;
					margin-bottom : 2px;
				}
			}
		}
	}
	.option_offer {
		display : block;
		width   : 100%;
		.option {
			width    : 100%;
			border   : 1px solid $grisfonce;
			@include radius(7px 7px 0 7px);
			overflow : hidden;
			.visuel {
				display  : block;
				width    : 100%;
				height   : 100%;
				position : relative;
				img {
					display : block;
					width   : 100%;
				}
			}
			.infos {
				padding : 16px;
				.users {
					p {
						display        : inline-block;
						vertical-align : middle;
						font-size      : 14px;
						margin         : 0;
						&:before {
							display        : inline-block;
							vertical-align : middle;
							font-size      : 1.2em;
							margin-right   : 8px;
						}
						+ p {
							margin-left : 24px;
						}
					}
				}
			}
		}
		.check_option > div {
			background : $grisfonce;
			padding    : 16px;
			@include radius(0 0 7px 7px);
			.form-group {
				.checkbox {
					margin : 0;
					label {
						color       : $blanc;
						font-size   : 15px;
						font-weight : bold;
						&:before {
							border-color : $blanc !important;
						}
						&:after {
							background : $blanc !important;
						}
					}
				}
			}
		}
		+ .option_offer {
			margin-top : 40px;
		}
		.choice_day {
			> div {
				display : block;
				width   : 100%;
			}
		}
	}

	#form-offer-booking, #form-offer-quotation, #offer-gift-voucher {
		display     : block;
		padding-top : 40px;

		.mt-10 {
			margin-top : 10px;
		}

		p {
			margin : 0;
		}
		#summary {
			margin-bottom : 20px;
			> div {
				display      : block;
				margin-right : 8px;
				@include radius(10px);
				@include shadow(0 0 8px 0 rgba($noir, 0.3));
				background   : $blanc;
				overflow     : hidden;
			}
			.title {
				padding    : 24px 16px;
				background : rgba($gris2, 0.4);
			}
			.visuel {
				display : block;
				width   : 100%;
				img {
					display : block;
					width   : 100%;
				}
			}
			.infos {
				display : block;
				width   : 100%;
				> div {
					display : block;
					width   : 100%;
					padding : 16px;
					+ div {
						border-top : 1px solid rgba($gris2, 0.6);
					}
				}
				.nbPerson {
					span {
						display        : inline-block;
						vertical-align : middle;
						font-size      : 13px;
						line-height    : 16px;
						@include font_lato_bold;
						position       : relative;
						margin-top     : 8px;
						+ span {
							margin-left : 20px;
							&:before {
								content    : '';
								@include y_align_absolute;
								left       : -15px;
								width      : 6px;
								height     : 6px;
								@include radius(50%);
								background : $vert;
							}
						}
					}
				}
				.teaser {
					p {
						font-size   : 13px;
						line-height : 16px;
					}
				}
				.label {
					font-size   : 15px;
					line-height : 20px;
					@include font_lato_bold;
					opacity     : 0.3;
				}
				.value {
					font-size   : 14px;
					line-height : 16px;
					@include font_lato_bold;
					margin-top  : 4px;
					&.price {
						font-size   : 16px;
						line-height : 18px;
					}
				}
				.old_price {
					text-decoration : line-through;
					font-size       : 0.8em;
					margin-right    : 8px;
				}
			}
			@include media_x_max('sm') {
				> div {
					margin-left   : 0;
					margin-bottom : 24px;
					.title {
						position      : relative;
						padding       : 16px;
						padding-right : 24px;
						&:after {
							content     : "\e004";
							font-family : "ty-win";
							position    : absolute;
							right       : 16px;
							top         : 50%;
							@include transform(translateY(-50%));
							font-size   : 14px;
						}
					}
					.infos > div {
						padding : 12px 16px;
					}
				}
				&:not(.open) {
					> div {
						@include shadow(none);
						> div:not(.title) {
							display : none;
						}
					}
				}
				&.open {
					> div {
						@include shadow(0 0 16px 0 rgba($noir, 0.15));
						.title {
							&:after {
								@include transform(translateY(-50%) rotate(180deg));
							}
						}
					}
				}
			}
		}
		.content_data_summary {
			display       : block;
			width         : 100%;
			margin-bottom : 16px;
			border        : 1px solid $gris2;
			@include radius(10px);
			overflow      : hidden;
			.content_header {
				display    : block;
				width      : 100%;
				padding    : 16px 24px;
				background : rgba($vert, 0.1);
			}
			.content_body {
				padding : 24px;
				.data {
					display    : block;
					width      : 100%;
					min-height : 100%;
					@include radius(5px);
					overflow   : hidden;
					border     : 1px solid $gris3;
					.data_header {
						padding    : 16px 12px;
						background : rgba($gris2, 0.4);
						span {
							display     : block;
							font-size   : 15px;
							line-height : 18px;
							font-weight : bold;
						}
					}
					.data_body {
						padding : 12px;
						> div + div {
							margin-top : 5px;
						}
						span {
							display     : block;
							font-size   : 13px;
							line-height : 16px;
						}
						.x_end {
							span {
								font-weight : bold;
							}
						}
					}
				}
				.voucher_choice {
					display       : block;
					width         : 100%;
					margin-bottom : 16px;
					.form-group {
						display : block;
						width   : 100%;
						.radio, .checkbox {
							margin : 0;
						}
						.radio {
							display : block;
							width   : 100%;
							border  : 1px solid $gris3;
							@include radius(4px);
							label {
								padding      : 16px 24px;
								padding-left : 48px;
								&:before, &:after {
									@include y_align_absolute;
									@include radius(0);
								}
								&:before {
									left : 16px;
								}
								&:after {
									left : 20px;
								}
							}
						}
					}
					.content_choice {
						padding : 16px;
					}
					#transporters-container {
						padding-left  : 0;
						padding-right : 0;
					}
					.transporter-element {
						display  : block;
						width    : 100%;
						position : relative;
						+ .transporter-element {
							margin-top : 8px;
						}
						input {
							opacity  : 0;
							position : absolute;
							top      : 0;
							left     : 0;
							z-index  : 1;
							&:checked {
								+ .transporter {
									border-color : $gris;
									.choice-element {
										&:before {
											border-color : $vert;
										}
										&:after {
											opacity : 1;
										}
									}
								}
							}
						}
						.transporter {
							display  : block;
							width    : 100%;
							border   : 1px solid $blanc;
							@include radius(4px);
							position : relative;
							z-index  : 2;
							padding  : 8px 16px;
							img {
								display : block;
								width   : 60px;
							}
							p {
								display        : inline-block;
								vertical-align : middle;
								font-size      : 14px;
								line-height    : 18px;
								&.price {
									font-weight : bold;
								}
								&:not(:last-child) {
									margin-right : 32px;
									position     : relative;
									&:after {
										content    : '';
										@include y_align_absolute;
										right      : -21px;
										width      : 6px;
										height     : 6px;
										@include radius(50%);
										background : $gris2;
									}
								}
								span {
									display        : inline-block;
									vertical-align : middle;
									font           : inherit;
									+ span {
										margin-left : 16px;
									}
								}
							}
							.choice-element {
								position : absolute;
								z-index  : 2;
								top      : 0;
								left     : 0;
								bottom   : 0;
								right    : 0;
								cursor   : pointer;
								&:before {
									content : '';
									@include y_align_absolute;
									right   : 16px;
									width   : 16px;
									height  : 16px;
									@include radius(50%);
									border  : 1px solid $gris2;
								}
								&:after {
									content    : '';
									@include y_align_absolute;
									right      : 18px;
									width      : 12px;
									height     : 12px;
									@include radius(50%);
									background : $vert;
									opacity    : 0;
								}
							}

							.comment {
								font-style : italic;
								color      : $gris3;
							}
						}
						@include media_x_max('md') {
							.transporter {
								padding : 6px 24px 6px 12px;
								p {
									font-size   : 13px;
									line-height : 16px;
									span + span {
										margin-left : 12px;
									}
								}
								.choice-element {
									&:before {
										right : 8px;
									}
									&:after {
										right : 10px;
									}
								}
							}
						}
					}
				}
			}
			@include media_x_max('md') {
				.content_header {
					padding : 16px;
				}
				.content_body {
					padding : 16px;
					> .row > div + div {
						margin-top : 12px;
					}
				}
			}
		}
		.templates {
			display : block;
			width   : 100%;
			> div {
				margin-bottom : 24px;
			}
			.template-element {
				display        : block;
				width          : 100%;
				height         : 100%;
				position       : relative;
				@include radius(8px);
				overflow       : hidden;
				padding-bottom : 48px;
				&.selected {
					button {
						border-color : $vert;
						.btn_cta {
							@extend .hover;
						}
					}
				}
				.flags {
					position : absolute;
					top      : 8px;
					left     : 8px;
					.flag {
						display : block;
						width   : 18px;
						height  : 12px;
						+ .flag {
							margin-top : 6px;
						}
					}
					.flag-fr {
						background      : url("../../images/icons/icon_flag_fr.svg") no-repeat center;
						background-size : contain;
					}

					.flag-en {
						background      : url("../../images/icons/icon_flag_en.svg") no-repeat center;
						background-size : cover;
					}

					.flag-pt {
						background      : url("../../images/icons/icon_flag_pt.svg") no-repeat center;
						background-size : contain;
					}

					.flag-it {
						background      : url("../../images/icons/icon_flag_it.svg") no-repeat center;
						background-size : contain;
					}

					.flag-es {
						background      : url("../../images/icons/icon_flag_es.svg") no-repeat center;
						background-size : contain;
					}

					.flag-de {
						background      : url("../../images/icons/icon_flag_de.svg") no-repeat center;
						background-size : contain;
					}

					.flag-nl {
						background      : url("../../images/icons/icon_flag_nl.svg") no-repeat center;
						background-size : contain;
					}
				}
				> div {
					padding : 16px;
					&:empty {
						display : none;
					}
				}
				img {
					display : block;
					width   : 100%;
				}
				.title {
					display     : block;
					width       : 100%;
					height      : 40px;
					line-height : 40px;
					text-align  : center;
					p {
						display        : inline-block;
						vertical-align : middle;
						font-size      : 15px;
						line-height    : 20px;
						max-height     : 40px;
						overflow       : hidden;
					}
				}
				button {
					position : absolute;
					top      : 0;
					bottom   : 0;
					left     : 0;
					right    : 0;
					width    : 100%;
					border   : 1px solid rgba($vert, 0.1);
					@include radius(8px);
					.btn_cta {
						position : absolute;
						left     : 16px;
						bottom   : 8px;
						right    : 16px;
						width    : auto;
					}
					&:hover {
						.btn_cta {
							@extend .hover;
						}
					}
				}
			}
		}
		#payment-step-form {
			display : block;
			width   : 100%;
		}
	}
}

.paiement_confirm {
	.ck-content {
		margin-bottom : 24px;
	}
}

.availability-offer-list {
	.reset-query {
		margin-top : -16px;
		color      : $vert;
	}
}
