.content_table {
	display  : block;
	width    : 100%;
	overflow : auto;
}

.mat-mdc-table {
	@include shadow(none);
	border     : 1px solid $gris;
	min-width  : 100%;
	background : $blanc;
	tr {
		border-bottom : 1px solid $gris;
		height        : auto !important;
		min-height    : 45px;
		th, td {
			border-bottom  : 0;
			border-right   : 1px solid $gris;
			padding        : 8px !important;
			font-size      : 13px;
			line-height    : 16px;
			vertical-align : middle;
			min-width      : 160px;
			&.mat-column-actions, &.mat-column-actions-filter {
				min-width  : inherit;
				width      : auto;
				text-align : center;
			}
			&.mat-column-select, &.mat-column-select-filter {
				min-width  : 50px;
				width      : auto;
				text-align : center;
			}
			&.society-provider-reference {
				min-width : 220px;
			}
		}
	}
	thead {
		background : rgba($vert, 0.2);
		.mat-mdc-header-row {
			background : transparent;
		}
		tr:last-child {
			border-bottom-color : $grisfonce;
		}
		th {
			color : rgba($noir, 0.54);
		}
		.mat-sort-header {
			.mat-sort-header-container {
				display       : inline-block;
				width         : 100%;
				padding-right : 16px;
				position      : relative;
				.mat-sort-header-content {
					text-align : left;
				}
				.mat-sort-header-arrow {
					position    : absolute;
					right       : 0;
					top         : 50%;
					margin-top  : -8px;
					width       : 16px;
					height      : 16px;
					line-height : 16px;
					text-align  : center;
					padding     : 0;
					@include transform(translateY(0) !important);
					> div {
						display : none;
					}
					&:after {
						content        : "\e004";
						font-family    : "ty-win";
						display        : inline-block;
						vertical-align : middle;
						font-size      : 12px;
						line-height    : 14px;
						height         : 14px;
					}
				}
			}
			&:not([aria-sort='descending']) {
				.mat-sort-header-container .mat-sort-header-arrow:after {
					@include transform(rotateX(180deg));
				}
			}
		}
		.mat-mdc-form-field {
			display : block;
			width   : 100%;
			.mat-mdc-form-field-bottom-align {
				display : none !important;
			}
			.mat-mdc-text-field-wrapper {
				padding : 0;
				.mat-mdc-form-field-underline, .mat-mdc-form-field-focus-overlay, .mdc-line-ripple {
					display : none !important;
				}
			}
			.mat-mdc-form-field-infix {
				display    : block;
				width      : 100%;
				padding    : 0;
				height     : 40px;
				min-height : inherit;
				border     : none;
				cursor     : pointer;
				input, mat-select {
					display     : block;
					width       : 100%;
					height      : 100%;
					padding     : 0 8px;
					padding-top : 10px;
					border      : 1px solid $gris;
					background  : $blanc;
					font-size   : 12px;
					color       : $grisfonce;
					position    : relative;
					box-sizing  : border-box;
					z-index     : -1;
					&:not(:disabled) {
						cursor : pointer;
					}
				}
				mat-select {
					padding-top : 17px;
					&:after {
						content     : "\e007";
						font-family : "ty-win";
						@include y_align_absolute;
						right       : 8px;
						font-size   : 10px;
						opacity     : 0.5;
					}
					.mat-mdc-select-trigger {
						width         : 100%;
						padding-right : 8px;
						.mat-mdc-select-value {
							.mat-select-value-text {
								width         : 100%;
								display       : block;
								padding-right : 10px;
							}
							span span {
								//padding-top : 8px;
								//display     : block;
								line-height : 1em;
							}
						}
						.mat-mdc-select-arrow-wrapper {
							display : none !important;
						}
					}
				}
				label {
					position    : absolute;
					left        : 8px;
					right       : 8px;
					font-size   : 12px;
					line-height : 14px;
					top         : 12px;
					width       : auto;
					margin      : 0;
					transform   : translateY(0) !important;
					color       : $grisfonce !important;
					opacity     : 0.5;
					@include transition($transition);
					cursor      : pointer;
					&.mdc-floating-label--float-above {
						top         : 4px;
						font-size   : 10px;
						line-height : 12px;
					}
				}
			}
			.mat-mdc-form-field-flex {
				background : none;
				padding    : 0;
				border     : none;
			}
		}
		.mat-mdc-form-field-type-mat-select {
			.mat-mdc-form-field-flex {
				padding : 0;
			}
		}
		app-core-date-interval-filter {
			@include d_flex;
			@include y_items_center;
			mat-form-field {
				@include col_auto;
				@include y_items_center;
				width : auto !important;
				.mat-mdc-text-field-wrapper {
					background : none;
				}
				.mat-mdc-form-field-flex {
					@include d_flex;
					@include y_items_center;
				}
				.mat-mdc-form-field-infix {
					height : auto !important;
					@include y_items_center;
				}
				.mat-mdc-form-field-suffix {
					@include y_items_center;
				}
				.mat-date-range-input {
					width : 120px;
				}
				.mat-date-range-input-container {
					display : block;
					.mat-date-range-input-start-wrapper, .mat-date-range-input-end-wrapper, .mat-date-range-input-wrapper {
						display   : block;
						width     : 100%;
						max-width : 100%;
						overflow  : visible;
						z-index   : 3;
						input {
							height      : 32px;
							padding-top : 0;
						}
					}
					.mat-date-range-input-end-wrapper {
						margin-top : 5px;
					}
					.mat-date-range-input-separator, .mat-date-range-input-mirror {
						display : none;
					}
				}
			}
			> button {
				@include d_flex;
				@include col_content;
				@include y_items_center;
			}
			> button, .mat-datepicker-toggle button {
				display     : block;
				width       : 24px !important;
				height      : 24px !important;
				line-height : 24px !important;
				font-size   : 13px !important;
				margin-left : 5px;
				color       : $noir;
				opacity     : 0.5;
				@include transition($transition);
				@include hover {
					opacity : 1;
				}
				.mat-ripple {
					display : none !important;
				}
				.mat-icon {
					font-size : 21px;
				}
			}
		}
	}
	tbody {
		.mat-column-actions {
			display      : block;
			white-space  : nowrap;
			padding      : 8px;
			text-align   : center;
			border       : none;
			border-right : 1px solid $gris;
			button, a {
				display        : inline-block;
				vertical-align : middle;
				width          : 32px;
				height         : 32px;
				line-height    : 32px;
				min-width      : 0;
				padding        : 0;
				color          : $noir;
				opacity        : 0.5;
				@include transition($transition);
				@include radius(50%);
				+ button, + a {
					margin-left : 8px;
				}
				.mat-button-ripple.mat-ripple, .mat-button-focus-overlay, .mat-ripple {
					display : none !important;
				}
				.mat-icon {
					display        : inline-block;
					vertical-align : middle;
					font-size      : 18px;
					height         : 18px;
					line-height    : 18px;
					width          : 18px;
					margin-right   : 0;
				}
				&.check {
					color   : $success;
					opacity : 0.8;
				}
				&.close {
					color   : $danger;
					opacity : 0.8;
				}
				@include hover {
					opacity    : 1;
					background : $gris;
				}
				&.force-hover {
					opacity : 1;
				}
			}
		}
	}
	.mat-mdc-checkbox {
		.mat-ripple, .mat-mdc-checkbox-touch-target, .mdc-checkbox__ripple {
			display : none !important;
		}
		&.mat-mdc-checkbox-checked {
			.mdc-checkbox__background {
				background   : $vert !important;
				border-color : $vert !important;
			}
		}
	}
}

page-booking-list, app-page-gift-voucher-list {
	.mat-mdc-table {
		border-left : none;
		thead {
			.mat-mdc-header-row {
				position : relative;
			}
			.mat-column-actions, .mat-column-actions-filter, .mat-column-select, .mat-column-select-filter {
				width       : 65px;
				position    : sticky;
				left        : 0;
				top         : 0;
				z-index     : 2;
				background  : $blanc;
				border-left : 1px solid $gris;
				&:before {
					content    : '';
					position   : absolute;
					top        : 0;
					left       : 0;
					bottom     : 0;
					right      : 0;
					z-index    : 1;
					background : rgba($vert, 0.2);
				}
				> * {
					position : relative;
					z-index  : 2;
				}
			}
			.mat-column-select, .mat-column-select-filter {
				width : 50px;
				+ .mat-column-actions, + .mat-column-actions-filter {
					left        : 50px;
					margin-left : -1px;
				}
			}
		}
		tbody {
			.mat-mdc-row {
				position : relative;
			}
			.mat-column-actions, .mat-column-select {
				position    : sticky;
				left        : 0;
				top         : 0;
				width       : 60px;
				background  : $blanc;
				border-left : 1px solid $gris;
				@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
					display : table-cell;
				}
			}
			.mat-column-select {
				width : 50px;
				+ .mat-column-actions {
					left        : 50px;
					margin-left : -1px;
				}
			}
		}
	}
}

page-booking-list {
	.mat-mdc-table {
		tbody {
			.mat-mdc-row {
				background : transparent;
				@include transition($transition);
				@include hover {
					background : $grisclair;
					.mat-column-actions button {
						background : transparent !important;
						.mdc-button__ripple {
							display : none;
						}
						.mat-icon {
							background : $grisclair;
						}
					}
				}
			}
			.mat-column-actions {
				position   : static;
				background : transparent;
				@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
					position   : sticky;
					background : $blanc;
				}
				button {
					position      : absolute;
					left          : 0;
					top           : 0;
					bottom        : 0;
					right         : 0;
					width         : auto;
					height        : auto;
					z-index       : 1;
					border-radius : 0;
					background    : transparent !important;
					text-align    : left;
					opacity       : 1;
					.mat-icon {
						position     : sticky;
						left         : 0;
						top          : 0;
						bottom       : 0;
						height       : calc(100% - 1px);
						margin-top   : 1px;
						width        : 65px;
						padding      : 8px;
						padding-top  : 15px;
						display      : block;
						text-align   : center;
						background   : $blanc;
						border-left  : 1px solid #eeeeee;
						border-right : 1px solid #eeeeee;
						@include transition($transition);
					}
					.mat-ripple {
						display : none;
					}
				}
			}
		}
	}
}

app-page-gift-voucher-list {
	.mat-mdc-table tbody .mat-column-actions {
		width : 95px;
	}
}

app-core-page-quotation-list {
	.mat-mdc-table tr {
		th, td {
			&.mat-column-reference {
				white-space : nowrap;
			}
		}
	}
}
