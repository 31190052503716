$pageCmsMap : (
    '.content_cms .cms_row .cms_column [class*="element_"]' : (
        'padding' : (
            16px,
            32px
        ),
    ),
    '.content_cms .cms_row .cms_column .element_image .texte-visuel, .cms_row .cms_column .element_slideshow .texte-visuel' : (
        'padding' : (
            8px,
            16px
        ),
    ),
    '.content_cms .cms_row .cms_column .element_image .texte-visuel .titre, .cms_row .cms_column .element_slideshow .texte-visuel .titre' : (
        'font-size' : (
            14px,
            16px
        ),
        'line-height' : (
            18px,
            20px
        ),
    ),
    '.content_cms .cms_row .cms_column .element_image .texte-visuel .soustitre, .cms_row .cms_column .element_slideshow .texte-visuel .soustitre' : (
        'font-size' : (
            13px,
            14px
        ),
        'line-height' : (
            16px,
            18px
        ),
    ),
);

@include fluid($pageCmsMap);

.content_cms .cms_row {
    @extend .marges;
    .cms_column {
        margin-top    : 12px;
        margin-bottom : 12px;
        > div {
            @extend .y_content_start;
            &.without_bg, &.bg_blanc {
                background : $blanc;
            }
            &.bg_grey {
                background : $gris;
            }
            &.bg_black {
                background : $noir;
                .ck-content{
                    @extend .txt_blanc;
                }
            }
            &.bg_green {
                background : $vert;
            }
            &.bg_degrade {
                @include degrade($vert, $vertfonce);
            }
        }
        [class*='element_'] {
            &.element_image, &.element_slideshow, &.element_iframe {
                padding : 0;
                + [class*='element_'] {
                    margin-top : 24px;
                }
            }
            &:not(.element_image):not(.element_slideshow):not(.element_iframe) {
                + [class*='element_'] {
                    padding-top : 0;
                }
            }
            app-cms-button, app-cms-text, app-cms-file, app-cms-image, app-cms-slideshow, app-cms-iframe, app-cms-video {
                display : block;
                width   : 100%;
                @include media_x_min('md') {
                    height : 100%;
                }
            }
        }
        .element_image, .element_slideshow {
            position : relative;
            img, a {
                display : block;
            }
            img {
                width : 100%;
            }
            .texte-visuel {
                position   : absolute;
                z-index    : 2;
                bottom     : 0;
                left       : 0;
                width      : 100%;
                background : rgba($noir, 0.75);
                p {
                    color : $blanc;
                }
                .titre {
                    font-weight : bold;
                }
                .soustitre {
                    margin-top : 3px;
                }
            }
            iframe {
                max-width : 100%;
            }
        }
        .element_button, .element_file {
            @extend .row;
        }
    }
}
