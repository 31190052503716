.mat-mdc-paginator {
	color : rgba($noir, 0.6);
	.mat-mdc-paginator-page-size {
		.mat-mdc-form-field {
			width : auto;
			.mat-mdc-text-field-wrapper {
				padding : 0;
				.mdc-notched-outline {
					display : none;
				}
				.mat-mdc-form-field-infix {
					padding       : 0;
					min-height    : inherit;
					width         : 56px;
					border-bottom : 1px solid $gris2;
				}
			}
		}
	}
}