.offer_calendar {
    position   : absolute;
    right      : 6px;
    top        : -6px;
    background : $blanc;
    z-index    : 1001;
    padding    : 24px;
    width      : calc(100% - 12px);
    max-width  : 800px;
    @include radius(15px 15px 15px);
    @include shadow();
    > div {
        display  : block;
        width    : 100%;
        position : relative;
    }
    .navigation {
        position : absolute;
        z-index  : 2;
        top      : 0;
        left     : 0;
        right    : 0;
        height   : 48px;
        .btn_cta {
            @include y_align_absolute;
            padding : 0 6px;
            &.prev {
                left : 0;
            }
            &.next {
                right : 0;
            }
        }
    }
    .month {
        width         : 48%;
        margin-bottom : 24px;
        .month_label {
            display       : block;
            width         : 100%;
            height        : 48px;
            line-height   : 48px;
            text-align    : center;
            margin-bottom : 8px;
            span {
                display        : inline-block;
                vertical-align : middle;
                @include font_lato_bold;
                font-size      : 18px;
                line-height    : 22px;
                color          : $grisfonce;
                text-transform : capitalize;
            }
        }
        .day {
            width      : calc(100% / 7);
            text-align : center;
            padding    : 6px;
            position   : relative;
            span {
                display : block;
                margin  : auto;
            }
            .legend-icon {
                top  : 6px;
                left : 6px;
            }
            &.day_name {
                font-size      : 13px;
                line-height    : 15px;
                text-transform : uppercase;
                color          : $vert;
            }
            .day_number {
                font-size     : 14px;
                line-height   : 17px;
                color         : $grisfonce;
                width         : 28px;
                height        : 28px;
                text-align    : center;
                padding       : 6px 0;
                @include radius(50%);
                margin-bottom : 4px;
            }
            .day_info {
                font-size   : 9px;
                line-height : 10px;
                color       : $grisfonce;

                .promotion {
                    color : $vert;
                }
            }
            &.current {
                .day_number {
                    background : $grisfonce;
                    color      : $blanc !important;
                }
            }
            &.available, &.onRequest {
                cursor : pointer;
                .day_number {
                    @include transition($transition);
                }
                @include hover {
                    .day_number {
                        background : rgba($grisfonce, 0.2);
                        color      : $grisfonce;
                    }
                }
            }
            &.onRequest {
                .day_number {
                    color : rgba($grisfonce, 0.6)
                }
                .day_info {
                    opacity : 0.6;
                }
            }
            &.closed {
                .day_number {
                    color : rgba($grisfonce, 0.45)
                }
                .day_info {
                    opacity : 0.45;
                }
            }
            &.startSelection {
                .day_number {
                    background : $vert !important;
                    color      : $grisfonce;
                }
                &.current {
                    .day_number {
                        color : $grisfonce !important;
                    }
                }
            }
            &.endSelection {
                .day_number {
                    background : $vert !important;
                    color      : $grisfonce;
                }
                &.current {
                    .day_number {
                        color : $grisfonce !important;
                    }
                }
            }
            &.selection {
                .day_number {
                    background : rgba($vert, 0.4);
                    color      : $grisfonce;
                }
            }
        }
    }
    .quotation_info {
        p {
            font-size   : 12px;
            line-height : 15px;
            color       : $txt;
            margin      : 0;
            + p {
                margin-top : 4px;
            }
            strong {
                @include font_lato_bold;
                font-size   : 14px;
                line-height : 18px;
                color       : $grisfonce;
            }
        }
    }
    .buttons {
        .btn_cta {
            margin-left : 16px;
        }
    }
    .legend-icon {
        display  : block;
        width    : 14px;
        height   : 14px;
        position : absolute;
        // rond vert
        &.legend-available {
            &:after {
                content    : '';
                position   : absolute;
                top        : 50%;
                left       : 50%;
                @include transform(translateX(-50%) translateY(-50%));
                width      : 10px;
                height     : 10px;
                @include radius(50%);
                background : $success;
            }
        }
        //losange orange
        &.legend-onbooking {
            &:after {
                content    : '';
                position   : absolute;
                top        : 50%;
                left       : 50%;
                @include transform(translateX(-50%) translateY(-50%));
                width      : 10px;
                height     : 10px;
                @include radius(50%);
                background : transparent;
                border : 2px solid $warning;
            }
        }
        //carré gris
        &.legend-onrequest {
            &:after {
                content    : '';
                position   : absolute;
                top        : 50%;
                left       : 50%;
                @include transform(translateX(-50%) translateY(-50%));
                width      : 10px;
                height     : 10px;
                @include radius(50%);
                background : transparent;
                border : 2px solid $gris2;
            }
        }
    }
    .legends {
        margin-top : 10px;
    }
    .legend {
        display       : block;
        padding-left  : 24px;
        position      : relative;
        margin-bottom : 8px;
        font-size     : 13px;
        .legend-icon {
            @include y_align_absolute;
            left : 0;
        }
    }
    .choice_price {
        margin-bottom : 16px;
        mat-radio-button + mat-radio-button {
            margin-left : 24px;
        }
    }
}

@include media_x_max('md') {
    .offer_calendar {
        right : -4px;
        top   : -4px;
        width : calc(100% + 8px);
        .month {
            width : 100%;
        }
        .quotation_info {
            margin-bottom : 24px;
        }
    }
}
