body, & {
	* {
		letter-spacing : normal;
	}
	&.has_panel_open {
		.mat-drawer-container {
			.mat-drawer-content {
				z-index : 3 !important;
			}
		}
	}
}

.mat-drawer-container {
	position : fixed !important;
	z-index  : 1;
	width    : 100vw !important;
	height   : auto !important;
	left     : 0;
	top      : 80px;
	bottom   : 0;
	overflow : hidden;
	.mat-drawer {
		position   : absolute;
		left       : 0;
		top        : 0;
		bottom     : 0;
		width      : 275px;
		z-index    : 3 !important;
		background : $blanc;
		@include shadow(0 2px 30px 0 rgba($noir, 0.05));
	}
	.mat-drawer-content {
		position   : absolute;
		z-index    : 1 !important;
		top        : 0;
		bottom     : 0;
		right      : 0;
		left       : 275px;
		background : $blanc;
		margin     : 0 !important;
		padding    : 24px 32px;
		> .layout {
			display : block;
			width   : 100%;
			height  : 100%;
		}
	}
	.page_title {
		display        : block;
		width          : 100%;
		margin-bottom  : 24px;
		padding-bottom : 16px;
		border-bottom  : 1px solid $gris;
		position       : sticky;
		top            : 0;
		left           : 0;
		z-index        : 999;
		> div {
			position : relative;
			z-index  : 2;
		}
		&:before {
			content    : '';
			position   : absolute;
			bottom     : 0;
			top        : -24px;
			left       : -32px;
			right      : -32px;
			background : $blanc;
			z-index    : 1;
		}
		.title {
			font-size   : 24px;
			line-height : 30px;
			padding     : 0;
			margin      : 0;
		}
		.licences {
			display      : block;
			width        : 100%;
			padding-left : 12px;
			span {
				display     : block;
				font-size   : 13px;
				line-height : 15px;
				margin-top  : 4px;
				color       : $noir;
				opacity     : 0.6;
				font-style  : italic;
			}
		}
		.text_title {
			display    : block;
			width      : 100%;
			padding    : 16px;
			background : rgba($vert, 5%);
			margin-top : 24px;
		}
		.btn_cta {
			+ .btn_cta {
				margin-left : 16px;
			}
		}
		app-core-search-autocomplete {
			width : 300px;
		}
		.offer_name {
			display    : block;
			margin-top : 4px;
			a, span {
				display        : inline-block;
				vertical-align : middle;
				font-size      : 18px;
				line-height    : 20px;
				color          : $noir;
			}
			a {
				@include hover {
					text-decoration : underline;
				}
			}
			.offer-reference {
				opacity : 0.5;
			}
		}
		.col + .col_12 {
			margin-top : 16px;
		}
	}
	.mat-mdc-card-actions {
		@include d_flex;
		@include x_end;
		width         : 100%;
		margin-bottom : 24px;
	}
}

.mat-primary .mat-mdc-option.mat-selected:not(.mat-mdc-option-disabled) {
	color : $noir;
	@include font_lato_bold;
}

.text_content {
	text-align  : left;
	font-size   : 14px;
	line-height : 18px;
	p {
		@include font_lato_regular;
		color : $grisfonce;
		&:not(:last-child) {
			margin-bottom : 8px;
		}
	}
	a {
		display : inline;
		color   : $grisfonce;
		@include hover {
			text-decoration : underline;
		}
	}
	strong {
		@include font_lato_bold;
	}
	&.no_result {
		text-align : center;
	}
	mat-icon {
		display        : inline-block;
		vertical-align : middle;
	}
}

app-core-page-offer-create, app-core-page-offer-update,
app-core-page-user-update, app-core-page-user-create,
app-core-page-channel-create, app-core-page-channel-update, app-core-page-quotation-create, app-core-page-tender-create,
app-page-template-gift-voucher-create, app-page-template-gift-voucher-update,
app-page-gift-voucher-update, app-page-gift-voucher-read,
app-core-page-society-distributor-update,
app-gift-voucher-configuration-form {
	display        : block;
	width          : 100%;
	height         : 100%;
	padding-bottom : 12px;
	overflow       : hidden;
	> form {
		display : block;
		width   : 100%;
		height  : 100%;
		> .mat-mdc-tab-group {
			width  : 100%;
			height : calc(100% - 89px);
			.mat-mdc-tab-body-content {
				overflow-x : hidden;
				overflow-y : visible;
			}
		}
	}

}
