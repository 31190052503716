.bloc_offers_filters_banner {
	display       : flex;
	align-items   : center;
	padding       : 24px 32px;
	border-bottom : 1px solid $gris;

	.list_filters {
		@include d_flex;
		flex-grow       : 1;
		justify-content : space-between;
		padding-right   : 24px;

		.filter_item {
			@include d_flex;
			@include y_items_center;
			width : calc(20% - ((12px * 4) / 5) - (24px / 5)); // 5 is the number of filters
			> * {
				display : block;
				width   : 100%;
			}
			& + .filter_item {
				margin-left : 12px;
			}

			// All filters
			.mat-mdc-form-field {
				width : 100%;
				.mat-mdc-text-field-wrapper {
					padding-bottom : 0;
					width          : 100%;
					.mat-mdc-form-field-flex {
						background-color : $blanc;
						padding          : .25em 0;
						.mat-mdc-form-field-infix {
							padding          : 0;
							border-top-width : 0.5em;
						}
					}
					.mat-mdc-form-field-underline {
						bottom : 0;
						.mat-mdc-form-field-ripple {
							display : none;
						}
					}
				}

				&.mat-focused {
					.mat-mdc-form-field-label-wrapper {
						label {
							color : $noir;
						}
					}
				}
			}

			// Text
			app-core-text-filter {
				.mat-mdc-form-field {
					.mat-mdc-text-field-wrapper {
						.mat-mdc-form-field-flex {
							.mat-mdc-form-field-infix {
								.mat-mdc-form-field-label-wrapper {
									label {
										top : 0.65em;
									}
								}
							}
						}
						.mat-mdc-form-field-underline {
							background-color : $gris;
						}
					}
					&.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float {
						.mat-mdc-form-field-label-wrapper {
							label {
								@include transform(translateY(-0.65em) scale(0.75) perspective(100px) translateZ(0.001px));
							}
						}
					}
				}
			}

			// Select multiple
			app-core-select-array-multiple-filter,
			app-core-select-filter,
			app-core-select-boolean-filter,
			app-core-boolean-filter {
				.mat-mdc-form-field {
					.mat-mdc-text-field-wrapper {
						.mat-mdc-form-field-flex {
							.mat-mdc-form-field-infix {
								width : auto; // Width defined by .filter_item
							}
						}
						.mat-mdc-form-field-underline {
							&:before {
								background-color : $gris;
							}
						}
					}
					&.mat-focused {
						.mat-select-arrow {
							color : rgba($noir, 0.5);
						}
					}
				}
			}

			// Date filter
			&.filter_dates {
				flex-direction : column;
			}

			&.filter_item_range {
				position : relative;
				// Range
				app-core-date-interval-filter {
					@include d_flex;
					@include y_items_center;

					.mat-mdc-form-field {
						@include col_auto;
						@include y_items_center;
						.mat-mdc-form-field-flex {
							@include d_flex;
							@include y_items_center;
							border        : 1px solid $noir;
							padding       : 0 !important;
							border-radius : 4px;
							.mat-mdc-form-field-infix {
								border-top-width : unset !important;
								width            : 50%;
							}
							.mat-mdc-form-field-suffix {
								@include y_items_center;
							}
						}

						.mat-date-range-input-inner {
							padding-top : 0;
						}

						.mat-date-range-input-container {
							@include d_flex;
							flex-direction : column;
							@include y_items_start;

							.mat-date-range-input-start-wrapper,o
							.mat-date-range-input-end-wrapper {
								display   : block;
								width     : 100%;
								max-width : 100%;
								overflow  : visible;
								padding   : 0.5em 0.35em;

								input {
									padding-top : 0;
								}
							}

							.mat-date-range-input-separator {
								padding-top : 8px;
							}

							.mat-date-range-input-start-wrapper {
								input {
									top  : unset !important;
									left : unset !important;
								}
							}

							.mat-date-range-input-end-wrapper {
								border-top : 1px solid $noir;
							}

							.mat-date-range-input-separator {
								display : none;
							}
						}
						.mat-mdc-form-field-underline {
							display : none;
							.mat-mdc-form-field-ripple {
								display : none;
							}
						}
					}
					& > button {
						&:first-of-type {
							display : none;
						}
						&:nth-of-type(2) {
							position : absolute;
							top      : 0;
							right    : -15px;
							.mat-icon {
								height    : 20px;
								width     : 20px;
								font-size : 20px;
							}
						}
					}
				}
			}

		}
	}

	.filters_reinit {
		@include radius(30px);
		height : 40px;
	}
}

.bloc_offers_filters {
	display : block;
	width   : 100%;
	> div {
		display       : block;
		width         : 100%;
		padding       : 24px;
		border-bottom : 1px solid $gris;
	}
	.filters {
		app-core-offer-filter {
			display : inline;
		}
	}
	.filters_checked {
		padding : 0;
		&:has(*) {
			padding-top : 16px;
		}
		.filter {
			margin-top   : 8px;
			margin-right : 12px;
			font-size    : 13px;
			line-height  : 16px;
			background   : rgba($gris2, 0.5);
			color        : $noir;
			@include transition($transition);
			.close {
				right : 5px;
				mat-icon {
					font-size : 16px;
					height    : 16px;
					width     : 16px;
				}
			}
			@include hover {
				background : rgba($gris2, 0.8);
			}
		}
	}
	.bloc_offers_filters_more {
		display       : block;
		width         : 100%;
		padding       : 24px;
		border-bottom : 1px solid $gris;
	}
	.filter_item {
		display        : inline-block;
		vertical-align : middle;
		margin-right   : 16px;
		margin-bottom  : 24px;
		.mat-mdc-form-field {
			.mat-mdc-form-field-bottom-align {
				display : none;
			}
			.mat-mdc-text-field-wrapper {
				padding       : 0;
				height        : 36px;
				border-bottom : 1px solid $gris2;
				background    : transparent;
				.mat-mdc-form-field-flex {
					display    : block;
					width      : 100%;
					height     : 100%;
					padding    : 0;
					background : transparent;
					.mat-mdc-form-field-infix {
						display    : block;
						width      : 160px;
						height     : 100%;
						padding    : 0;
						border     : none;
						min-height : inherit;
					}
				}
				.mat-mdc-form-field-focus-overlay, .mdc-line-ripple, .mat-form-field-underline, .mat-form-field-subscript-wrapper {
					display : none;
				}
			}
			mat-select {
				display : block;
				width   : 100%;
				height  : 100%;
				padding : 0;
				.mat-mdc-select-trigger {
					display : block;
					&, .mat-mdc-select-value {
						width     : 100%;
						max-width : 100%;
						height    : 100%;
						padding   : 0;
						position  : relative;
					}
					.mat-mdc-select-value {
						@include d_flex;
						@include x_start;
						@include y_items_center;
						font-size     : 13px;
						color         : $noir !important;
						padding-right : 14px;
						padding-top   : 5px;
					}
				}
				.mat-mdc-select-arrow-wrapper {
					@include y_align_absolute;
					right  : 0;
					width  : 12px;
					height : 12px;
					&:after {
						content     : "\e004";
						font-family : "ty-win";
						display     : block;
						font-size   : 11px;
						color       : $noir;
					}
					> * {
						display : none;
					}
				}
			}
			.mat-mdc-input-element, .mat-date-range-input-inner {
				display     : block;
				width       : 100%;
				height      : 100%;
				font-size   : 13px;
				color       : $noir !important;
				caret-color : $noir;
				padding-top : 8px;
			}
			label {
				position    : absolute;
				font-size   : 13px;
				line-height : 16px;
				top         : 10px;
				left        : 0;
				padding     : 0;
				margin      : 0;
				width       : 100%;
				color       : $noir !important;
				opacity     : 0.5;
				@include transform(translateY(0) !important);
				@include transition($transition);
				&.mdc-floating-label--float-above {
					top         : 0;
					font-size   : 10px;
					line-height : 12px;
				}
			}
			&.mat-mdc-form-field-should-float {
				mat-select {
					.mat-mdc-select-trigger {
						.mat-mdc-select-value {
							padding-top : 12px;
						}
					}
				}
				.mat-mdc-input-element {
					padding-top : 6px;
				}
				.mat-mdc-form-field-label-wrapper {
					.mat-form-field-label {
						top       : 1px;
						font-size : 10px;
					}
				}
			}
		}
		app-core-date-interval-filter {
			display : block;
			> button {
				display : none;
			}
			.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
				width : 190px;
			}
			.mat-date-range-input {
				display     : block;
				width       : 100%;
				height      : 100%;
				padding-top : 0 !important;
				.mat-date-range-input-container {
					width  : 100%;
					height : 100%;
				}
				&:not(.mat-date-range-input-hide-placeholders) {
					.mat-date-range-input-separator {
						display : none;
					}
				}
			}
		}
		.form-group {
			.checkbox {
				margin     : 0;
				min-height : 16px;
				label {
					padding-left : 22px;
					&:before {
						content     : "\e003";
						font-family : "ty-win" !important;
						width       : 16px;
						height      : 16px;
						line-height : 14px;
						text-align  : center;
						border      : 1px solid $grisfonce;
						color       : $blanc;
						font-size   : 11px;
						font-weight : bold;
						@include radius(2px);
						@include transition($transition);
					}
					&:after {
						display : none;
					}
				}
				input:checked ~ label {
					&:before {
						background : $grisfonce;
					}
				}
			}
		}
		&.filter_item_big {
			.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
				width: 220px;
			}
		}
		&.filter_item_small {
			.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
				width: 120px;
			}
		}
	}
	.bloc_filter {
		display       : block;
		width         : 100%;
		max-width     : 250px;
		margin-bottom : 32px;
		.filters_title {
			display       : block;
			font-size     : 15px;
			line-height   : 18px;
			font-weight   : 500;
			color         : $noir;
			margin-bottom : 12px;
		}
		.filter_section_title {
			display       : block;
			font-size     : 14px;
			line-height   : 18px;
			font-weight   : 400;
			color         : $noir;
			margin-bottom : 6px;
		}
		.filter_item {
			display       : block;
			width         : 100%;
			margin-right  : 0;
			margin-bottom : 12px;
			> *, .mat-mdc-form-field {
				display : block;
				width   : 100%;
			}
			.mat-mdc-form-field-infix {
				width : 100% !important;
			}
		}
		.filter_duo_group {
			margin-bottom : 12px;
			.filter_item {
				margin : 0;
				@include d_flex;
				border : 1px solid $noir;
				> * {
					+ * {
						border-left : 1px solid $noir;
					}
				}
				&:first-child {
					border-top-left-radius  : 8px;
					border-top-right-radius : 8px;
				}
				&:last-child {
					border-bottom-left-radius  : 8px;
					border-bottom-right-radius : 8px;
				}
				.mat-mdc-text-field-wrapper {
					border : none;
				}
				.mat-mdc-form-field {
					.mat-mdc-input-element, .mat-date-range-input-inner {
						padding-left  : 6px;
						padding-right : 6px;
					}
					label {
						left  : 6px;
						right : 6px;
					}
				}
			}
		}
	}
}

.filter_item.network_offer_creator {
	.mat-mdc-form-field-infix {
		min-width : 200px;
	}
}
