.mat-mdc-tab-group {
	.mat-mdc-tab-header {
		border-bottom : 1px solid $gris;
		.mat-mdc-tab-labels {
			.mat-ripple, .mdc-tab__ripple {
				display : none !important;
			}
			.mat-mdc-tab {
				opacity   : 1;
				padding   : 0 24px;
				flex-grow : 0;
				.mdc-tab__content {
					@include font_lato_regular;
					font-size   : 14px;
					line-height : 18px;
					color       : $grisfonce !important;
					white-space : pre-wrap;
					.mdc-tab__text-label {
						color            : inherit;
						transition-delay : 0s;
						transition       : none;
					}
				}
				&.mdc-tab--active {
					.mdc-tab-indicator {
						.mdc-tab-indicator__content--underline {
							border-color     : $vert !important;
							border-top-width : 1px;
						}
					}
					.mdc-tab__content {
						@include font_lato_bold;
						color : $noir !important;
					}
				}

				.invalid-tab {
					display        : inline-block;
					vertical-align : middle;
					font-size      : 15px;
					line-height    : 16px;
					color          : $danger;
					margin-left    : 6px;
					&:before {
						content        : 'error_outline';
						font-family    : "Material Icons";
						display        : inline-block;
						vertical-align : middle;
					}
				}

				.valid, .invalid {
					display        : inline-block;
					vertical-align : middle;
					margin-left    : 6px;
					font-size      : 18px;
					line-height    : 18px;
					font-weight    : bold;
				}

				.valid {
					padding-top : 2px;
					&:before {
						content     : 'check';
						font-family : "Material Icons";
						color       : $success;
					}
				}

				.invalid {
					padding-top : 4px;
					&:before {
						content     : 'close';
						font-family : "Material Icons";
						color       : $danger;
					}
				}
				&.mat-mdc-tab-disabled:not(.mdc-tab--active) {
					opacity : 0.3;
				}

			}
		}

		.mat-mdc-tab-header-pagination {
			.mat-ripple-element {
				display : none !important;
			}
		}

	}

	.mat-mdc-tab-body > div {
		padding-top    : 24px;
		padding-bottom : 24px;

		.tab_content_overflow { // For content with box shadows, so the shadows are not cut on left and right
			margin-right : 12px;
			margin-left  : 12px;
		}
		> .mat-tab-translations {
			margin-top : -24px;
			border-top : none;
		}

		.content_tab {
			overflow-x : hidden;
		}
	}

	.mat-mdc-tab-group {
		margin-top : 24px;
	}
}

.mat-tab-translations {
	display     : block;
	position    : relative;
	width       : 100%;
	border      : 1px solid rgba($grisfonce, 0.3);
	@include radius(4px);
	padding-top : 8px;

	app-core-translation-add {
		position : absolute;
		z-index  : 2;
		left     : 8px;
		top      : 8px;
		width    : 180px;
		height   : 48px;
		padding  : 8px;

		button {
			display        : block;
			width          : 100%;
			height         : 36px;
			border         : 1px solid $gris;
			background     : $grisclair;
			@include font_lato_regular;
			font-size      : 14px;
			color          : $grisfonce;
			padding-bottom : 2px;
			@include radius(4px);
			@include transition($transition);

			&:before {
				content        : '+';
				display        : inline-block;
				vertical-align : middle;
				margin-right   : 8px;
				font-size      : 1.4em;
				line-height    : 0.5em;
				margin-top     : -3px;
				font-weight    : bold;
			}

			@include hover {
				background : $gris;
			}
		}

		+ .mat-mdc-tab-group {
			.mat-mdc-tab-header {
				padding-left : 188px;
			}
		}
	}

	.mat-mdc-tab-group {
		margin : 0;

		.mat-mdc-tab-header {
			border       : none;
			height       : 52px;
			padding-left : 8px;
			.mat-mdc-tab-labels {

			}
			.mat-mdc-tab {
				height        : 36px;
				margin        : 8px;
				background    : $gris;
				min-width     : inherit;
				border-bottom : 1px solid transparent;
				flex-grow     : initial;
				@include radius(4px);
				@include transition($transition);
				.mdc-tab__content {
					@include font_lato_regular;
					font-size : 14px;
					color     : $grisfonce;
				}
				.mdc-tab-indicator {
					display : none;
				}

				@include hover {
					border-bottom : 1px solid $vert;
				}
				&.mdc-tab--active {
					border-bottom : 2px solid $vert !important;
				}
			}

			.mat-ink-bar {
				display : none;
			}
		}

		.mat-mdc-tab-body {
			padding : 16px;

			> div {
				padding  : 0;
				overflow : hidden;
			}
		}
	}
}

.ligne_form {
	.mat-tab-translations {
		margin-top : 0 !important;
		border-top : 1px solid rgba($grisfonce, 0.3) !important;
	}
}
