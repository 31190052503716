.calendar-container {
    .day {
        &.available {
            background : $available !important;
        }
        &.closed {
            background : $closed !important;
        }
        &.excluded {
            left       : 3px !important;
            top        : 3px !important;
            right      : 3px !important;
            bottom     : 3px !important;
            background : repeating-linear-gradient(
                    -45deg,
                    rgba($grisfonce, 0.2),
                    rgba($grisfonce, 0.2) 2px,
                    transparent 2px,
                    transparent 6px
            );
        }
    }
    .calendar_legend {
        display    : block;
        width      : 100%;
        text-align : right;
        margin-top : 12px;
        .legend {
            display        : inline-block;
            vertical-align : middle;
            padding-left   : 26px;
            position       : relative;
            &:before {
                content : '';
                @include y_align_absolute;
                left    : 0;
                width   : 18px;
                height  : 14px;
                border  : 1px solid rgba($grisfonce, 0.3);
            }
            span {
                display     : block;
                @include font_lato_regular;
                font-size   : 11px;
                line-height : 14px;
                color       : rgba($grisfonce, 0.9);
            }
            + .legend {
                margin-left : 16px;
            }
            &.available:before {
                background : $available !important;
            }
            &.closed:before {
                background : $closed !important;
            }
            &.excluded:before {
                background : repeating-linear-gradient(
                        -45deg,
                        rgba($grisfonce, 0.2),
                        rgba($grisfonce, 0.2) 2px,
                        transparent 2px,
                        transparent 4px
                );
            }
        }
    }

    .price-type{
        mat-radio-group {
            display        : inline-block;
            vertical-align : middle;
            margin-right   : 16px;

            mat-radio-button + mat-radio-button {
                margin-left : 16px;
            }
			.mdc-radio__background {
				&:before {
					display : none;
				}
				.mdc-radio__outer-circle {
					width        : 18px;
					height       : 18px;
					border-color : rgba($grisfonce, 0.5)
				}
				.mdc-radio__inner-circle {
					width  : 18px;
					height : 18px;
					top    : -1px;
					left   : -1px;
				}
			}

            .mat-ripple {
                display : none;
            }
			mat-radio-button.mat-mdc-radio-checked {
				.mdc-radio__background {
					.mdc-radio__outer-circle {
						border-color : $vert !important;
					}
					.mdc-radio__inner-circle {
						border-color : $vert !important;
					}
				}
			}
        }
    }

    .toggle_month {
        display    : block;
        width      : 100%;
        margin-top : 24px;
        button {
            display        : inline-block;
            vertical-align : middle;
            border         : 1px solid $vertclair;
            border-bottom  : 0;
            padding        : 4px 12px;
            font-size      : 13px;
            line-height    : 16px;
            color          : $grisfonce;
            + button {
                margin-left : 4px;
            }
            &.actif {
                background : $vertclair;
            }
        }
    }

    .calendar {
        display       : block;
        width         : 100%;
        border        : 1px solid $vertclair;
        padding       : 0;
        border-radius : 0;
        .calendar-header {
            display    : block;
            width      : 100%;
            border     : none;
            margin     : 0;
            background : $gris;
            table th {
                font-size : 18px;
                padding   : 8px 12px;
                height    : auto;
                cursor    : default;
                &.prev, &.next {
                    span {
                        display     : block;
                        font-size   : 24px;
                        line-height : 10px;
                    }
                }
                &[class*='year-neighbor'], &.prev, &.next {
                    cursor  : pointer;
                    opacity : 0.4;
                    @include hover {
                        opacity    : 1;
                        background : rgba($txt, 0.2);
                    }
                }
            }
        }
        .months-container {
            @extend .row;
            display : flex !important;
            width   : 100%;
            padding : 5px;
            .month-container {
                padding   : 8px;
                @extend .col_auto;
                width: auto;
                min-width: 48%;
                margin: 5px;
                .month {
                    width : 100%;
                    th {
                        &.month-title {
                            @include font_lato_bold;
                            height : 28px;
                        }
                        &.day-header {
                            height : 24px;
                        }
                    }
                    .day {
                        cursor         : default;
                        height         : 160px;
                        padding        : 10px;
                        line-height    : 16px;
                        background     : transparent;
                        font-size: 12px;
                        font-weight: bold;
                        border: none;
                        &:not(.old):not(.new){
                            cursor: pointer;
                            &:hover{
                                background-color: $vertclair;
                            }
                        }
                        .day-label{
                            width: 30px;
                            background-color: $vert;
                            display: inline-block;
                            border-radius: 0;
                        }
                        .infos{
                            min-height: 100px;
                            margin-top: 10px;
                            font-size: 10px !important;
                            text-align: left !important;
                            line-height: 0 !important;
                        }
                        img{
                            margin-right: 5px !important;
                        }
                        p{
                            margin: 0 0 5px !important;
                        }
                        &:not(.old):not(.new) {
                            border : 1px solid $gris;
                        }
                    }
                }
            }

        }
    }

}
