.mat-mdc-dialog-container {
	.mat-mdc-dialog-surface {
		display       : block;
		padding       : 24px;
		border-radius : 4px;
		box-sizing    : border-box;
		overflow      : auto;
		outline       : 0;
		width         : 100%;
		height        : 100%;
		min-height    : inherit;
		max-height    : inherit;
		.mat-mdc-dialog-title {
			font-size      : 24px;
			font-weight    : 400;
			line-height    : 32px;
			font-family    : Roboto, "Helvetica Neue", sans-serif;
			letter-spacing : normal;
			margin         : 0 0 16px;
			padding        : 0;
			padding-bottom : 16px;
			&:before {
				display : none;
			}
		}
		.mat-mdc-dialog-content {
			padding   : 0;
			font-size : 14px;
			.loader-files .file {
				margin-top  : 8px;
				margin-left : 8px;

				.mat-mdc-progress-bar {
					height   : 100%;
					position : absolute;
					left     : 0;
					top      : 0;
					.mdc-linear-progress__buffer {
						height : 100%;
						.mdc-linear-progress__buffer-bar {
							background : $grisclair;
							height     : 100%;
						}
					}
					.mdc-linear-progress__bar {
						background : $grisclair;
						height     : 100%;
					}
					.mdc-linear-progress__bar-inner {
						height       : 100%;
						border-color : $vert;
						background   : $vert;
					}
				}
			}
		}
		.mat-mdc-dialog-actions {
			padding : 0;
			&.x_center {
				justify-content : center;
			}
		}
	}
}

body:has(app-core-offer-creator-search), &:has(app-core-offer-creator-search) {
	.cdk-overlay-container {
		mat-tooltip-component .mat-mdc-tooltip {
			max-width  : 100%;
			background : $noir;
		}
		.mdc-tooltip__surface {
			background : transparent;
		}
	}
}

subscription-dialog {
	position : relative;
	display  : block;
	width    : 100%;
	.btn_validate_paiement {
		position : absolute;
		bottom   : 8px;
		right    : 110px;
	}
}

introduction-dialog {
	h1 {
		font-weight : bold !important;
		&:after {
			content   : '\00AE';
			font-size : 12px;
			position  : relative;
			top       : -0.5em;
		}
	}
	position : relative;
	display  : block;
	width    : 100%;
	.add_cgv {
		position : relative;
		ul {
			list-style  : none;
			margin-left : 20px;
			li {
				display       : list-item;
				margin-bottom : 20px;
			}
		}
		p {
			span {
				font-weight : bold;
			}

			a {
				color           : $info;
				text-decoration : underline;
				font-weight     : bold;
			}
		}

		&:after {
			content       : '';
			position      : absolute;
			left          : 7%;
			bottom        : 0;
			right         : 7%;
			height        : 1px;
			background    : $vertfonce;
			margin-bottom : 10px;
		}
	}
	.content_cgv {
		div {
			padding     : 10px;
			display     : flex;
			align-items : center;
			button {
				width : 80%;
			}
		}
	}
	.content_list {
		div {
			padding     : 10px;
			display     : flex;
			align-items : center;
			button {
				width            : 80%;
				border           : 1px solid $vert;
				background-color : $blanc;
				color            : $noir;

				&:hover {
					color : $noir !important;
					@include degrade($vertfonce !important, $vert !important);
				}

				&.last {
					color : $blanc;
					@include degrade($vertfonce, $vert);

					&:hover {
						color      : $blanc !important;
						background : $vertfonce !important;
					}
				}
			}
		}
	}
}

.condition_use {
	ul {
		list-style  : disc outside none;
		margin-left : 20px;
		li {
			display : list-item;
		}
	}
}

app-service-periodicity-dialog {

	form {
		margin : 20px 0;
	}

}

app-service-subscription-delete-dialog {
	mat-mdc-dialog-content {
		p {
			font-weight : bold;
		}
	}

}

app-about-service-dialog {

	i {
		font-style : italic;
	}

	.price {
		font-size     : 14px;
		line-height   : 16px;
		@include font_lato_bold;
		color         : $grisfonce;
		margin-bottom : 0;
		span {
			font-weight : normal;
			font-size   : 0.8em;
		}
		+ .price {
			margin-top : 8px;
		}
	}

	.description {
		margin-top : 20px;
	}

	.ck-content {
		margin-bottom : 20px;
	}

	a {
		color : $noir !important;
	}
}

invoice-download-dialog {

	form {
		height : 100px;
	}

}

app-lib-confirm-dialog, app-core-offer-creator-no-reply-reason-dialog, app-core-tender-refuse-offer-dialog, app-core-tender-select-offer-dialog, app-core-offer-access-request-create-dialog, app-core-relationship-create-dialog, app-gift-voucher-renewal-dialog, app-page-society-provider-send-request-dialog, app-core-offer-update-confirmation-dialog, app-page-society-provider-create-dialog {
	.mat-mdc-dialog-title {
		text-align     : center;
		border-bottom  : 1px solid $vert;
		padding        : 0;
		padding-bottom : 16px;
		font-size      : 24px;
		font-weight    : 400;
		line-height    : 32px;
		font-family    : Roboto, "Helvetica Neue", sans-serif;
		letter-spacing : normal;
		margin         : 0 0 16px;
		&:before {
			display : none;
		}
	}
	.mat-mdc-dialog-content {
		padding : 0;
	}
	.mat-mdc-dialog-actions {
		padding       : 0;
		margin-bottom : 0;
		margin-top    : 12px;
	}
}
