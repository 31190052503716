.content_card {
	margin : 12px 0;
}

.card_offer, .card_page {
	display  : block;
	width    : 100%;
	height   : 100%;
	overflow : hidden;
	@include radius(8px);
	@include shadow();

	// Global style for both visual AND map_wrapper, and their children
	.visuel, .map_wrapper {
		display    : block;
		width      : 100%;
		height     : 180px;
		position   : relative;
		background : $vertclair;

		&:after {
			content    : '';
			position   : absolute;
			z-index    : 3;
			left       : 0;
			right      : 0;
			bottom     : 0;
			height     : 16px;
			@include radius(8px 8px 0 0);
			background : $blanc;
		}

		.infos {
			position : absolute;
			z-index  : 2;
			top      : 112px;
			right    : 16px;
			left     : 16px;
			bottom   : 16px;
			.badge {
				position        : absolute;
				top             : -100px;
				left            : 0;
				width           : 42px;
				height          : 42px;
				line-height     : 42px;
				background      : url("../../images/bg_offer_statut.svg") no-repeat center;
				background-size : contain;

				span {
					display        : inline-block;
					vertical-align : middle;
					width          : 100%;
					text-align     : center;
					font-size      : 10px;
					line-height    : 12px;
					color          : $blanc;
					font-weight    : bold;
				}
			}

			.extras {
				position       : absolute;
				top            : -100px;
				right          : 0;
				display        : flex;
				flex-direction : column;

				.map-switch, .status, .hashtags {
					width           : 32px;
					height          : 32px;
					line-height     : 32px;
					font-size       : 18px;
					border-radius   : 6px;
					color           : $noir;
					display         : flex;
					flex-direction  : column;
					justify-content : center;
					align-items     : center;
					margin-bottom   : 6px;
				}

				.map-switch {
					font-size        : 16px;
					background-color : $noir;
					color            : $blanc;
					cursor           : pointer;
				}

                .hashtags {
                    background : rgba($noir, 0.7);
                    img{
                        width: 60%;
                    }
                }

				.disabled {
					opacity : 0.5;
				}

				.status {
					font-weight : 500;
					&.green {
						background-color : $vert;
					}

					&.red {
						background-color : #d22025;
					}

					&.blue {
						background-color : #708fdd;
					}

					&.vigilance {
						background : rgba($noir, 0.7);
						position   : relative;
						&:after {
							content         : '';
							@include center_align_absolute;
							width           : 80%;
							height          : 80%;
							background      : url("../../images/icons/icon_vigilance.svg") no-repeat center;
							background-size : contain;
						}
					}

				}

				.map-close {
					font-size       : 20px;
					padding-bottom  : 7px;
                    @include hover {
                        background-color : $grisfonce;
                    }
				}
			}

			.upper_left {
				position : absolute;
				left     : 0;
				right    : 60px;
				top      : -100px;
				.new, .translation-process {
					display        : inline-block;
					vertical-align : middle;
					margin-right   : 6px;
					margin-bottom  : 6px;
					background     : #d22025;
					height         : 18px;
					line-height    : 18px;
					padding        : 0 8px;
					font-size      : 12px;
					font-weight    : 400;
					color          : $blanc;
					@include radius(6px);
					&:last-child {
						margin-right : 0;
					}
				}
				.has-offer-in-catalog {
					display        : inline-block;
					vertical-align : middle;
					margin-right   : 6px;
					margin-bottom  : 6px;
					background     : $vert;
					height         : 18px;
					line-height    : 18px;
					padding        : 0 8px;
					font-size      : 12px;
					font-weight    : 500;
					color          : $noir;
					@include radius(6px);
					&:last-child {
						margin-right : 0;
					}
				}
			}

			.tags {
				position : absolute;
				bottom   : -4px;
				left     : 0;

				.tag {
					display        : inline-block;
					vertical-align : bottom;
					height         : 30px;
					line-height    : 25px;
					padding        : 0 12px;
					background     : rgba($noir, 0.7);
					@include radius(6px);

					+ .tag {
						margin-left : 8px;
					}

					span {
						display        : inline-block;
						vertical-align : middle;
						font-size      : 12px;
						font-weight    : 400;
						line-height    : 14px;
						color          : $blanc;
						position       : relative;
						padding-left   : 16px;

						&:before {
							content    : '';
							@include y_align_absolute;
							left       : 0;
							width      : 6px;
							height     : 6px;
							@include radius(50%);
							background : $vert;
						}
					}

					> img {
						display        : inline-block;
						vertical-align : middle;
						height         : 20px;
						margin         : auto;
					}

					&.highlighted {
						span {
							padding-left : 0;
							&:before {
								display : none;
							}
						}
					}

					&.referenced {
						span {
							padding-left : 0;
							&:before {
								display : none;
							}
						}
					}

					&.vigilance {
						span {
							&:before {
								background : $danger;
							}
						}
					}

					&.promo {
						span {
							&:before {
								width : 0;
							}

							mat-icon, img {
								@include y_align_absolute;
								left      : -4px;
								width     : 14px;
								height    : 14px;
								font-size : 18px;
								color     : $vert;
							}

							img {
								width  : 16px;
								height : 16px;
							}
						}
					}

					&.online-sale, &.gift-voucher {
						padding : 0 8px;
						//transform : translateY(10px);
						//
						//img {
						//    transform : translateY(-3px);
						//    width     : 25px;
						//}
					}

					&.gift-voucher {
						img {
							width  : 16px;
							height : 16px;
						}
					}
				}
			}
		}
	}

	// Either display image
	.visuel {
		> img {
			display  : block;
			position : relative;
			z-index  : 1;
			width    : 100%;
			height   : 100%;
			@include imageFit;
		}

		.infos {
			left   : 16px;
			bottom : 16px;
		}
	}

	// Or display map
	.map_wrapper {
		> .map {
			position : relative;
			z-index  : 1;
			width    : 100%;
			height   : 100%;
		}

		.infos {
			.badge {
				display : none;
			}

		}
	}

	.infos_offer, .infos_page {
		display          : block;
		width            : 100%;
		height           : 100%;
		background-color : $blanc;
		.interests {
			li {
				display : block;
			}
		}

		.infos {
			display     : block;
			width       : 100%;
			padding     : 16px;
			padding-top : 8px;
			overflow    : hidden;

			.flags {
				display      : block;
				margin-right : 8px;
				margin-top   : -2px;
				.flag {
					display        : inline-block;
					vertical-align : middle;
					width          : 18px;
					height         : 12px;
					+ .flag {
						margin-left : 6px;
					}
				}
				.flag-pending {
					opacity : 0.2;
				}
				.flag-fr {
					background      : url("../../images/icons/icon_flag_fr.svg") no-repeat center;
					background-size : contain;
				}

				.flag-en {
					background      : url("../../images/icons/icon_flag_en.svg") no-repeat center;
					background-size : cover;
				}

				.flag-pt {
					background      : url("../../images/icons/icon_flag_pt.svg") no-repeat center;
					background-size : contain;
				}

				.flag-it {
					background      : url("../../images/icons/icon_flag_it.svg") no-repeat center;
					background-size : contain;
				}

				.flag-es {
					background      : url("../../images/icons/icon_flag_es.svg") no-repeat center;
					background-size : contain;
				}

				.flag-de {
					background      : url("../../images/icons/icon_flag_de.svg") no-repeat center;
					background-size : contain;
				}

				.flag-nl {
					background      : url("../../images/icons/icon_flag_nl.svg") no-repeat center;
					background-size : contain;
				}
			}

			.title {
				margin-bottom : 16px;
				min-height    : 42px;
				p {
					display       : block;
					font-size     : 20px;
					line-height   : 24px;
					color         : $noir;
					margin        : 0;
					font-weight   : 600;
					margin-bottom : 4px;
				}
				.duration {
					font-size   : 15px;
					line-height : 17px;
					margin      : 0;
					font-weight : 400;
				}
			}

			.show_more {
				display    : block;
				width      : 42px;
				height     : 42px;
				@include radius(50%);
				background : rgba($grisfonce, 0.4);
				position   : relative;
				cursor     : pointer;
				@include transition($transition);
				@include transform(rotate(180deg));

				&:before {
					@include center_align_absolute;
					font-size : 16px;
					color     : $blanc;
				}
			}

			.price {
				display : flex;
				//align-items: center;
				margin  : 0;

				span {
					display        : inline-block;
					vertical-align : middle;
					font-size      : 13px;
					line-height    : 18px;
					color          : $txt;
				}

				& > span {
					&:first-child {
						margin-right : 8px;
						@include font_lato_bold;
						font-size    : 24px;
						line-height  : 32px;
						color        : $vert;
					}
				}

				.price_info {
					display        : flex;
					flex-direction : column;
					@include transform(translateY(0.1em));

					span:last-child {
						@include transform(translateY(-0.5em));
					}
				}

				&.old {
					span {
						position : relative;
						color    : rgba($txt, 0.3) !important;
						&:before {
							content    : '';
							position   : absolute;
							top        : 50%;
							left       : 50%;
							width      : 100%;
							height     : 2px;
							@include transform(translateY(-50%) translateX(-50%) rotate(-10deg));
							background : $vert;
						}
					}
				}
			}

			.description {
				display : block;
				width   : 100%;
				margin  : 12px 0;

				p {
					margin      : 0;
					font-size   : 13px;
					line-height : 18px;
					color       : $grisfonce;
				}
			}

			.duration {

				.row > div {

					&:first-child {
						p {
							opacity         : 0.6;
							text-decoration : underline;
						}
					}
				}

				p {
					margin      : 0;
					font-size   : 15px;
					line-height : 20px;
					color       : $grisfonce;
				}

			}

			.infos_sup {
				display  : block;
				width    : 100%;
				@include radius(6px);
				overflow : hidden;

				.row {
					&:nth-of-type(odd) {
						background : $gris;
					}

					> div {
						padding : 8px 12px;

						&:first-child {
							p {
								opacity : 0.6;
							}
						}
					}
				}

				p {
					margin      : 0;
					font-size   : 15px;
					line-height : 20px;
					color       : $grisfonce;
				}

				ul li {
					font-size   : 12px;
					line-height : 15px;
				}
                .offer-creator-link{
                    color: inherit;
                    text-decoration: none;
                    &:hover{
                        color: $vert;
                    }
                }
			}

			&.closed {
				min-height : 100%;
				max-height : 240px;
				position   : relative;

				.show_more {
					@include transform(rotate(0deg));
				}

				&:after {
					content    : '';
					position   : absolute;
					left       : 0;
					bottom     : 0;
					right      : 0;
					height     : 72px;
					background : linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
					z-index    : 2;
				}
			}
		}

		.links_offer, .links_page {
			display   : flex;
			flex-wrap : wrap;

			> div {
				width : 50%;

				a {
					cursor : pointer;
				}

				p {
					margin : 0;
				}

				a, p {
					display     : block;
					width       : 100%;
					height      : 48px;
					line-height : 48px;
					background  : $blanc;
					padding     : 0 16px;
					text-align  : center;
					@include transition($transition);
					span {
						display        : inline-block;
						vertical-align : middle;
						@include font_lato_bold;
						font-size      : 12px;
						line-height    : 16px;
						color          : $noir;
						@include transition($transition);
					}
					&.selectable {
						span {
							position     : relative;
							padding-left : 22px;
							&:before {
								content : '';
								@include y_align_absolute;
								left    : 0;
								width   : 14px;
								height  : 14px;
								border  : 1px solid $blanc;
								@include radius(3px);
							}
							&:after {
								content    : '';
								@include y_align_absolute;
								left       : 3px;
								width      : 8px;
								height     : 8px;
								background : $blanc;
								@include radius(1px);
								opacity    : 0;
							}
						}
						&.selected {
							span {
								&:before {
									border : 1px solid $blanc;
								}
								&:after {
									opacity : 1;
								}
							}
						}
					}
				}
				a {
					@include hover {
						background : $gris;
					}
				}
				&:only-child, &:nth-child(2), &:nth-child(3), &:nth-child(6), &:nth-child(7), &:nth-child(10) {
					a {
						background : $noir;

						span {
							color : $blanc;
						}
						@include hover {
							background : $grisfonce;
						}
					}
				}
				&:last-child:nth-child(odd) { // Last child, if odd (impair) number, make div bigger
					width : 100%;
				}
				&.btn_degrade {
					a {
						@include degrade($vertfonce2, $vert2);

						span {
							color : $blanc;
						}
						@include hover {
							color      : $blanc;
							background : $vertfonce2;
						}
					}
				}
				&.btn_white {
					a {
						background : $blanc;

						span {
							color : $noir;
						}
						@include hover {
							background : $gris;
						}
					}
				}
			}

		}
	}

	&_border {
		border : 2px solid $noir;
	}

	&.offer_highlighted {
		border : 1px solid $vert;
	}

    &.card_offer {
        &.selected {
            border : 1px solid $vert;
        }
    }

	&.card_creator {
		.visuel {
			background    : $blanc;
			border-bottom : 1px solid $vertclair;
			height        : 150px;
			> img {
				@include center_align_relative;
				width      : auto;
				height     : auto;
				max-width  : 80%;
				max-height : 80%;
			}
		}
		.links_offer {
			.creator_selected {
				span {
					position     : relative;
					padding-left : 24px;
					&:before {
						content : '';
						@include y_align_absolute;
						left    : 0;
						width   : 18px;
						height  : 18px;
						border  : 1px solid $noir;
					}
					&:after {
						content    : '';
						@include y_align_absolute;
						left       : 3px;
						width      : 12px;
						height     : 12px;
						background : $vert;
					}
				}
			}
			a.disabled {
				background : $gris !important;
				cursor     : default;
				span {
					opacity : 0.6;
				}
			}
		}
		&.selected {
			border : 1px solid $vert;
		}
        &.highlighted {
            &.green{
                border : 1px solid $vert;
            }
            &.red{
                border : 1px solid $rouge;
            }
        }
	}

}

.card_page {
	.infos_page .infos .title {
		min-height : 0;
	}
}

// SPEC : Card in map
// Main dif if position absolute + scroll inside .card_bottom .infos_wrapper
app-core-offer-map {
	.card_offer_map {
		position : absolute;
		top      : 0;
		right    : 12px; // same as padding of .content_offers, to have box shadow visible
		z-index  : 2;
		height   : 100%;

		.card_offer {
			.card_top {
				height     : 40%;
				max-height : 180px;

				.visuel {
					height : 100%;
				}
			}

			.card_bottom {
				height : 60%;

				.infos_offer {
					.infos_wrapper {
						height     : 100%;
						overflow-y : auto;
					}
				}
			}
		}
	}
}
