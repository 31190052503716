$compCardMap : (
    '.card_offer .infos_offer .infos' : (
        'padding' : (
            12px,
            16px
        ),
        'padding-top' : (
            8px,
            8px
        ),
    ),
    '.card_offer .infos_offer .infos .title' : (
        'font-size' : (
            16px,
            24px
        ),
        'line-height' : (
            20px,
            28px
        ),
        'margin-bottom' : (
            12px,
            16px
        ),
    ),
    '.card_offer .infos_offer .infos .duration' : (
        'font-size' : (
            13px,
            16px
        ),
        'line-height' : (
            16px,
            20px
        ),
        'margin-bottom' : (
            8px,
            12px
        ),
        'margin-top' : (
            -8px,
            -12px
        ),
    ),
    '.card_offer .infos_offer .infos .show_more' : (
        'width' : (
            35px,
            42px
        ),
        'height' : (
            35px,
            42px
        ),
    ),
    '.card_offer .infos_offer .infos .show_more:before' : (
        'font-size' : (
            12px,
            16px
        ),
    ),
    '.card_offer .infos_offer .infos .price span' : (
        'font-size' : (
            11px,
            13px
        ),
        'line-height' : (
            16px,
            18px
        ),
    ),
    '.card_offer .infos_offer .infos .price span:first-child' : (
        'font-size' : (
            18px,
            24px
        ),
        'line-height' : (
            24px,
            32px
        ),
    ),
    '.card_offer .infos_offer .infos .description' : (
        'margin-top' : (
            8px,
            12px
        ),
        'margin-bottom' : (
            8px,
            12px
        ),
    ),
    '.card_offer .infos_offer .infos .description p' : (
        'font-size' : (
            12px,
            13px
        ),
        'line-height' : (
            16px,
            18px
        ),
    ),
    '.card_offer .infos_offer .infos .infos_sup p' : (
        'font-size' : (
            13px,
            15px
        ),
        'line-height' : (
            16px,
            20px
        ),
    ),
    '.card_offer .infos_offer .infos .infos_sup li' : (
        'font-size' : (
            10px,
            12px
        ),
        'line-height' : (
            13px,
            15px
        ),
    ),
    '.card_offer .infos_offer .infos .infos_sup .provider img' : (
        'width' : (
            45px,
            50px
        ),
    ),
    '.card_offer .infos_offer .providers .provider' : (
        'margin-top' : (
            8px,
            8px
        ),
        'margin-bottom' : (
            8px,
            8px
        ),
    ),
    '.card_offer .infos_offer .providers .provider img' : (
        'width' : (
            70px,
            80px
        ),
    ),
);

@include fluid($compCardMap);

.content_card {
    margin: auto;
    margin-top: 20px;
    max-width: 400px;
    @include media_x_max('md') {
        margin-bottom : 16px;
    }
}

.card_offer {
    display  : block;
    width    : 100%;
    height   : 100%;
    overflow : hidden;
    @include radius(8px);
    @include shadow();

    .visuel {
        display    : block;
        width      : 100%;
        height     : 180px;
        position   : relative;
        background : $gris;

        &:after {
            content    : '';
            position   : absolute;
            z-index    : 3;
            left       : 0;
            right      : 0;
            bottom     : 0;
            height     : 16px;
            @include radius(8px 8px 0 0);
            background : $blanc;
        }

        > img {
            display  : block;
            position : relative;
            z-index  : 1;
            width    : 100%;
            height   : 100%;
            @include imageFit;
        }

        .infos {
            position : absolute;
            z-index  : 3;
            top      : 16px;
            right    : 16px;
            left     : 16px;
            bottom   : 16px;

            .badge {
                position        : absolute;
                top             : 0;
                left            : 0;
                width           : 42px;
                height          : 42px;
                line-height     : 42px;
                background      : url("~assets/images/bg_offer_statut.svg") no-repeat center;
                background-size : contain;

                span {
                    display        : inline-block;
                    vertical-align : middle;
                    width          : 100%;
                    text-align     : center;
                    font-size      : 10px;
                    line-height    : 12px;
                    color          : $blanc;
                }
            }

            .extras {
                position       : absolute;
                top            : -100px;
                right          : 0;
                display        : flex;
                flex-direction : column;

                .map-switch, .status {
                    width           : 32px;
                    height          : 32px;
                    line-height     : 32px;
                    font-size       : 18px;
                    border-radius   : 6px;
                    color           : $noir;
                    display         : flex;
                    flex-direction  : column;
                    justify-content : center;
                    align-items     : center;
                    margin-bottom   : 8px;
                }

                .map-switch {
                    font-size        : 16px;
                    background-color : $noir;
                    color            : $blanc;
                    cursor           : pointer;
                }

                .disabled {
                    opacity : 0.5;
                }

                .status {
                    font-weight : 500;
                    &.green {
                        background-color : $vert;
                    }

                    &.red {
                        background-color : #d22025;
                    }

                    &.blue {
                        background-color : #708fdd;
                    }

                    &.vigilance {
                        background : rgba($noir, 0.7);
                        position   : relative;
                        &:after {
                            content         : '';
                            @include center_align_absolute;
                            width           : 80%;
                            height          : 80%;
                            background      : url("../../images/icons/icon_vigilance.svg") no-repeat center;
                            background-size : contain;
                        }
                    }

                }

                .map-close {
                    display         : flex !important;
                    font-size       : 20px;
                    cursor          : pointer !important;
                    flex-direction  : column;
                    justify-content : center;
                    align-items     : center;
                    padding-bottom  : 7px;
                    background      : rgba(52, 224, 161, .7);
                    width           : 24px;
                    height          : 24px;
                    border-radius   : 6px;
                    color           : black;
                    @include hover {
                        background-color : $gris;
                    }
                }
            }

            .new {
                position    : absolute;
                left        : 0;
                top         : -100px;
                background  : #d22025;
                height      : 18px;
                line-height : 18px;
                padding     : 0 8px;
                font-size   : 12px;
                font-weight : 400;
                color       : $blanc;
                @include radius(6px);
            }

            .tags {
                position : absolute;
                bottom   : -4px;
                left     : 0;
                right    : 0;

                .tag {
                    display        : inline-block;
                    vertical-align : bottom;
                    height         : 30px;
                    line-height    : 25px;
                    padding        : 0 12px;
                    background     : rgba($noir, 0.7);
                    @include radius(6px);

                    + .tag {
                        margin-left : 8px;
                    }

                    span {
                        display        : inline-block;
                        vertical-align : middle;
                        font-size      : 12px;
                        font-weight    : 400;
                        line-height    : 14px;
                        color          : $blanc;
                        position       : relative;
                        padding-left   : 16px;

                        &:before {
                            content    : '';
                            @include y_align_absolute;
                            left       : 0;
                            width      : 6px;
                            height     : 6px;
                            @include radius(50%);
                            background : $vert;
                        }
                    }

                    > img {
                        display        : inline-block;
                        vertical-align : middle;
                        height         : 20px;
                        margin         : auto;
                    }

                    &.highlighted {
                        span {
                            padding-left : 0;
                            &:before {
                                display : none;
                            }
                        }
                    }

                    &.vigilance {
                        span {
                            &:before {
                                background : $danger;
                            }
                        }
                    }

                    &.promo {
                        span {
                            &:before {
                                width : 0;
                            }

                            mat-icon {
                                @include y_align_absolute;
                                left      : -4px;
                                width     : 18px;
                                height    : 18px;
                                font-size : 18px;
                                color     : $vert;
                            }
                        }
                    }

                    &.online-sale, &.gift-voucher {
                        padding : 0 8px;
                        //transform : translateY(10px);
                        //
                        //img {
                        //    transform : translateY(-3px);
                        //    width     : 25px;
                        //}
                    }

                    //&.gift-voucher {
                    //    padding   : 5px 7px;
                    //    transform : translateY(10px);
                    //
                    //    img {
                    //        transform : translateY(-3px);
                    //        width     : 25px;
                    //    }
                    //}
                }
            }
        }
        .map {
            position   : absolute;
            z-index    : 0;
            left       : 0;
            top        : 0;
            width      : 100%;
            height     : 100%;
            display    : block;
            background : $gris;
            .map-container {
                width  : 100%;
                height : 100%;
            }
        }
    }

    .map_wrapper {
        .map {
            z-index : 2;
        }

        .infos {

            left   : inherit;
            bottom : inherit;

            .badge {
                display : none;
            }

            .extras {
                .status {
                    display : none;
                }
            }

            .tags {
                display : none;
            }
        }
    }

    .infos_offer {
        display          : block;
        width            : 100%;
        height           : 100%;
        background-color : $blanc;

        .infos {
            display  : block;
            width    : 100%;
            overflow : hidden;

            .title {
                display : block;
            }

            .duration {
                display : block;
                color   : $grisfonce;
            }

            .show_more {
                display    : block;
                @include radius(50%);
                background : rgba($grisfonce, 0.15);
                position   : relative;
                cursor     : pointer;
                @include transition($transition);
                @include transform(rotate(180deg));

                &:before {
                    @include center_align_absolute;
                    color : $blanc;
                }
            }

            .price {
                display : flex;
                margin  : 0;

                &_amount {
                    margin-right : 8px;
                    @include font_lato_bold;
                    font-size    : 24px;
                    line-height  : 32px;
                }

                .price_info {
                    display        : flex;
                    flex-direction : column;

                    span {
                        display        : inline-block;
                        vertical-align : middle;
                        font-size      : 13px;
                        line-height    : 18px;
                        color          : $txt;
                    }

                    @include transform(translateY(0.1em));

                    span:last-child {
                        @include transform(translateY(-0.5em));
                    }
                }

                &.old {
                    span {
                        position : relative;
                        color    : rgba($txt, 0.3) !important;
                        &:before {
                            content    : '';
                            position   : absolute;
                            top        : 50%;
                            left       : 50%;
                            width      : 100%;
                            height     : 2px;
                            @include transform(translateY(-50%) translateX(-50%) rotate(-10deg));
                            background : $vert;
                        }
                    }
                }
            }

            .description {
                display : block;
                width   : 100%;
                p {
                    margin : 0;
                    color  : $grisfonce;
                }
            }

            .infos_sup {
                display  : block;
                width    : 100%;
                @include radius(6px);
                overflow : hidden;

                > .row {
                    &:nth-of-type(odd) {
                        background : $gris;
                    }

                    > div {
                        padding : 6px 12px;

                        &.col_4 {
                            p {
                                opacity : 0.6;
                            }
                        }
                    }
                }

                p {
                    margin      : 0;
                    font-size   : 12px;
                    line-height : 14px;
                    color       : $grisfonce;
                }

                .provider {
                    //img {
                    //	margin-right : 12px;
                    //}
                    p {
                        font-size   : 12px;
                        line-height : 16px;
                        white-space : normal;
                        span {
                            font-weight : bold;
                        }
                    }
                }
            }

            &.closed {
                max-height : 250px;
                position   : relative;

                .show_more {
                    @include transform(rotate(0deg));
                }

                &:after {
                    content    : '';
                    position   : absolute;
                    left       : 0;
                    bottom     : 0;
                    right      : 0;
                    height     : 72px;
                    background : linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                    z-index    : 2;
                }
            }
        }

        .links_offer {
            display : block;
            .btn_cta {
                width : 100%;
                @include radius(0 0 8px 8px);
            }
        }
    }

    &_border {
        border : 2px solid $noir;
    }

}

.offer-map {
    .card_offer_map {
        position   : absolute;
        top        : 16px;
        right      : 16px;
        z-index    : 2;
        max-height : calc(100% - 32px);
        width      : calc(100% - 32px);
        max-width  : 350px;
    }
}
