$cmsMap : (
	'.ck-content .titre_h1, .ck-content h1:not([class*="titre_"])' : (
		'font-size' : (
			36px,
			60px
		),
		'line-height' : (
			42px,
			65px
		),
		'margin-bottom' : (
			12px,
			16px
		),
	),
	'.ck-content .titre_h2, .ck-content h2:not([class*="titre_"])' : (
		'font-size' : (
			26px,
			40px
		),
		'line-height' : (
			30px,
			45px
		),
		'margin-bottom' : (
			16px,
			24px
		),
	),
	'.ck-content .titre_h3, .ck-content h3:not([class*="titre_"])' : (
		'font-size' : (
			20px,
			24px
		),
		'line-height' : (
			24px,
			30px
		),
		'margin-bottom' : (
			12px,
			16px
		),
	),
	'.ck-content .titre_h4, .ck-content h4:not([class*="titre_"])' : (
		'font-size' : (
			15px,
			18px
		),
		'line-height' : (
			18px,
			24px
		),
		'margin-bottom' : (
			12px,
			16px
		),
	),
	'.ck-content, .ck-content p, .ck-content ul li' : (
		'font-size' : (
			14px,
			15px
		),
		'line-height' : (
			20px,
			26px
		),
	),
	'.ck-content p, .ck-content ul, .ck-content .sep, .ck-content .bg_green' : (
		'margin-bottom' : (
			12px,
			16px
		),
	),
);

@include fluid($cmsMap);

.ck-content, .ck.ck-content, .ck.ck-list__item {
	text-align : left;

	%titre_cms {
		display : block;
		@include font_lato_bold;
		color   : $noir;
	}

	> *:last-child {
		margin-bottom : 0;
	}

	.titre_h1, .titre_h2, .titre_h3, .titre_h4 {
		@extend %titre_cms;
		&.highlight {
			position : relative;
			padding-bottom: 8px;
			&:after {
				content    : '';
				position   : absolute;
				bottom     : 0;
				left       : 0;
				height     : 2px;
				background : $vert;
			}
		}
	}
	.titre_h1, .titre_h2 {
		&.highlight:after {
			width : 100px;
		}
	}
	.titre_h3 {
		&.highlight:after {
			width : 50px;
		}
	}
	.titre_h4 {
		&.highlight:after {
			width : 30px;
		}
	}
	h1, h2, h3, h4 {
		&:not([class*="titre_"]) {
			@extend %titre_cms;
		}
	}

	.titre_h1, h1:not([class*="titre_"]) {
		span {
			display : block;
			@include font_lato_bold;
		}
	}

	p, & {
		display : block;
		color   : $grisfonce;
	}

	ul {
		display : block;
		color   : $noir;
		li {
			display      : block;
			position     : relative;
			padding-left : 12px;
			&:before {
				content  : '-';
				position : absolute;
				left     : 0;
				top      : -1px;
			}
		}
	}

	ol li {
		list-style : decimal;
	}

	strong, b {
		font-weight : 500;
		//color       : $noir;
	}

	i, em {
		font-style : italic;
	}

	.no_margin {
		margin-bottom : 0;
	}

	.sep {
		display    : block;
		width      : 100%;
		height     : 1px;
		background : $gris;
		&.semi {
			max-width : 50%;
		}
	}

	.underline {
		text-decoration : underline;
	}

	a:not(.btn_cta) {
		color : $rouge;
		@include font_lato_bold;
		@include hover {
			text-decoration : underline;
		}
	}

	.copy-content {
		padding       : 12px;
		padding-right : 32px;
		background    : $gris;
		margin-bottom : 16px;
		cursor        : pointer;
		position      : relative;
		@include transition($transition);
		&:after {
			content     : 'content_copy';
			font-family : "Material Icons";
			@include y_align_absolute;
			right       : 8px;
			font-size   : 18px;
			opacity     : 0;
			@include transition($transition);
		}
		pre {
			white-space : normal;
			color       : $grisfonce;
			font-weight : 500;
		}
		@include hover {
			background : rgba($vert, 0.2);
			&:after {
				opacity : 1;
			}
		}
	}

	.modify, .no_modify {
		font-weight : 600;
	}

	.modify {
		color : $rouge;
	}
	.no_modify {
		opacity : 0.7;
	}

	.form-group {
		margin-bottom : 16px;
	}

	.bg_green {
		display    : block;
		width      : 100%;
		padding    : 16px;
		background : rgba($vert, 5%);
	}

	.titre_ht {
		display   : inline-block;
		font-size : 0.7em;
		color     : $noir;
		.old_price {
			text-decoration : line-through;
			font-size       : 0.8em;
			margin-right    : 8px;
		}
	}

	.error {
		color : $danger;
	}

	.old_price {
		text-decoration : line-through;
		font-size       : 0.8em;
		margin-right    : 8px;
	}
}

.txt_blanc .ck-content, .ck-content.txt_blanc {
	&, .titre_h1, .titre_h2, .titre_h3, p, ul li, strong, b, a:not(.btn_cta) {
		color : $blanc;
	}
}


