/* ---------------------------------------------------------------------------------------------------------------------
---- Stgs Projet
--------------------------------------------------------------------------------------------------------------------- */

/* - Couleurs
..................................................................................................................... */

$vert         : #34e0a1;
$vert2 		  : #03B875;
$vertfonce    : #208d65;
$vertfonce2   : #015234;
$vertclair    : #d9e2df;
$blanc        : #ffffff;
$noir         : #000000;
$grisfonce    : #1d2028;
$grisclair    : #fafafa;
$gris         : #eeeeee;
$gris2        : #c3c0c0;
$gris3        : #7d7777;
$txt          : #969696;
$bleublanc    : #ebf6fe;
$rouge        : #ac5242;

$available    : #97c655;
$closed       : rgba($grisfonce, 0.15);

// alert colors

$success      : #00b900;
$info         : #000fc3;
$warning      : #dc9d00;
$danger       : #f80000;

/* - Variables
..................................................................................................................... */

$transition   : all 0.25s ease-in-out;
