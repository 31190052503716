.society-search-list {
    display : block;
    width   : 100%;
    .society-search-item {
        border-bottom : 1px solid $gris;
        padding       : 10px 0;
        a, span, img {
            display        : inline-block;
            vertical-align : middle;
            font-size      : 14px;
            line-height    : 18px;
            color          : $noir;
        }
        a {
            margin-right : 16px;
            @include hover {
                text-decoration : underline;
            }
        }
        img {
            width       : 16px;
            margin-left : 8px;
            margin-top  : -1px;
        }
        .offer-reference {
            opacity : 0.5;
        }
    }
}

.bloc_societies{
    box-shadow: none;
    .bloc_society_filters{

        > div {
            border-bottom: none;
        }
    }
}
