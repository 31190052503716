app-core-header {
    position       : fixed;
    top            : 0;
    left           : 0;
    right          : 0;
    z-index        : 3;
    height         : 80px;
    background     : $blanc;
    @include shadow();
    padding        : 0 32px;
    padding-bottom : 2px;
    &:after {
        content  : '';
        position : absolute;
        left     : 0;
        bottom   : 0;
        right    : 0;
        height   : 2px;
        @include degrade($vert, $vertfonce);
    }
    .logo, .link-logo {
        display: block;
        height: 40px;
    }
    .ty-user {
        display        : inline-block;
        vertical-align : middle;
        font-size      : 16px;
        line-height    : 19px;
        margin-right   : 48px;
        color          : $noir;
        &:before {
            content         : '';
            display         : inline-block;
            vertical-align  : middle;
            width           : 28px;
            height          : 28px;
            margin-right    : 8px;
            background      : url("../../images/icons/utilisateur.png") no-repeat center;
            background-size : contain;
        }

		&.ty-30 {
			&:before {
				transform: translateY(30%);
			}
		}

		&-info {
			display: inline-flex;
			flex-direction: column;

			.italic {
				font-style: italic;
			}
		}
    }
    .ty-logout {
        display        : inline-block;
        vertical-align : middle;
        width          : 40px;
        height         : 40px;
        line-height    : 38px;
        text-align     : center;
        cursor         : pointer;
        @include radius(50%);
        background     : $grisfonce;
        color          : $blanc;
        &:before {
            display        : inline-block;
            vertical-align : middle;
            font-size      : 14px;
            line-height    : 14px;
        }
    }
}
