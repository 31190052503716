ngx-material-timepicker-container {
    ngx-material-timepicker-content {
        .timepicker__header {
            background : $vert !important;
            .timepicker-dial__container {
                justify-content : center !important;
                .timepicker-dial__period--hidden {
                    display : none !important;
                }
            }
        }
        .clock-face__number > span.active {
            background : $vert !important;
        }
        .clock-face__clock-hand {
            background : $vert !important;
        }
        .clock-face__clock-hand_minute:before {
            border-color : $vert !important;
        }
        .timepicker-button {
            color : $vert !important;
        }
    }
}
