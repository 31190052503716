.page_card {

    .warning {
        p {
            margin: 0;
            padding: 0;
            color: $rouge;
        }
    }

    .recap {
        display : block;
        padding : 16px 0;

        .ck-content {
            display    : block;
            padding    : 8px 16px;
            border     : 1px solid $gris;
            background : $grisclair;
            @include radius(4px);
        }
    }

    .recap {
        p {
            display : block;
            padding: 10px;
        }
    }
}

.payment_form {
    .payment_methods {
        margin-bottom : 32px;
        .method_item {
            display : flex;
            width   : 56px;
            height  : 56px;
            &.cb {
                background : url("../../images/payment_cb.png") no-repeat center / contain;
            }
            &.visa {
                background : url("../../images/payment_visa.png") no-repeat center / contain;
            }
            &.mastercard {
                background : url("../../images/payment_mastercard.png") no-repeat center / contain;
            }
            &.maestro {
                background : url("../../images/payment_maestro.png") no-repeat center / contain;
            }
        }
    }
}

.create_card_information {
    padding       : 16px;
    border        : 1px solid $vert;
    background    : rgba($vert, 0.2);
    margin-bottom : 10px;
    margin-top    : 5px;
    &.failed{
        border        : 1px solid $warning;
        background    : rgba($warning, 0.2);
    }
    @include radius(4px);
    a {
        font-weight : bold;
        color       : $noir;
    }
    p {
        margin-bottom : 0;
    }
}
