.bloc_recap {
	display     : block;
	width       : 100%;
	@include radius(10px);
	@include shadow(1px 1px 6px 0 rgba(0, 0, 0, 0.15));
	border-left : 4px solid $vert;
	padding     : 16px;
	+ .bloc_recap {
		margin-top : 24px;
	}
	//&:only-child {
	//	height : 100%;
	//}
	&.grey {
		background : $grisclair;
		border     : none;
	}
	&.lightgreen {
		background : rgba(#008151, 0.05);
		border     : none;
	}
}
.framed_green {
	padding       : 16px;
	border        : 1px solid $vert;
	background    : rgba($vert, 0.2);
	margin-bottom : 10px;
	margin-top    : 5px;
	@include radius(4px);
}