
.page_service {
	.mdc-tab__content {
		width           : 100%;
		justify-content : flex-start;
		img {
			width     : 45px;
			position  : absolute;
			right     : 0;
			transform : translateY(-10%);
		}
	}

	.mt_24 {
		margin-top : 24px;
	}

	&_subscription {
		padding-bottom : 24px;

		.subscription_quantity, .subscription_frequency_tiles_container {
			max-width : 900px;
		}

		.subscription_quantity {
			margin-top : 24px;
		}

		.subscription_frequency_tile_wrapper {
			margin   : 16px 0;
			position : relative;
			.radio {
				display : none;
			}
			input[type="radio"] {
				position : absolute;
				width    : 0;
				height   : 0;
				left     : 0;
				top      : 0;
			}
			.subscription_frequency_tile {
				display       : block;
				width         : 100%;
				height        : 100%;
				border        : 1px solid $gris;
				@include transition($transition);
				@include shadow(0 0 11px 0 rgba($gris, 0.5));
				cursor        : pointer;
				border-radius : 30px;

				.tile_top {
					.freq_name {
						@include font_lato_black();
						margin-bottom : 16px;
					}
					& > p {
						color  : $txt;
						margin : 0;
					}
				}

				.tile_top, .tile_bottom {
					display        : flex;
					flex-direction : column;
					padding        : 25px;
				}
				.tile_top {
					position : relative;
					.ty-check {
						position         : absolute;
						display          : flex;
						justify-content  : center;
						align-items      : center;
						background-color : $gris;
						border-radius    : 25px;
						height           : 22px;
						width            : 22px;
						right            : 25px;
						@include transition($transition);
						&:before {
							color     : $gris;
							font-size : 14px;
							@include transition($transition);
						}
					}
				}
				.tile_bottom {
					border-top : 1px solid $gris;
					@include transition($transition);

					.prices {
						.price_item {
							margin-bottom : 8px;
							flex-wrap     : nowrap;
							.label {
								display     : flex;
								align-items : center;
							}
							.price {
								text-align : right;
								@include font_lato_black();
								.price_ht {
									@include font_lato_regular;
									font-size : 0.8em;
								}
							}
						}
						&:last-child {
							.price_item {
								&:last-child {
									margin-bottom : 0;
								}
							}
						}
					}
					> span {
						@include font_lato_bold();
						margin-bottom : 12px;
						margin-top    : 8px;
						font-size     : 16px;
					}
				}

				&.tile_checked {
					border-color : $vert;
					.tile_top {
						.ty-check {
							background-color : $vert;
							&:before {
								color : $blanc;
							}
						}
					}
					.tile_bottom {
						border-color : $vert;
					}
				}

			}
		}

		// temp
		p.title {
			display       : block;
			line-height   : 16px;
			color         : #000000;
			font-family   : "Lato", sans-serif;
			font-weight   : 700;
			margin-bottom : 12px;
		}

		.card_warning {
			p {
				margin-bottom : 0;
				padding       : 0;
				color         : $noir;
			}
		}

		.serviceContent {
			display    : block;
			padding    : 16px 24px;
			margin     : 16px 0;
			background : $grisclair;
			@include radius(4px);
			&:empty {
				display : none;
			}
		}

		.recap {
			display   : block;
			margin    : 16px 0 16px 0;
			max-width : 850px;

			&_service {
				max-width : 850px;

				& > div:first-child {
					margin-bottom : 10px;
				}

				.title {
					@include font_lato_bold;
					font-size   : 17px;
					line-height : 20px;
				}

				.periodicity {
					@include font_lato_bold;
					font-size   : 15px;
					line-height : 18px;
					margin      : 0;
				}

				.prorata {
					margin        : 0;
					padding       : 6px;
					border        : 1px solid $gris;
					border-radius : 5px;
				}
			}

			.ck-content {
				display    : block;
				&:has(*) {
					padding : 24px;
				}
				background : rgba($vert, 0.1);
				@include radius(4px);
			}

			p {
				font-size   : 14px;
				line-height : 18px;
			}

			.price {
				//font-size     : 14px;
				//line-height   : 18px;
				margin-bottom : 12px;
				@include font_lato_bold;

				&.total {
					font-size   : 20px;
					line-height : 24px;
					margin-top  : 24px;
				}
				&:last-child {
					margin-bottom : 0;
				}
			}
		}

		.recap, .recap_service {

			p {
				display : block;
			}
		}

		.back_to_services {
			margin-bottom : 16px;
			span {
				font-size       : 12px;
				line-height     : 16px;
				color           : $grisfonce;
				text-transform  : initial;
				text-decoration : none;
				cursor          : pointer;
				@include transition($transition);
				&:before {
					display        : inline-block;
					vertical-align : middle;
					@include transform(rotate(90deg));
					margin-right   : 12px;
					color          : inherit;
					font-size      : 0.8em;
					margin-top     : -1px;
				}
				@include hover {
					color : $vert;
				}
			}
		}

		.payment_form {
			max-width : 550px;
			margin    : 20px 0;
		}

		.card_infos {
			font-style      : italic;
			text-decoration : underline;
		}

		.promotion {
			color      : $vert;
			font-style : italic;
		}

		.management_disabled {
			padding       : 16px;
			border        : 1px solid $vert;
			background    : rgba($vert, 0.2);
			margin-bottom : 10px;
			margin-top    : 10px;
			text-align    : center;
			@include radius(4px);
			a {
				font-weight : bold;
				color       : $blanc;
			}
			p {
				margin-bottom : 0;
			}
		}
	}

}
