/* ---------------------------------------------------------------------------------------------------------------------
---- Settings
--------------------------------------------------------------------------------------------------------------------- */
/* - Projet
..................................................................................................................... */
@import 'settings/stgs_projet';


/* - Input tel international
..................................................................................................................... */
@import './../node_modules/intl-tel-input/build/css/intlTelInput.css';

/* - Arabica
..................................................................................................................... */
@import 'settings/stgs_arabica';
/* - Mixins
..................................................................................................................... */
@import 'settings/stgs_mixins';

/* ---------------------------------------------------------------------------------------------------------------------
---- Molecules
--------------------------------------------------------------------------------------------------------------------- */
/* - Fonts
..................................................................................................................... */
@import 'molecules/mol_fonts';
/* - Buttons
..................................................................................................................... */
@import 'molecules/mol_buttons';

/* ---------------------------------------------------------------------------------------------------------------------
---- Components
--------------------------------------------------------------------------------------------------------------------- */

.pim-app{

    /* - Loader
    ..................................................................................................................... */
    @import 'components/comp_loader';
    /* - Form
    ..................................................................................................................... */
    @import 'components/comp_form';
    /* - Table
    ..................................................................................................................... */
    @import 'components/comp_table';
    /* - Tab
    ..................................................................................................................... */
    @import 'components/comp_tab';
    /* - Accordion
    ..................................................................................................................... */
    @import 'components/comp_accordion';
    /* - CGV
    ..................................................................................................................... */
    @import 'components/comp_cgv';
    /* - Collection
    ..................................................................................................................... */
    @import 'components/comp_collection';
    /* - Calendar
    ..................................................................................................................... */
    @import 'components/comp_calendar';
    /* - Period
    ..................................................................................................................... */
    @import 'components/comp_period';
    /* - Card
    ..................................................................................................................... */
    @import 'components/comp_card';
    /* - Service
    ..................................................................................................................... */
    @import 'components/comp_card_service';
    /* - Slider
    ..................................................................................................................... */
    @import 'components/comp_slider';
    /* - Offer calendar
    ..................................................................................................................... */
    @import 'components/comp_offer_calendar';
    /* - timepicker
    ..................................................................................................................... */
    @import 'components/comp_timepicker';
    /* - subscription tile
    ..................................................................................................................... */
    @import 'components/comp_subscription_tile';
    /* - paginator
    ..................................................................................................................... */
    @import 'components/comp_paginator';

    /* - Cms
    ..................................................................................................................... */
    @import 'components/comp_cms';

    /* ---------------------------------------------------------------------------------------------------------------------
    ---- Blocs
    --------------------------------------------------------------------------------------------------------------------- */
    /* - Body
    ..................................................................................................................... */
    @import 'blocs/bloc_body';
    /* - Login
    ..................................................................................................................... */
    @import 'blocs/bloc_login';
    /* - Header
    ..................................................................................................................... */
    @import 'blocs/bloc_header';
    /* - Nav
    ..................................................................................................................... */
    @import 'blocs/bloc_nav';
    /* - Modal
    ..................................................................................................................... */
    @import 'blocs/bloc_modal';
    /* - Account
    ..................................................................................................................... */
    @import 'blocs/bloc_account';
    /* - Offers
    ..................................................................................................................... */
    @import 'blocs/bloc_offers';
    /* - Offers filters banner
    ..................................................................................................................... */
    @import 'blocs/bloc_offers_filters_banner';

    /* - Promotion subscription
    ..................................................................................................................... */
    @import 'blocs/bloc_promotion_subscription';

    /* - Promotion service
    ..................................................................................................................... */
    @import 'blocs/bloc_promotion_service';

    /* - Bon cadeaux
    ..................................................................................................................... */
    @import 'blocs/bloc_gift_voucher';

    /* - Payment
    ..................................................................................................................... */
    @import 'blocs/bloc_payment';

    /* - Availability
    ..................................................................................................................... */
    @import 'blocs/bloc_availability';

    /* - Recap
    ..................................................................................................................... */
    @import 'blocs/bloc_recap';

    /* - Cms
    ..................................................................................................................... */
    @import 'blocs/bloc_cms';

    /* - Society
    ..................................................................................................................... */
    @import 'blocs/bloc_society';

    /* ---------------------------------------------------------------------------------------------------------------------
    ---- Page
    --------------------------------------------------------------------------------------------------------------------- */
    /* - Offer
    ..................................................................................................................... */
    @import 'pages/page_offer';

    /* - Offer Creator
    ..................................................................................................................... */
    @import 'pages/page_offer_creator';

    /* - Service
    ..................................................................................................................... */
    @import 'pages/page_service';

    /* - Booking
    ..................................................................................................................... */
    @import 'pages/page_booking';

    /* - Card
    ..................................................................................................................... */
    @import 'pages/page_card';

    /* - Channel Preview
    ..................................................................................................................... */
    .channel_pim_preview{

        @import "molecules/mol_buttons_channel_preview";

        @import 'components/comp_accordion_channel_preview';

        @import "components/comp_card_channel_preview";

        @import "pages/page_offer_channel_preview";

        position  : relative;
        z-index   : 2;

        &.font-lato * {
            @include font_lato_regular;
        }

        &.font-roboto * {
            @include font_roboto;
        }

        &.font-source * {
            @include font_source;
        }

        &.font-nunito * {
            @include font_nunito;
        }

        &.font-ubuntu * {
            @include font_ubuntu;
        }

        &.font-raleway * {
            @include font_raleway;
        }

        &.font-caveat * {
            @include font_caveat;
        }

        &.font-amatic * {
            @include font_amatic;
        }

        &.font-poiretone * {
            @include font_poiretone;
        }

        &.font-opensans * {
            @include font_opensans;
        }

        &.font-montserrat * {
            @include font_montserrat;
        }

        &.font-oswald * {
            @include font_oswald;
        }

        &.font-slabo * {
            @include font_slabo;
        }

        &.font-ptsans * {
            @include font_ptsans;
        }

        &.font-merriweather * {
            @include font_merriweather;
        }

        &.font-notosans * {
            @include font_notosans;
        }

        &.font-concertone * {
            @include font_concertone;
        }

        &.font-prompt * {
            @include font_prompt;
        }

        &.font-worksans * {
            @include font_worksans;
        }

        &.font-ooohbaby * {
            @include font_ooohbaby;
        }

        &.font-badscript * {
            @include font_badscript;
        }

        &.font-dancingscript * {
            @include font_dancingscript;
        }
    }

    .ck-powered-by-balloon{
        display: none !important;
    }

    .material-tooltip-html-container {
        display: block;
        background: $grisfonce;
        @include radius(4px);
        padding: 6px;
        color: $blanc;
        font-size: 11px;
        line-height: 16px;
        position: relative;
        opacity: 0.7;
    }
}
