.bloc_payment {
    display   : block;
    width     : 100%;
    max-width : 550px;
    .payment_form {
        margin-top: 20px;
    }
}
.form_paymment{
  .warning{
    padding: 10px;
    background: #f7d9d98a;
    border: 1px solid #e19292;
    margin-bottom: 20px;
  }
}
.form_paymment_reponse {
    p {
        font-size   : 15px;
        line-height : 22px;
        margin      : 0;
        &.title {
            @include font_lato_bold;
            font-size   : 20px;
            line-height : 24px;
            &.success {
                color : $success;
            }
            &.error {
                color : $danger;
            }
        }
        strong {
            @include font_lato_bold;
        }
        &:not(:last-child) {
            margin-bottom : 16px;
        }
    }
    .btn_cta {
        margin-top : 40px;
    }
}

.recap_sub{
    padding: 16px 24px;
    background: $grisclair;
}

.recap_payment{
  table {
    width:100%;

    td {
      padding: 10px;
      border: 1px solid #eaeaea;
      &.border-none{
        border:none;
      }
      &.bold{
        font-weight:bold;
      }
    }
    margin-bottom:20px;
  }
}

.content_cards{
  .card{
    border:1px solid #eaeaea;
    .number_card{
      line-height:30px;
    }
    &.card_invalid{
      border-color:red;
    }
    .cardStatut{
      position: absolute;
      right: 10px;
      font-weight: bold;
      color: red;
      line-height: 30px;
    }
  }
  span.icon_cb{
    float: left;
    width: 40px;
    height: 30px;
    margin-right:5px;
    background : url("../../images/icons/icon_paiement_cb.png") no-repeat center;
  }
  span.icon_visa{
    float: left;
    width: 40px;
    height: 30px;
    margin-right:5px;
    background : url("../../images/icons/icon_paiement_visa.png") no-repeat center;
  }
  span.icon_mastercard{
    float: left;
    width: 40px;
    height: 30px;
    margin-right:5px;
    background : url("../../images/icons/icon_paiement_mastercard.png") no-repeat center;
  }
  span.icon_maestro{
    float: left;
    width: 40px;
    height: 30px;
    margin-right:5px;
    background : url("../../images/icons/icon_paiement_maestro.png") no-repeat center;
  }
}


app-page-subscription-payment {
  position : relative;
  z-index  : 3;
  .overlay {
    position   : fixed;
    top        : 80px;
    left       : 275px;
    bottom     : 0;
    right      : 0;
    background : rgba($blanc, 0.5);
    > div{
      @include center_align_absolute;
    }
  }
  mat-progress-spinner {
    width  : 80px !important;
    height : 80px !important;
    svg {
      width  : 100% !important;
      height : 100% !important;
      circle {
        stroke : $vert !important;
      }
    }
  }
}

.layout + app-page-subscription-payment{
  z-index : 5;
  .overlay {
    left    : 0;
    top     : 0;
  }
}

.without_payment_information {
    display    : block;
    width      : 100%;
    background : $vert;
    @include radius(8px);
    margin-bottom: 20px;
    padding    : 20px;
    p {
        display     : block;
        font-size   : 14px;
        line-height : 25px;
        margin      : 0;
    }
}
