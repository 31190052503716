app-navigation-sidenav {
	display  : block;
	width    : 100%;
	height   : 100%;
	overflow : auto;
	&.has_padding {
		padding-bottom : 47px;
	}
	.mat-ripple-element {
		display : none !important;
	}
	.mat-mdc-nav-list {
		display : block;
		padding : 0;
		.mdc-list-item {
			padding-left  : 0;
			padding-right : 0;
		}
		.mat-mdc-list-item {
			height     : auto !important;
			background : transparent !important;
			&:before {
				display : none;
			}
		}
		.mdc-list-item__content {
			display : block !important;
			width   : 100%;
			padding : 0 !important;
			margin  : 0 !important;
			.item_nav {
				width         : 100%;
				height        : 72px;
				padding       : 0 24px;
				border-bottom : 1px solid $gris;
				text-align    : left;
				cursor        : pointer;
				white-space   : normal;
				@include transition($transition);
				span {
					display     : inline-block;
					font-size   : 14px;
					line-height : 16px;
					color       : $grisfonce;
					position    : relative;
					&[class*="ty-"] {
						padding-left : 32px;
						&:before {
							@include y_align_absolute;
							left         : 0;
							font-size    : 1.4em;
							margin-right : 16px;
						}
					}
					&[class*="icon_"] {
						padding-left : 32px;
						&:before {
							content         : '';
							@include y_align_absolute;
							left            : 0;
							width           : 24px;
							height          : 24px;
							background-size : contain !important;
						}
					}
					&.icon_account {
						&:before {
							background : url("../../images/icons/icon_account.png") no-repeat center;
						}
					}
					&.icon_collaborators {
						&:before {
							background : url("../../images/icons/icon_collaborators.png") no-repeat center;
						}
					}
					&.icon_offers_creators {
						&:before {
							background : url("../../images/icons/icon_offers_creators.png") no-repeat center;
						}
					}
					&.icon_distributors {
						&:before {
							background : url("../../images/icons/icon_distributors.png") no-repeat center;
						}
					}
					&.icon_offers {
						&:before {
							background : url("../../images/icons/icon_offers.png") no-repeat center;
						}
					}
					&.icon_canals {
						&:before {
							background : url("../../images/icons/icon_canals.png") no-repeat center;
						}
					}
					&.icon_canals_r {
						&:before {
							background : url("../../images/icons/icon_canals_r.png") no-repeat center;
						}
					}
					&.icon_users {
						&:before {
							background : url("../../images/icons/icon_users.png") no-repeat center;
						}
					}
					&.icon_bills {
						&:before {
							background : url("../../images/icons/icon_bills.png") no-repeat center;
						}
					}
					&.icon_content {
						&:before {
							background : url("../../images/icons/icon_content.png") no-repeat center;
						}
					}
					&.icon_content_admin {
						&:before {
							background : url("../../images/icons/icon_content_admin.png") no-repeat center;
						}
					}
					&.icon_administrators {
						&:before {
							background : url("../../images/icons/icon_administrators.png") no-repeat center;
						}
					}
					&.icon_request {
						&:before {
							background : url("../../images/icons/icon_request.png") no-repeat center;
						}
					}
					&.icon_markup {
						&:before {
							background : url("../../images/icons/icon_markup.png") no-repeat center;
						}
					}
					&.icon_promotions {
						&:before {
							background : url("../../images/icons/icon_promotions.png") no-repeat center;
						}
					}
					&.icon_codes_partenaires {
						&:before {
							background : url("../../images/icons/icon_codes_partenaires.png") no-repeat center;
						}
					}
					&.icon_cadeaux {
						&:before {
							background : url("../../images/icons/icon_cadeaux.png") no-repeat center;
						}
					}
					&.icon_options {
						&:before {
							background : url("../../images/icons/icon_options.png") no-repeat center;
						}
					}
					&.icon_options_permanentes {
						&:before {
							background : url("../../images/icons/icon_options_permanentes.png") no-repeat center;
						}
					}
					&.icon_reseaux {
						&:before {
							background : url("../../images/icons/icon_reseaux.png") no-repeat center;
						}
					}
					&.icon_pages {
						&:before {
							background : url("../../images/icons/icon_pages.png") no-repeat center;
						}
					}
					&.icon_prestataires {
						&:before {
							background : url("../../images/icons/icon_prestataires.png") no-repeat center;
						}
					}
					&.icon_tenders {
						&:before {
							background : url("../../images/icons/icon_tenders.png") no-repeat center;
						}
					}
				}
				@include hover {
					background : $gris;
				}
				&.active {
					background : $gris;
					span {
						@include font_lato_bold;
					}
				}
				&.has_child {
					position      : relative;
					padding-right : 40px;
					&:after {
						content     : "\e004";
						font-family : "ty-win";
						position    : absolute;
						top         : 50%;
						margin-top  : -6px;
						right       : 24px;
						font-size   : 12px;
						height      : 12px;
						line-height : 12px;
						width       : 12px;
						text-align  : center;
						color       : $grisfonce;
						@include transition($transition);
					}
					&.open {
						background : $gris;
						&:after {
							@include transform(rotateX(-180deg));
						}
						~ .subnav {
							display : block;
						}
					}
				}
			}
			.subnav {
				display : none;
				.item_nav {
					height : 48px;
					span {
						padding-left : 32px;
						&:before {
							display : none;
						}
					}
					@include hover {
						background : $vertclair;
					}
					&.active {
						background : $vertclair;
					}
				}
				.subnav {
					.item_nav span {
						padding-left : 48px;
					}
					.subnav {
						.item_nav span {
							padding-left : 64px;
						}
						.subnav {
							.item_nav span {
								padding-left : 80px;
							}
						}
					}
				}
			}
		}
		.logo_powered_by {
			padding    : 8px;
			position   : absolute;
			bottom     : 0;
			left       : 0;
			background : $blanc;
			z-index    : 2;
			width      : 100%;
			border-top : 1px solid $gris;
			@include x_center;
			img {
				width     : 100%;
				max-width : 100px;
				display   : inline-block;
			}
		}
	}
}
