.content_cgvs, .content_forms {
    display       : block;
    width         : 100%;
    margin-bottom : 24px;
    &:empty {
        margin-bottom : 0;
    }
    .content_cgv, .content_form {
        display       : block;
        width         : 100%;
        margin-bottom : 16px;
        position      : relative;
        border        : 1px solid $gris;
        overflow      : hidden;
        @include radius(8px);
        .header_cgv, .header_form {
            padding    : 16px;
            background : $gris;
            .title {
                @include font_lato_bold;
                font-size   : 15px;
                line-height : 20px;
                color       : $noir;
            }
        }
        .body_form {
            padding : 16px;
        }
        .mat-tab-translations {
            position : relative;
            border   : none;
            margin   : 5px 0 !important;
        }
    }
}
