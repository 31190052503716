.stop_vente {
	display     : block;
	position    : relative;
	overflow    : hidden;
	height      : 30px;
	line-height : 30px;
	> label {
		display        : inline-block;
		vertical-align : middle;
		margin-right   : 12px;
	}

	.mat-mdc-slide-toggle {
		height         : 18px;
		line-height    : 18px;
		display        : inline-block;
		vertical-align : middle;

		.mdc-switch__shadow, .mdc-switch__ripple, .mdc-switch__icons {
			display : none !important;
		}

		.mdc-switch {
			width : 48px;
			.mdc-switch__track {
				display        : inline-block;
				vertical-align : middle;
				width          : 100%;
				height         : 8px;
				@include radius(4px);
				background     : rgba($grisfonce, 0.05);
				margin         : 0 2px;
				opacity        : 1;
				&:before, &:after {
					display : none;
				}
			}
			.mdc-switch__handle {
				width      : 20px;
				height     : 20px;
				top        : -6px;
				left       : -2px;
				@include radius(50%);
				background : $vertclair;
				@include transform(translateX(0) !important);
				@include transition($transition);
				&:before, &:after {
					display : none;
				}
			}
			&.mdc-switch--disabled {
				&, ~ label {
					opacity : 0.4;
				}
			}
		}
		.mat-mdc-slide-toggle-content {
			display        : inline-block;
			vertical-align : middle;
			font           : inherit;
			margin-left    : 8px;
			white-space    : normal;
			max-width      : calc(100% - 80px);
		}

		label {
			margin : 0;
		}

		&.mat-mdc-slide-toggle-checked {

			.mdc-switch {
				.mdc-switch__track {
					background : rgba($grisfonce, 0.05);
				}
				.mdc-switch__handle {
					background : $vert;
				}
			}
		}
	}
}

.bloc_availability {
	display : block;
	width   : 100%;
	.actions_availability {
		margin-bottom : 24px;
		p {
			display     : block;
			margin      : 0;
			font-size   : 15px;
			line-height : 20px;
			color       : $noir;
		}
	}
	.display_options {
		display       : block;
		width         : 100%;
		margin-bottom : 12px;
		.display_title {
			display       : block;
			font-size     : 15px;
			line-height   : 18px;
			color         : $noir;
			margin-bottom : 8px;
		}
		.calendar_switch {
			display : block;
			.switch {
				display        : inline-block;
				vertical-align : middle;
				position       : relative;
				margin-bottom  : 6px;
				&:not(:last-child) {
					margin-right : 24px;
				}
				app-core-toggle-boolean-filter, .switch_label {
					display        : inline-block;
					vertical-align : middle;
				}
				app-core-toggle-boolean-filter {
					.form-group {
						position   : static;
						min-height : inherit;
					}
					.app-toggle {
						> label {
							display : none;
						}
					}
					.mat-mdc-slide-toggle {
						height      : 17px;
						line-height : 17px;
						display     : block;
						.mdc-switch {
							display    : block;
							width      : 30px;
							height     : 17px;
							margin     : 0;
							background : transparent;
							top        : -1px;
							@include radius(9px);
							.mdc-switch__track {
								display : none;
							}
							.mdc-switch__handle-track {
								width     : 100%;
								height    : 100%;
								transform : none;
							}
							.mdc-switch__handle {
								position   : relative;
								left       : 0;
								top        : 0;
								@include transform(translateY(0));
								display    : block;
								width      : 100%;
								height     : 100%;
								background : $gris2;
								@include radius(10px);
								@include transition($transition);
								&:before {
									content     : "x";
									@include y_align_absolute;
									left        : 3px;
									width       : 12px;
									height      : 12px;
									line-height : 9px;
									text-align  : center;
									font-size   : 10px;
									color       : $noir;
									@include radius(50%);
									background  : $blanc;
									@include transition(left 0.2s ease-in-out);
									display     : block;
								}
							}
						}
						label {
							position : absolute;
							top      : 0;
							left     : 0;
							bottom   : 0;
							right    : 0;
							margin   : 0;
						}
						&.mat-mdc-slide-toggle-checked {

							.mdc-switch .mdc-switch__handle {
								background : $vert;
								&:before {
									content     : "\e003";
									font-family : "ty-win";
									left        : 15px;
									font-size   : 7px;
									line-height : 11px;
								}
							}
						}
					}
				}
				.switch_label {
					padding-left : 8px;
					margin-top   : -4px;
					img {
						display        : inline-block;
						vertical-align : middle;
						width          : 18px;
						margin-right   : 4px;
					}
					span {
						display        : inline-block;
						vertical-align : middle;
						font-size      : 12px;
						line-height    : 14px;
						color          : $noir;
					}
				}
			}
		}
		.stop_vente {
			margin-top : 21px;
		}
		mat-radio-group .mdc-radio {
			padding : 0;
		}
	}
	.calendar {
		border         : none;
		padding-bottom : 32px;
		.months-container {
			padding : 0;
			.month-container {
				margin  : 0;
				padding : 0;
				.month {
					* {
						font-family : 'Roboto', sans-serif !important;
					}
					th.month-title {
						font-size     : 14px;
						font-weight   : 500;
						margin-bottom : 8px;
					}
					th.day-header {
						font-size   : 12px;
						font-weight : 500;
					}
					.day {
						padding : 6px;
						width   : calc(100% / 7);
						height  : 125px;
						.day-label {
							width     : 20px;
							padding   : 0;
							font-size : 11px;
						}
						.infos {
							min-height : inherit;
							margin-top : 15px;
						}
						p {
							font-size   : 11px;
							font-weight : 400;
							margin      : 0 !important;
							img {
								display        : inline-block;
								vertical-align : middle;
								width          : 12px;
								margin-top     : -3px;
							}
							+ p {
								margin-top : 8px !important;
							}
							.unlimited {
								font-size      : 16px;
								line-height    : 8px;
								display        : inline-block;
								vertical-align : middle;
							}
							.negative {
								color       : $warning;
								font-weight : 500;
							}
							&.closed {
								text-align  : center;
								color       : $danger;
								opacity     : 0.8;
								font-weight : 500;
							}
						}
						&:not(.old):not(.new) {
							border : 1px solid #dddddd;
							&:nth-child(n + 6) {
								background : #e0eff7;
							}
							@include hover {
								background : $gris;
							}
						}
					}
				}
				&:not(:only-child) {
					&:nth-of-type(odd) {
						padding-right : 16px;
					}
					&:nth-of-type(even) {
						padding-left : 16px;
					}
					&:nth-child(n + 3) {
						padding-top : 32px;
					}
				}
				&:only-child {
					.month {
						.day {
							p {
								font-size : 13px;
								img {
									width : 15px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.offer_date_panel {
	position         : fixed;
	right            : 0;
	top              : 80px;
	bottom           : 0;
	width            : 50%;
	max-width        : 650px;
	background-color : $blanc;
	z-index          : 999;
	border-top       : 1px solid gray;
	border-left      : 1px solid gray;
	border-bottom    : 1px solid gray;
	@include radius(10px 0 0 10px);
	overflow         : visible;
	@include shadow();
	> * {
		display    : block;
		position   : relative;
		z-index    : 2;
		background : $blanc;
		height     : 100%;
		overflow   : auto;
		@include radius(10px 0 0 10px);
	}
	&:before {
		content    : '';
		position   : fixed;
		z-index    : 1;
		top        : 80px;
		left       : 0;
		bottom     : 0;
		right      : 0;
		background : $noir;
		opacity    : 0.15;
	}
	.panel_title {
		display    : block;
		width      : 100%;
		position   : sticky;
		top        : 0;
		left       : 0;
		z-index    : 2;
		padding    : 24px;
		background : $blanc;
		p {
			display     : block;
			margin      : 0;
			font-size   : 14px;
			line-height : 18px;
			&.title {
				font-size   : 22px;
				line-height : 28px;
			}
		}
	}
	span.ty-close {
		position  : relative;
		width     : 30px;
		height    : 30px;
		font-size : 32px;
		cursor    : pointer;
		&:before {
			@include center_align_absolute;
			line-height : 12px;
			height      : 20px;
		}
	}
	.form_part {
		display  : block;
		width    : 100%;
		padding  : 16px;
		position : relative;
		z-index  : 1;
		strong {
			font-weight : bold;
		}
		+ .form_part {
			margin : 0;
		}
		.title_part {
			margin-bottom : 8px;
		}
		.form-group {
			.checkbox.inline {
				width : 15%;
				&:nth-child(n + 5) {
					margin-top : 12px;
				}
			}
			.app-toggle {
				margin-top : 8px;
			}
			.mat-mdc-slide-toggle {
				height         : 24px;
				line-height    : 24px;
				display        : inline-block;
				vertical-align : middle;
				width          : auto;
				.mdc-switch {
					display    : block;
					width      : 40px;
					height     : 24px;
					margin     : 0;
					background : transparent;
					top        : -1px;
					@include radius(12px);
					.mdc-switch__track {
						display : none;
					}
					.mdc-switch__handle-track {
						width     : 100%;
						height    : 100%;
						transform : none;
					}
					.mdc-switch__handle {
						position   : relative;
						left       : 0;
						top        : 0;
						@include transform(translateY(0));
						display    : block;
						width      : 100%;
						height     : 100%;
						background : $gris2;
						@include radius(12px);
						@include transition($transition);
						&:before {
							content     : "x";
							@include y_align_absolute;
							left        : 3px;
							width       : 16px;
							height      : 16px;
							line-height : 13px;
							text-align  : center;
							font-size   : 10px;
							color       : $noir;
							@include radius(50%);
							background  : $blanc;
							@include transition(left 0.2s ease-in-out);
							display     : block;
						}
					}
				}
				label {
					position : absolute;
					top      : 0;
					left     : 0;
					bottom   : 0;
					right    : 0;
					margin   : 0;
				}
				&.mat-mdc-slide-toggle-checked {

					.mdc-switch .mdc-switch__handle {
						background : $vert;
						&:before {
							content     : "\e003";
							font-family : "ty-win";
							left        : 15px;
							font-size   : 7px;
							line-height : 14px;
						}
					}
				}

				~ label {
					display        : inline-block;
					vertical-align : middle;
					margin         : 0;
					padding-left   : 12px;
				}
			}
		}
	}
	table {
		font-family     : arial, sans-serif;
		border-collapse : collapse;
		width           : 100%;
		td, th {
			text-align : left;
			padding    : 6px;
			font-size  : 13px;
			img {
				display        : inline-block;
				vertical-align : middle;
				width          : 24px;
				margin-right   : 6px;
			}
		}
		td {
			padding : 8px 6px;
			input[type='text'] {
				display        : inline-block;
				vertical-align : middle;
				height         : 40px;
				padding-left   : 12px;
				padding-right  : 12px;
				~ label {
					left : 12px;
					top  : 11px;
				}
				&:not(:placeholder-shown), &:focus {
					~ label, ~ label {
						top       : 4px;
						font-size : 9px;
					}
				}
			}
		}
		th, td:first-child {
			font-size : 14px;
		}
		th {
			font-weight : bold;
		}
		tr + tr {
			td {
				border-top : 1px solid $gris;
			}
		}
	}
	.stock_info {
		display       : block;
		@include font_lato_bold;
		font-size     : 12px;
		line-height   : 24px;
		color         : $grisfonce;
		margin-bottom : 24px;
		padding       : 16px;
		background    : rgba($vert, 0.2);

		span {
			display     : block;
			font-size   : 14px;
			line-height : 18px;
			@include font_lato_regular;

			a {
				text-decoration : underline;
				color           : $noir;
			}
		}
	}
}
