@mixin subscriptionTile() {
	border : 1px solid $bleublanc;
	@include transition($transition);
	cursor : pointer;
	.tile_top, .tile_bottom {
		display        : flex;
		flex-direction : column;
	}
	.tile_top {
		position : relative;
		.ty-check {
			position         : absolute;
			display          : flex;
			justify-content  : center;
			align-items      : center;
			background-color : $gris;
			border-radius    : 25px;
			height           : 22px;
			width            : 22px;
			@include transition($transition);
			&:before {
				color     : $gris;
				font-size : 14px;
				@include transition($transition);
			}
		}
	}
	.tile_bottom {
		border-top : 1px solid $bleublanc;
		@include transition($transition);
	}
    &:not(.disabled):not(.tile_checked):not(.readonly){
        @include hover {
            border-color : $vert;
            .tile_top {
                .ty-check {
                    background-color : $bleublanc;
                    &:before {
                        color : $blanc;
                    }
                }
            }
            .tile_bottom {
                border-color : $grisfonce;
            }
        }
    }
	&.tile_checked {
		border-color : $vert;
		.tile_top {
			.ty-check {
				background-color : $vert;
				&:before {
					color : $blanc;
				}
			}
		}
		.tile_bottom {
			border-color : $grisfonce;
		}
	}
}

$registrationSubscriptionChoice        : (
	'.licenceType_choice_tiles_row:first-of-type': (
		'margin-bottom': (
			24px,
			32px
		)
	),
	'.licenceType_choice_tiles_row .licenceType_choice_tile_wrapper .licenceType_choice_tile .tile_top, .licenceType_choice_tiles_row .licenceType_choice_tile_wrapper .licenceType_choice_tile .tile_bottom': (
		'padding-top': (
			12px,
			16px
		),
		'padding-bottom': (
			12px,
			16px
		),
		'padding-left': (
			16px,
			24px
		),
		'padding-right': (
			16px,
			24px
		),
	),
	'.licenceType_choice_tiles_row .licenceType_choice_tile_wrapper .licenceType_choice_tile .tile_top .number': (
		'font-size': (
			18px,
			24px
		),
		'line-height': (
			32px,
			36px
		),
	),
	'.licenceType_choice_tiles_row .licenceType_choice_tile_wrapper .licenceType_choice_tile .tile_top p': (
		'font-size': (
			14px,
			16px
		),
		'line-height': (
			18px,
			20px
		),
	),
	'.licenceType_choice_tiles_row .licenceType_choice_tile_wrapper .licenceType_choice_tile .tile_bottom p': (
		'font-size': (
			12px,
			14px
		),
		'line-height': (
			16px,
			18px
		),
	),
	'.licenceType_choice_tiles_row .licenceType_choice_tile_wrapper .licenceType_choice_tile .tile_bottom span': (
		'font-size': (
			14px,
			16px
		),
		'line-height': (
			18px,
			20px
		),
	),
	'.licenceType_choice_tiles_row .licenceType_choice_tile_wrapper .licenceType_choice_tile .tile_bottom .choice_item.customizable span': (
		'font-size': (
			13px,
			14px
		),
		'line-height': (
			16px,
			18px
		),
	),
);

@include fluid($registrationSubscriptionChoice);

.licenceType_choice_tiles_row {
    .licenceType_choice_tile_wrapper {
        position      : relative;
        margin-bottom : 56px;
        input[type="radio"] {
            position : absolute;
            width    : 0;
            height   : 0;
            left     : 0;
            top      : 0;
        }
        .licenceType_choice_tile {
            @include subscriptionTile();
            @include shadow(0 0 4px 0 rgba($gris2, 0.5));
            @include radius(7px);
            height : 100%;
			background: $grisclair;
            .tile_top, .tile_bottom {
                align-items : center;
                text-align  : center;
                border      : none;
            }
            .tile_top {
                background : $grisclair;
                @include radius(7px 7px 0 0);
                .number {
                    @include font_lato_black;
                    color : $vert;
					font-size: 26px;
                    @include transition($transition);
                }
                p {
                    @include font_lato_bold;
                    color : $noir;
                }
                .ty-check {
                    top   : 12px;
                    right : 12px;
                }
            }
            .tile_bottom {
                border-radius  : 4px;
                padding-bottom : 30px;
				background : $grisclair;
                > p {
                    display      : block;
                    width        : 100%;
                    @include font_lato_regular;
                    color        : $noir;
                    text-align   : left;
                    padding-left : 24px;
                    position     : relative;
                    &:before {
                        content     : "\e003";
                        font-family : "ty-win";
                        @include y_align_absolute;
                        left        : 0;
                        color       : $vert;
                    }
                    + p {
                        margin-top : 4px;
                    }
                }
                span {
                    @include font_lato_bold;
                }
                .choice_item {
                    border           : 1px solid $gris;
                    border-radius    : 10px;
                    position         : absolute;
                    width            : 80%;
                    height           : 56px;
                    bottom           : -28px;
                    background-color : $gris;
                    display          : flex;
                    justify-content  : center;
                    align-items      : center;
                    &:before {
                        content     : "\e004";
                        font-family : "ty-win";
                        @include x_align_absolute;
                        top         : -20px;
                        font-size   : 10px;
                        color       : $noir;
                    }
                    .price, .frequency_price {
                        color : rgba($noir, 0.5);
                    }
                    p.customized_max_offer {
                        margin-bottom : 4px;
                    }
                    .action {
                        position   : absolute;
                        z-index    : 2;
                        top        : 0;
                        bottom     : 0;
                        width      : 32px;
                        background : $gris;
                        span {
                            @include center_align_absolute;
                            font-size : 20px;
                            color     : rgba($noir, 0.5);
                        }
                        &:first-child {
                            left : 0;
                            @include radius(8px 0 0 8px);
                            span {
                                font-size : 24px;
                            }
                        }
                        &:last-child {
                            right : 0;
                            @include radius(0 8px 8px 0);
                        }
                        &.disabled {
                            cursor: default;
                        }
                        &:not(.disabled) {
                            span {
                                color : $noir;
                            }
                            @include hover {
                                span {
                                    color : $vert;
                                }
                            }
                        }
                    }
                    &.customizable {
                        padding    : 0 32px;
                        background : $blanc;
                    }
                }
                .ty-check {
                    display          : none;
                    position         : absolute;
                    z-index          : 3;
                    justify-content  : center;
                    align-items      : center;
                    background-color : $gris;
                    border-radius    : 25px;
                    height           : 22px;
                    font-size        : 14px !important;
                    line-height      : 24px !important;
                    width            : 22px;
                    top              : -10px;
                    right            : -10px;
                    @include transition($transition);
                    &:before {
                        color     : $gris;
                        font-size : 14px;
                        @include transition($transition);
                    }
                }
            }
            &.tile_checked {
                .tile_bottom {
                    .choice_item {
                        border-color : $vert;
						background: $vertclair;
                        .price, .frequency_price {
                            color : $noir;
                        }
                        &.customizable {
                            .customized_max_offer span span {
                                color : $vert;
                            }
                        }
                    }
                    .ty-check {
                        display          : block;
                        background-color : $vert;
                        &:before {
                            color : $blanc;
                        }
                    }
                }
            }
            &.disabled {
                cursor: default;
                background: $grisclair;
                .tile_top{
                    background: transparent;
                    .number, p{
                        color: $gris3;
                    }
                }
                .tile_bottom{
                    > p, > p:before{
                        color: $gris3;
                    }
                }
                .choice_item{
                    background: $grisclair !important;
                }
            }
            &.readonly {
                cursor: default;
            }
        }
    }
}

$registrationSubscriptionLicenceTile   : (
	'.licenceType_choice_tiles_row .licenceType_choice_tile_wrapper .licenceType_choice_tile .tile_top .licence_name' : (
		'font-size': (
			20px,
			24px
		),
		'line-height': (
			22px,
			26px
		),
		'margin-bottom': (
			8px,
			12px
		),
	),
	'.licenceType_choice_tiles_row .licenceType_choice_tile_wrapper .licenceType_choice_tile .tile_top > p' : (
		'font-size': (
			14px,
			16px
		),
		'line-height': (
			22px,
			26px
		),
	),
	'.licenceType_choice_tiles_row .licenceType_choice_tile_wrapper .licenceType_choice_tile .tile_bottom .prices .price_item .label' : (
		'font-size': (
			14px,
			16px
		),
	),
	'.licenceType_choice_tiles_row .licenceType_choice_tile_wrapper .licenceType_choice_tile .tile_bottom .prices .price_item .price' : (
		'font-size': (
			16px,
			18px
		),
	),
	'.licenceType_choice_tiles_row .licenceType_choice_tile_wrapper .licenceType_choice_tile .tile_bottom > span' : (
		'font-size': (
			16px,
			18px
		),
	),
);
@include fluid($registrationSubscriptionLicenceTile);

// Style complet de la tuile

@mixin subscriptionLicenceTile() {
	.licenceType_choice_tiles_row {
		margin-bottom : 16px;
		.licenceType_choice_tile_wrapper {
			position : relative;
			&:not(:first-child) {
				@include media_x_max(md) {
					margin-top : 24px;
				}
			}
			input[type="radio"] {
				position : absolute;
				width    : 0;
				height   : 0;
				left     : 0;
				top      : 0;
			}
			.licenceType_choice_tile {
				@include subscriptionTile();
				@include shadow(0 0 11px 0 rgba($gris2, 0.5));
				border-radius : 30px;
				height        : 100%;
				@include fluid(('.tile_top, .tile_bottom': (
					'padding-top': (
						16px,
						24px
					),
					'padding-bottom': (
						16px,
						24px
					),
					'padding-left': (
						24px,
						32px
					),
					'padding-right': (
						24px,
						32px
					),
				)));
				.tile_top {
					.licence_name {
						@include font_lato_black();
					}
					> p {
						color : $txt;
						@include media_x_min(md) {
							min-height : 50px;
						}
					}
					@include fluid(('.ty-check': (
						'top': (
							16px,
							24px
						),
						'right': (
							24px,
							32px
						),
					)));
				}
				.tile_bottom {
					padding    : 0;
					border-top : none;
					tr {
						font-weight : 600;
						text-align  : center;
						border-top  : 1px solid $gris2;
						td {
							vertical-align : middle;
							padding        : 8px 12px;
							@include font_lato_regular;
							font-size      : 13px;
							line-height    : 16px;
							&:last-child {
								@include font_lato_bold;
							}
						}
					}
					.ty-check {
						display          : inline-grid;
						justify-content  : center;
						align-items      : center;
						background-color : $vert;
						border-radius    : 25px;
						height           : 22px;
						width            : 22px;
						@include transition($transition);
						&:before {
							color     : $blanc;
							font-size : 14px;
							@include transition($transition);
						}
					}
				}
			}
		}
	}
}
