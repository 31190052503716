.period-container {
	.period-list-container {
		.title {
			font-size     : 24px;
			line-height   : 30px;
			padding       : 0;
			margin        : 0;
			margin-bottom : 16px;
		}
		.period {
			display       : block;
			width         : 100%;
			padding       : 8px 16px;
			border        : 1px solid rgba($grisfonce, 0.5);
			margin-bottom : 16px;
			@include radius(6px);
			.dates {
				@include font_lato_bold;
				font-size   : 14px;
				line-height : 20px;
				color       : $grisfonce;
				margin      : 0;
				span {
					display : block;
				}
			}
			button {
				display        : inline-block;
				vertical-align : middle;
				background     : none;
				border         : none;
				width          : 40px;
				height         : 40px;
				min-width      : 0;
				@include radius(50%);
				padding        : 0;
				text-align     : center;
				+ button {
					margin-left : 8px;
				}
				mat-icon {
					height    : 20px;
					width     : 20px;
					font-size : 20px;
				}
			}
		}
	}
}
