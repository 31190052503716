$molButtonsMap : (
    '.btn_cta' : (
        'height' : (
            48px,
            54px
        ),
        'font-size' : (
            14px,
            16px
        ),
        'padding-left' : (
            16px,
            24px
        ),
        'padding-right' : (
            16px,
            24px
        ),
    ),
    '.btn_cta span' : (
        'line-height' : (
            16px,
            18px
        ),
    ),
    '.btn_cta.btn_little' : (
        'height' : (
            40px,
            48px
        ),
        'font-size' : (
            13px,
            15px
        ),
    ),
    '.btn_cta.btn_little span' : (
        'line-height' : (
            13px,
            15px
        ),
    ),
    '.btn_cta.btn_small' : (
        'height' : (
            30px,
            36px
        ),
        'font-size' : (
            11px,
            12px
        ),
    ),
    '.btn_cta.btn_small span' : (
        'line-height' : (
            11px,
            12px
        ),
    ),
);

@include fluid($molButtonsMap);

.btn_cta {
    @include d_flex;
    @include y_items_center;
    @include x_center;
    @include font_lato_bold;
    max-width      : 100%;
    background     : $grisfonce;
    color          : $blanc;
    padding-bottom : 3px;
    @include radius(8px);
    span {
        &[class*='ty-'] {
            &:before {
                display        : inline-block;
                vertical-align : middle;
                margin-right   : 8px;
                font-size      : 1.1em;
                margin-top     : -2px;
            }
        }
    }
    &:not(:disabled):not(.disabled) {
        cursor : pointer;
        @include transition($transition);
        @include hover {
            background : $noir;
            color      : $vert;
            @include shadow(1px 1px 10px 0 rgb(0, 0, 0, 0.2));
        }
        &.hover {
            background : $noir;
            color      : $vert;
            @include shadow(1px 1px 10px 0 rgb(0, 0, 0, 0.2));
        }
    }
    &.btn_red {
        background : $danger;
        &:not(:disabled):not(.disabled) {
            @include hover {
                background : darken($danger, 0.1);
                color      : $blanc;
            }
            &.hover {
                background : darken($danger, 0.1);
                color      : $blanc;
            }
        }
    }
    &.btn_highlight{
        background : $vert;
        color          : $grisfonce;
        &:not(:disabled):not(.disabled) {
            @include hover {
                background : darken($vert, 0.3);
                color          : $grisfonce;
            }
            &.hover {
                background : darken($vert, 0.3);
                color          : $grisfonce;
            }
        }
    }
    &.btn_white {
        background : $blanc;
        color      : $noir;
        &:not(:disabled):not(.disabled) {
            @include hover {
                background : $blanc;
                color      : $vert;
            }
            &.hover {
                background : $blanc;
                color      : $vert;
            }
        }
    }
    &.btn_lines {
        background : $blanc;
        border     : 1px solid $grisfonce;
        color      : $noir;
        &:not(:disabled):not(.disabled) {
            @include hover {
                background : $grisclair;
                color      : $noir;
            }
            &.hover {
                background : $grisclair;
                color      : $noir;
            }
        }
        &:disabled, &.disabled {
            background : $blanc !important;
            color      : rgba($grisfonce, 0.6) !important;
            border     : 1px solid rgba($grisfonce, 0.4) !important;
        }
    }
	&.btn_green_lines {
		background : $blanc;
		border     : 1px solid $vert;
		color      : $noir;
		&:not(:disabled):not(.disabled) {
			@include hover {
				color : $noir;
				@include degrade($vertfonce, $vert);
			}
			&.hover {
				color : $noir;
				@include degrade($vertfonce, $vert);
			}
		}
		&:disabled, &.disabled {
			background : $blanc !important;
			color      : rgba($grisfonce, 0.6) !important;
			border     : 1px solid rgba($vert, 0.4) !important;
		}
	}
    &.btn_add {
        &:before {
            content        : '+';
            display        : inline-block;
            vertical-align : middle;
            font-size      : 1.2em;
            margin-right   : 16px;
        }
    }
    &:disabled, &.disabled {
        cursor     : default;
        background : #efefef !important;
        color      : #555555 !important;
        border     : none !important;
    }
}
