$blocLoginMap : (
	'.auth-wrapper' : (
		'padding-top' : (
			80px,
			120px
		),
	),
	'.auth-wrapper .header_auth' : (
		'height' : (
			80px,
			120px
		),
	),
	'.auth-wrapper .header_auth .back:before' : (
		'font-size' : (
			18px,
			24px
		),
	),
	'.auth-wrapper .header_auth .logo .main_logo': (
		'max-width': (
			140px,
			240px
		),
		'max-height': (
			40px,
			55px
		),
	),
	'.auth-wrapper .header_auth .logo .powered' : (
		'width' : (
			80px,
			110px
		),
	),
	'.auth-wrapper .bloc_auth' : (
		'padding' : (
			16px,
			32px
		),
	),
	'.auth-wrapper .bloc_auth .content_auth .form-group + .form-group' : (
		'margin-top' : (
			16px,
			32px
		),
	),
);

@include fluid($blocLoginMap);

.auth-wrapper {
	position   : fixed;
	top        : 0;
	left       : 0;
	width      : 100%;
	height     : 100%;
	z-index    : 5;
	background : $grisclair;
	overflow   : auto;
	.header_auth {
		position   : absolute;
		left       : 0;
		top        : 0;
		right      : 0;
		background : $blanc;
		@include shadow(0 2px 4px 0 rgba($noir, 0.15));
		.back {
			@include y_align_absolute;
			left   : 32px;
			cursor : pointer;
			color  : $noir;
			@include transition($transition);
			&:before {
				display : block;
				@include transform(rotate(180deg));
			}
			@include hover {
				color : $vert;
			}
		}
		.logo {
			@include center_align_absolute;
			text-align : center;
			img {
				display        : inline-block;
				vertical-align : middle;
				margin         : auto;
				&.powered {
					margin-left : 16px;
				}
			}
			@include media_x_max('md') {
				img {
					display : block;
					&.powered {
						margin-top  : 4px;
						margin-left : auto;
					}
				}
			}
		}
		.flags {
			@include y_align_absolute;
			right : 24px;
			width : 80px;
		}
	}
	.auth {
		padding   : 24px;
		margin    : auto;
		max-width : 650px;
	}
	.bloc_auth {
		margin     : 8px 0;
		background : $blanc;
		@include shadow();
		@include radius(5px);
		.auth_title {
			text-align    : center;
			margin-bottom : 56px;
			span {
				font-size      : 24px;
				line-height    : 30px;
				padding        : 0 32px;
				padding-bottom : 16px;
				position       : relative;
				letter-spacing : 0.1px;
				&:after {
					content    : '';
					position   : absolute;
					bottom     : 0;
					left       : 0;
					right      : 0;
					height     : 2px;
					@include radius(1px);
					background : $vert;
				}
			}
		}
		.content_auth {
			margin    : auto;
			max-width : 480px;
			.form-group {
				&:not(.submit) {
					margin-left  : auto;
					margin-right : auto;
					max-width    : 400px;
				}
			}
			.btn_cta {
				+ .btn_cta {
					margin-top : 16px;
				}
			}
			.link_auth {
				text-align : center;
				margin-top : 16px;
				a {
					@include font_lato_light;
					font-size       : 16px;
					line-height     : 20px;
					color           : $noir;
					text-decoration : underline;
				}
			}
		}
	}
}
