.form_account {
	display    : block;
	width      : 100%;
	height     : calc(100vh - 80px - 48px);
	overflow   : hidden;
	background : $blanc;
	@include shadow(0 0 4px 0 rgba(0, 0, 0, 0.5));
	@include radius(8px);
	position   : relative;
	.infos_user {
		position   : absolute;
		z-index    : 2;
		top        : 0;
		left       : 0;
		width      : 210px;
		height     : 80px;
		background : $gris;
		padding    : 0 24px;
		@include radius(0 8px 0 0);
		.name {
			@include font_lato_bold;
			font-size    : 15px;
			line-height  : 20px;
			color        : $noir;
			padding-left : 40px;
			position     : relative;
			span {
				@include y_align_absolute;
				left           : 0;
				width          : 34px;
				height         : 34px;
				line-height    : 34px;
				@include radius(50%);
				background     : $vert;
				text-align     : center;
				text-transform : uppercase;
				color          : $blanc;
				font-size      : 20px;
			}
		}
	}
	> .mat-mdc-tab-group {
		display      : block;
		width        : 100%;
		height       : 100%;
		position     : relative;
		z-index      : 1;
		padding-left : 210px;
		> .mat-mdc-tab-header {
			position    : absolute;
			z-index     : 2;
			top         : 0;
			left        : 0;
			bottom      : 0;
			width       : 210px;
			padding-top : 80px;
			background  : $blanc;
			@include shadow(0 0 4px 0 rgba(0, 0, 0, 0.25));
			@include radius(0 8px 8px 0);
			.mat-mdc-tab-header-pagination, .mat-ink-bar, .mdc-tab-indicator, .mat-ripple {
				display : none !important;
			}
			.mat-mdc-tab-label-container {
				display  : block;
				width    : 100%;
				height   : 100%;
				overflow : auto;
				.mat-mdc-tab-labels {
					display : block;
					width   : 100%;
					height  : 100%;
				}
				.mat-mdc-tab {
					width         : 100%;
					height        : 65px;
					border-bottom : 1px solid $gris;
					@include x_start;
					@include y_items_center;
					.mdc-tab__content {
						font-size   : 14px;
						line-height : 18px;
						color       : $noir !important;
						.mdc-tab__text-label {
							color : inherit;
						}
					}
					&.mdc-tab--active {
						background : $grisfonce;
						.mdc-tab__content {
							color : $blanc !important;
						}
					}
				}
			}
		}
		> .mat-mdc-tab-body-wrapper {
			display  : block;
			width    : 100%;
			height   : 100%;
			overflow : visible;
			> .mat-mdc-tab-body {
				overflow : visible !important;
				position : absolute !important;
				z-index  : 1;
				top      : 80px !important;
				left     : 0 !important;
				bottom   : 0 !important;
				right    : 0 !important;
				display  : none;
				@include transform(none !important);
				&.mat-mdc-tab-body-active {
					display : block;
				}
				> div {
					width    : 100%;
					height   : 100%;
					padding  : 0 !important;
					@include transform(none !important);
					overflow : visible !important;
				}
				.title_tab {
					position : absolute;
					top      : -80px;
					left     : 0;
					width    : 100%;
					height   : 80px;
					> div {
						background : $vertclair;
					}
					.title {
						margin-left : 24px;
						font-size   : 17px;
						line-height : 22px;
						color       : $noir;
						@include font_lato_bold;
					}
					.btn_cta {
						margin-right : 24px;
					}
				}
				.current_tab {
					border : 2px solid #34e0a1;
				}
				.content_tab {
					display  : block;
					width    : 100%;
					height   : 100%;
					overflow : auto;
					padding  : 32px;
					&.my_card {
						&:has(.mat-mdc-tab-group) {
							padding-top : 0;
						}
						> .mat-mdc-tab-group {
							margin-top : 0;
							> .mat-mdc-tab-header {
								position    : sticky;
								top         : 0;
								background  : white;
								z-index     : 999999;
								padding-top : 12px;
							}
						}
					}
					p {
						a {
							color           : $noir;
							text-decoration : underline;
							font-weight     : 500;
						}
					}
				}
			}
		}
	}
	.add_role {
		display    : block;
		width      : 100%;
		background : $gris;
		@include radius(8px);
		padding    : 24px 32px;
		p {
			display     : block;
			font-size   : 14px;
			line-height : 25px;
			margin      : 0;
		}
		.btn_cta {
			margin-top : 16px;
		}
	}
	.advantage_content {
		margin-top : 24px;
		.content_label {
			color         : $noir;
			font-size     : 16px;
			margin-bottom : 24px;

			&.medium {
				@include font_lato_bold();
			}
			&.green {
				color : $vert;
			}
		}
		ul.content_list {
			margin-right : 12px;

			li {
				&:not(:last-child) {
					margin-bottom : 16px;
				}
				span {
					color   : $txt;
					display : flex;
					&:before {
						color            : $blanc;
						background-color : $vert;
						border-radius    : 3px;
						height           : 20px;
						width            : 20px;
						margin-right     : 24px;
						padding          : 2px;
						display          : flex;
						justify-content  : center;
						align-items      : center;
					}
				}
			}
		}
	}
	.accept_admin {
		p {
			display       : block;
			font-size     : 15px;
			line-height   : 25px;
			margin-bottom : 16px;
		}
		+ .accept_admin {
			margin-top  : 24px;
			padding-top : 24px;
			border-top  : 1px solid rgba($grisfonce, 0.4)
		}
	}
	.desactivate_account {
		> div {
			position : relative;
			height   : 100%;
			z-index  : 2;
		}
		p {
			display     : block;
			font-size   : 15px;
			line-height : 25px;
			margin      : 0;
		}
		.btn_cta {
			margin-top : 16px;
		}
		img {
			position  : absolute;
			z-index   : 2;
			bottom    : 120px;
			right     : 120px;
			width     : 60%;
			max-width : 450px;
			opacity   : 0.5;
		}
	}
	.invoices {
		.last_invoice {
			img {
				width : 100%;
			}
			.info_invoice {
				width      : 100%;
				padding    : 24px 40px;
				background : $gris;
				@include radius(8px);
				p {
					font-size   : 15px;
					line-height : 20px;
					color       : $noir;
					margin      : 0;
					&.title {
						font-size : 16px;
						@include font_lato_bold;
					}
					&#lastAmount {
						@include font_lato_black;
						font-size   : 30px;
						line-height : 40px;
						margin      : 4px 0;
					}
				}
				.dl_invoice {
					margin-top : 16px;
					p {
						font-size : 13px;
						mat-icon {
							font-size      : 16px;
							height         : 16px;
							width          : 16px;
							margin-right   : 4px;
							vertical-align : middle;
							margin-top     : -2px;
						}
					}
					.btn_cta {
						padding : 0 8px;
						mat-icon {
							font-size : 20px;
							height    : 20px;
						}
					}
				}
			}
		}
		.bloc_title {
			margin-bottom : 16px;
			margin-top    : 32px;
			p {
				@include font_lato_bold;
				font-size   : 15px;
				line-height : 20px;
				margin      : 0;
			}
		}
		.next_invoice {
			background : $gris;
			padding    : 24px 32px;
			@include radius(8px);
			> div > div {
				padding : 0 24px;
			}
			.next {
				display    : block;
				width      : 100%;
				text-align : center;
				img {
					width         : 80%;
					max-width     : 80px;
					margin-bottom : 24px;
				}
				.head_next {
					margin-bottom : 8px;
					span {
						font-size   : 12px;
						line-height : 15px;
						opacity     : 0.7;
					}
				}
				.infos_next {
					background : $blanc;
					border     : 1px solid $grisfonce;
					@include radius(4px);
					padding    : 8px 0;
					span {
						font-size   : 15px;
						line-height : 20px;
						&.date {
							&:before {
								content        : '';
								display        : inline-block;
								vertical-align : middle;
								width          : 6px;
								height         : 6px;
								@include radius(50%);
								background     : $vert;
								margin-right   : 5px;
							}
						}
					}
				}
			}
			.change_sub {
				text-align : center;
				p {
					font-size     : 14px;
					line-height   : 20px;
					color         : $noir;
					margin-bottom : 16px;
					&:first-child {
						@include font_lato_bold;
						font-size   : 16px;
						line-height : 24px;
					}
				}
				.btn_cta {
					margin : auto;
				}
			}
		}
	}

	.bloc_account_infos {
		border        : 1px solid #eaeaea;
		padding       : 10px;
		margin-bottom : 20px;
		background    : #eaeaea;
		p {
			font-size   : 13px;
			line-height : 20px;
			color       : $noir;
			margin      : 0;

			&.title {
				font-size     : 16px;
				@include font_lato_bold;
				margin-bottom : 10px;
			}
		}
	}
	.info_desactivate {
		background  : #eaeaea;
		padding     : 20px 20px 20px 20px;
		font-weight : bold;
		margin-top  : 15px;
		p {
			margin-bottom : 0;
		}
		button {
			margin : auto !important;
		}
	}
}

.paymentWaiting {
	background    : #eaeaea;
	padding       : 20px 20px 20px 20px;
	margin-bottom : 20px;
	margin-left   : 12px;
	border        : 1px solid #666666;
	font-family   : "Lato", sans-serif;
	font-weight   : 700;
	li {
		display : block;
	}
}

.contact_tywin {
	display       : block;
	width         : 100%;
	margin-top    : 20px;
	margin-bottom : 20px;
	background    : $gris;
	@include radius(8px);
	padding       : 24px 32px;
	p {
		display     : block;
		font-size   : 14px;
		line-height : 25px;
		margin      : 0;
	}
}
