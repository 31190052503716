$molButtonsMap : (
    '.btn_cta' : (
        'height' : (
            48px,
            54px
        ),
        'font-size' : (
            14px,
            16px
        ),
        'padding-left' : (
            16px,
            24px
        ),
        'padding-right' : (
            16px,
            24px
        ),
    ),
    '.btn_cta span' : (
        'line-height' : (
            16px,
            18px
        ),
    ),
    '.btn_cta.btn_little' : (
        'height' : (
            40px,
            48px
        ),
        'font-size' : (
            13px,
            15px
        ),
    ),
    '.btn_cta.btn_little span' : (
        'line-height' : (
            13px,
            15px
        ),
    ),
    '.btn_cta.btn_small' : (
        'height' : (
            30px,
            36px
        ),
        'font-size' : (
            11px,
            12px
        ),
    ),
    '.btn_cta.btn_small span' : (
        'line-height' : (
            11px,
            12px
        ),
    ),
);

@include fluid($molButtonsMap);

.btn_cta {
    @include d_flex;
    @include y_items_center;
    @include x_center;
    font-weight    : bold;
    max-width      : 100%;
    padding-bottom : 3px;
    @include radius(8px);
    span {
        font : inherit;
        font-size: 16px !important;
    }
    &:not(:disabled):not(.disabled) {
        cursor : pointer;
        @include transition($transition);
        @include hover {
            @include shadow(1px 1px 10px 0 rgb(0, 0, 0, 0.2));
        }
    }
    &:disabled, &.disabled {
        cursor     : default;
        background : #efefef !important;
        color      : #555555 !important;
        border     : none !important;
    }
}
