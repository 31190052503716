mat-accordion {
	display       : block;
	width         : 100%;
	margin-bottom : 24px;
	mat-expansion-panel {
		margin-bottom : 16px !important;
		border        : 1px solid $grisfonce;
		@include radius(4px !important);
		box-shadow    : none !important;
		mat-expansion-panel-header {
			height      : 40px !important;
			line-height : 40px !important;
			padding     : 0 16px;

			&.movable {
				cursor : move !important;
			}
			mat-panel-title {
				@include d_flex;
				@include x_between;
				@include font_lato_regular;
				font-size   : 14px;
				line-height : 21px;
				margin-top  : -2px;
				.valid, .invalid {
					display        : inline-block;
					vertical-align : middle;
					margin-left    : 6px;
					font-size      : 18px;
					line-height    : 18px;
					font-weight    : bold;
				}

				.valid {
					padding-top : 2px;
					&:before {
						content     : 'check';
						font-family : "Material Icons";
						color       : $success;
					}
				}

				.invalid {
					padding-top : 4px;
					&:before {
						content     : 'close';
						font-family : "Material Icons";
						color       : $danger;
					}
				}
			}
			.mat-expansion-indicator:after {
				margin-top   : -8px;
				padding      : 5px;
				border-color : $noir;
			}
		}
		.mat-expansion-panel-body {
			padding    : 16px;
			border-top : 1px solid $gris;
			.txt_offer {
				border-top  : none !important;
				padding-top : 0 !important;
				&:last-child {
					margin-bottom : 0;
				}
			}
		}
	}
	&.gradient {
		mat-expansion-panel {
			border : none;
			mat-expansion-panel-header {
				@include degrade(rgba($vert, 0.2), rgba($vert, 0), 70%, 100%);
				line-height : inherit !important;
				color       : $grisfonce;
				padding     : 16px;
				height      : auto !important;
				mat-panel-title {
					display     : block;
					@include font_lato_bold;
					font-size   : 18px;
					line-height : 24px;
					span {
						@include font_lato_regular;
						font-size   : 0.7em;
						margin-left : 4px;
					}
				}
				&.mat-expanded:focus, &.mat-expanded:hover {
					@include degrade(rgba($vert, 0.2), rgba($vert, 0), 70%, 100%);
				}
			}
			.mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), &:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
				@include degrade(rgba($vert, 0.2), rgba($vert, 0), 70%, 100%);
			}
			.mat-expansion-panel-body {
				padding    : 24px 0;
				border-top : none;
			}
		}
	}
}
