// Repasse graphique à réaliser
.card_service {
    display  : block;
    width    : 100%;
    height   : 100%;
    overflow : hidden;
    @include radius(8px);
    @include shadow(0 0 16px 0 rgba($noir, 0.10));

    .title {
        background : $gris;
        padding    : 12px 16px;
        > .row {
            min-height : 40px;
        }
        p {
            font-size   : 15px;
            line-height : 18px;
            @include font_lato_bold;
            color       : $noir;
            margin      : 0;
        }
        img {
            display    : block;
            max-height : 30px;
            max-width  : 50px;
        }
    }

    .infos {
        padding : 16px;
        .commission {
            font-size     : 15px;
            line-height   : 18px;
            @include font_lato_bold;
            color         : $noir;
            margin-bottom : 0;
        }
        .prices {
            display    : block;
            margin     : 0 -16px;
            padding    : 8px 16px;
            background : rgba($vert, 0.1);
            margin-top : 16px;
        }
        .price {
            font-size     : 13px;
            line-height   : 16px;
            @include font_lato_bold;
            color         : $grisfonce;
            margin-bottom : 0;
            span {
                font-weight : normal;
                font-size   : 0.8em;
            }
            + .price {
                margin-top : 6px;
            }
        }
        .description {
            font-size     : 13px;
            line-height   : 15px;
            color         : $grisfonce;
            margin-bottom : 0;
            margin-top    : 12px;

            ul {
                list-style  : disc outside none;
                margin-left : 20px;

                li {
                    display: list-item;
                }
            }
        }
    }

    .links_service {
        display   : flex;
        flex-wrap : wrap;

        > div {
            width : 50%;

            a, button {
                cursor : pointer;
            }

            a, button {
                display     : block;
                width       : 100%;
                height      : 48px;
                line-height : 48px;
                background  : $blanc;
                padding     : 0 16px;
                text-align  : center;
                color       : $noir;
                @include transition($transition);
                span {
                    display        : inline-block;
                    vertical-align : middle;
                    @include font_lato_bold;
                    font-size      : 12px;
                    line-height    : 16px;
                    @include transition($transition);
                }
            }

            a, button {
                &:not(.disabled):not(:disabled) {
                    @include hover {
                        background : $gris;
                    }
                }
                &.disabled, &:disabled {
                    cursor : initial;
                    color  : rgba($noir, .2);
                }
            }

            &:nth-child(2), &:nth-child(3), &:nth-child(6), &:nth-child(7), &:nth-child(10) {
                a, button {
                    &:not(.disabled):not(:disabled) {
                        background : $noir;
                        color      : $blanc;

                        @include hover {
                            color      : $vert;
                        }
                    }

                    &.disabled, &:disabled {
                        cursor     : initial;
                        background : rgba($noir, .2);
                        color      : $blanc;
                    }

                }
            }
            &:last-child:nth-child(odd) { // Last child, if odd (impair) number, make div bigger
                width      : 100%;
                border-top : 1px solid $blanc;
            }

        }
    }
}
