// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$app-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.m2-define-light-theme((
    color: (
        primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn,
    )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

/* You can add global styles to this file, and also import other style files */

html, body {
    height: 100%;
}

body {
    margin: 0;
}

@import "assets/scss/app";

#atlwdg-trigger {
    z-index: 9999999999 !important;
}

.excluded-day {
    opacity: 1 !important;
    right: -1px !important;
    border-right: 1px solid lightgrey;
}

.current_user_roles {
    position: fixed;
    top: 2px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 4;
    height: 48px;
    background-color: $vert;
    color: black;
    font-size: 14px;
    width: 400px;
    margin: auto;
    line-height: 48px;
}

@import "js-year-calendar/dist/js-year-calendar.css";
