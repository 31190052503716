.collection {

    .movable {

        .icon_displacement {
            flex-grow: 0;
        }

        mat-panel-title {

            & > span:first-of-type {

                flex-grow: 1;
                margin-left: 20px;
            }
        }

    }

    .removable_item {
        border        : 1px solid rgba($grisfonce, 0.3);
        padding       : 16px;
        position      : relative;
        margin-bottom : 16px;
        @include radius(4px);
        padding-right : 72px;
        .remove_item, .move_item {
            position : absolute;
            right    : 12px;
            top      : 16px;
            width    : 48px;
            padding  : 0;
        }
        .mat-tab-translations {
            border      : none !important;
            padding-top : 0;
            .mat-mdc-tab-group .mat-mdc-tab-body {
                padding     : 0;
                padding-top : 16px;
            }
            .mat-mdc-tab-group .mat-mdc-tab-header {
                padding-left : 0;
                left         : -8px;
            }
            app-core-translation-add {
                left : -8px;
                top  : 0;
                + .mat-mdc-tab-group .mat-mdc-tab-header {
                    padding-left : 172px;
                }
            }

            + .remove_item {
                top : 84px;
            }
        }
    }
    mat-accordion mat-expansion-panel {
        border : 1px solid rgba($grisfonce, 0.3);
    }
    &.add_picture {
        > .row > div {
            margin-bottom : 16px;
        }
        .removable_item {
            height        : 100%;
            margin-bottom : 0;
        }
    }
}
