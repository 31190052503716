@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css2?family=GFS+Didot&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

@font-face {
	font-family : "HKGrotesk-Regular";
	src         : url("../../fonts/HKGrotesk-Regular.eot");
	src         : url("../../fonts/HKGrotesk-Regular.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/HKGrotesk-Regular.woff") format("woff"),
	url("../../fonts/HKGrotesk-Regular.ttf") format("truetype"),
	url("../../fonts/HKGrotesk-Regular.svg#HKGrotesk-Regular") format("svg");
	font-weight : normal;
	font-style  : normal;
}

@font-face {
	font-family : "Avenir-Next-tl-Pro-Regular";
	src         : url("../../fonts/avenirnextltpro-regular.eot");
	src         : url("../../fonts/avenirnextltpro-regular.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/avenirnextltpro-regular.woff") format("woff"),
	url("../../fonts/avenirnextltpro-regular.ttf") format("truetype"),
	url("../../fonts/avenirnextltpro-regular.svg#avenir_next_lt_proregular") format("svg");
	font-weight : normal;
	font-style  : normal;
}

@mixin font_lato_light {
	font-family : 'Lato', sans-serif;
	font-weight : 300;
}
@mixin font_lato_regular {
	font-family : 'Lato', sans-serif;
	font-weight : 400;
}
@mixin font_lato_bold {
	font-family : 'Lato', sans-serif;
	font-weight : 700;
}
@mixin font_lato_black {
	font-family : 'Lato', sans-serif;
	font-weight : 900;
}

@mixin font_roboto {
    font-family: 'Roboto', sans-serif;
    font-weight : 400;
}

@mixin font_source {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight : 400;
}

@mixin font_nunito {
    font-family: 'Nunito', sans-serif;
    font-weight : 400;
}

@mixin font_ubuntu {
    font-family: 'Ubuntu', sans-serif;
    font-weight : 400;
}

@mixin font_raleway {
    font-family: 'Raleway', sans-serif;
    font-weight : 400;
}

@mixin font_caveat {
    font-family: 'Caveat', cursive;
    font-weight : 400;
}

@mixin font_amatic {
    font-family: 'Amatic SC', cursive;
    font-weight : 400;
}

@mixin font_poiretone {
    font-family: 'Poiret One', cursive;
    font-weight : 400;
}

@mixin font_opensans {
    font-family: 'Open Sans', sans-serif;
    font-weight : 400;
}

@mixin font_montserrat {
    font-family: 'Montserrat', sans-serif;
    font-weight : 400;
}

@mixin font_oswald {
    font-family: 'Oswald', sans-serif;
    font-weight : 400;
}

@mixin font_slabo {
    font-family: 'Slabo 27px', serif;
    font-weight : 400;
}

@mixin font_ptsans {
    font-family: 'PT Sans', sans-serif;
    font-weight : 400;
}

@mixin font_merriweather {
    font-family: 'Merriweather', serif;
    font-weight : 400;
}

@mixin font_notosans {
    font-family: 'Noto Sans', sans-serif;
    font-weight : 400;
}

@mixin font_concertone {
    font-family: 'Concert One', cursive;
    font-weight : 400;
}

@mixin font_prompt {
    font-family: 'Prompt', sans-serif;
    font-weight : 400;
}

@mixin font_worksans {
    font-family: 'Work Sans', sans-serif;
    font-weight : 400;
}

@mixin font_ooohbaby {
    font-family: 'Oooh Baby', cursive;
    font-weight : 400;
}

@mixin font_badscript {
    font-family: 'Bad Script', cursive;
    font-weight : 400;
}

@mixin font_dancingscript {
    font-family: 'Dancing Script', cursive;
    font-weight : 400;
}

@mixin font_garamond {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
}

@mixin font_baskerville {
    font-family: 'Libre Baskerville', serif;
    font-weight: 400;
}

@mixin font_franklin {
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 400;
}

@mixin font_didot {
    font-family: 'GFS Didot', serif;
    font-weight: 400;
}

@mixin font_heebo {
    font-family: 'Heebo', sans-serif;
    font-weight: 400;
}

@mixin font_verdana {
    font-family: Verdana, sans-serif;
    font-weight: 400;
}

@mixin font_helvetica {
    font-family: Helvetica, sans-serif;
    font-weight: 400;
}

@mixin font_redhat {
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
}

@mixin font_grotesk {
    font-family: 'HKGrotesk-Regular', Arial, sans-serif;
    font-weight: 400;
}

@mixin font_abel {
	font-family: 'Abel', sans-serif;
	font-weight: 400;
}

@mixin font_adventpro_light {
	font-family: 'Advent Pro', sans-serif;
	font-weight: 300;
}

@mixin font_jost {
	font-family: 'Jost', sans-serif;
	font-weight: 400;
}

@mixin font_lora {
	font-family: 'Lora', serif;
	font-weight: 400;
}

@mixin font_montserrat_light {
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
}

@mixin font_montserrat_sbold {
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
}

@mixin font_robotocondensed {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 400;
}

@mixin font_robotomono {
	font-family: 'Roboto Mono', monospace;
	font-weight: 400;
}

@mixin font_robotoslab {
	font-family: 'Roboto Slab', serif;
	font-weight: 400;
}

@mixin font_rubik {
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
}

@mixin font_yeseva {
	font-family: 'Yeseva One', cursive;
	font-weight: 400;
}

@mixin font_dosis {
	font-family: 'Dosis', sans-serif;
	font-weight: 400;
}

@mixin font_oxygen {
	font-family: 'Oxygen', sans-serif;
	font-weight: 400;
}

@mixin font_barlow {
	font-family: 'Barlow', sans-serif;
	font-weight: 400;
}

@mixin font_dmsans {
	font-family: 'DM Sans', sans-serif;
	font-weight: 400;
}

@mixin font_firasans {
	font-family: 'Fira Sans', sans-serif;
	font-weight: 400;
}

@mixin font_siliguri {
	font-family: 'Hind Siliguri', sans-serif;
	font-weight: 400;
}

@mixin font_inconsolata {
	font-family: 'Inconsolata', monospace;
	font-weight: 400;
}

@mixin font_kanit {
	font-family: 'Kanit', sans-serif;
	font-weight: 400;
}

@mixin font_karla {
	font-family: 'Karla', sans-serif;
	font-weight: 400;
}

@mixin font_manrope {
	font-family: 'Manrope', sans-serif;
	font-weight: 400;
}

@mixin font_mukta {
	font-family: 'Mukta', sans-serif;
	font-weight: 400;
}

@mixin font_mulish {
	font-family: 'Mulish', sans-serif;
	font-weight: 400;
}

@mixin font_nanumgothic {
	font-family: 'Nanum Gothic', sans-serif;
	font-weight: 400;
}

@mixin font_quicksand {
	font-family: 'Quicksand', sans-serif;
	font-weight: 400;
}

@mixin font_titillium {
	font-family: 'Titillium Web', sans-serif;
	font-weight: 400;
}

@mixin font_avenir_next {
	font-family: 'Avenir-Next-tl-Pro-Regular', sans-serif;
	font-weight: 400;
}

@font-face {
	font-family: "ty-win";
	src:url("../../fonts/ty-win.eot");
	src:url("../../fonts/ty-win.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/ty-win.woff") format("woff"),
	url("../../fonts/ty-win.ttf") format("truetype"),
	url("../../fonts/ty-win.svg#ty-win") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon]:before {
	font-family: "ty-win" !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="ty-"]:before,
[class*=" ty-"]:before {
	font-family: "ty-win" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ty-app:before {
	content: "\e000";
}
.ty-arrow:before {
	content: "\e001";
}
.ty-calendar:before {
	content: "\e002";
}
.ty-check:before {
	content: "\e003";
}
.ty-chevron:before {
	content: "\e004";
}
.ty-credit-card:before {
	content: "\e005";
}
.ty-download:before {
	content: "\e006";
}
.ty-dropdown:before {
	content: "\e007";
}
.ty-eye:before {
	content: "\e008";
}
.ty-file:before {
	content: "\e009";
}
.ty-filter:before {
	content: "\e00a";
}
.ty-flag:before {
	content: "\e00b";
}
.ty-help:before {
	content: "\e00c";
}
.ty-iframe:before {
	content: "\e00d";
}
.ty-info:before {
	content: "\e00e";
}
.ty-list:before {
	content: "\e00f";
}
.ty-logout:before {
	content: "\e010";
}
.ty-map:before {
	content: "\e011";
}
.ty-message:before {
	content: "\e012";
}
.ty-promotion:before {
	content: "\e013";
}
.ty-search:before {
	content: "\e014";
}
.ty-user:before {
	content: "\e015";
}
.ty-user-add:before {
	content: "\e016";
}
.ty-users:before {
	content: "\e017";
}
.ty-house:before {
	content: "\e018";
}
.ty-close:before {
    content: "\2716";
}
