.swiper-container {
    display  : block;
    width    : 100%;
    position : relative;
    overflow : hidden;
    .swiper-content {
        display  : block;
        width    : 100%;
        height   : 100%;
        position : relative;
    }
    .swiper-wrapper {
        width    : 100%;
        height   : 100%;
        position : relative;
        z-index  : 1;
        @extend .row;
        @extend .no_wrap;
        .swiper-slide {
            @extend .col_12;
            width    : 100% !important;
            height   : auto;
            position : relative;
            > img {
                display : block;
                width   : 100%;
                height  : 100%;
                @include imageFit;
            }
            .swiper-lazy-preloader {
                position   : absolute !important;
                z-index    : 4 !important;
                top        : 0;
                left       : 0;
                bottom     : 0;
                right      : 0;
                background : $blanc;
            }
            .loader {
                @include center_align_absolute;
                margin  : 0;
                opacity : 0.6;
            }
        }
    }
    .swiper-pagination {
        @include x_align_absolute;
        z-index : 2;
        bottom  : 24px;
        .swiper-pagination-bullet {
            display        : inline-block;
            vertical-align : middle;
            width          : 12px;
            height         : 12px;
            background     : $blanc;
            @include radius(50%);
            cursor         : pointer;
            opacity        : 0.4;
            @include transition($transition);
            margin         : 0 5px;
            @include shadow();
            &.swiper-pagination-bullet-active {
                opacity : 1;
            }
        }
    }
    .swiper-button-prev, .swiper-button-next {
        @extend .btn_cta;
        @extend .btn_little;
        @include y_align_absolute;
        z-index    : 2;
        opacity    : 0.5;
        background : $grisfonce !important;
        color      : $blanc !important;
        &:after {
            content     : "\e001";
            font-family : "ty-win";
            display     : block;
        }
        &.swiper-button-disabled {
            opacity : 0.3;
            cursor  : default !important;
        }
        @include hover {
            &:not(.swiper-button-disabled) {
                opacity : 0.8;
                color   : $vert !important;
            }
        }
    }
    .swiper-button-prev {
        left : 16px;
        &:after {
            @include transform(rotate(180deg));
        }
    }
    .swiper-button-next {
        right : 16px;
    }
    &.slider_program {
        overflow      : visible;
        margin-bottom : 40px;

        &:not(.not_pictures) {
            .swiper-slide {
                @extend .col_6;
            }
        }

        &.not_pictures {
            .swiper-slide {
                @extend .col_4;
            }
        }

        .swiper-pagination {
            display : none;
        }
        .swiper-wrapper {
            margin : 0 -8px;
            width  : calc(100% + 16px);
            .swiper-slide {
                position    : relative;
                padding     : 0 8px;
                padding-top : 28px;
                .day {
                    position    : absolute;
                    top         : 0;
                    left        : 8px;
                    background  : rgba($grisfonce, 0.25);
                    height      : 28px;
                    line-height : 28px;
                    padding     : 0 24px;
                    font-size   : 14px;
                    color       : $blanc;
                    @include radius(7px 7px 0 0);
                }
                .content_program {
                    @include radius(0 7px 7px 7px);
                    @include shadow();
                    background : $blanc;
                    position   : relative;
                    overflow   : hidden;
                    &:after {
                        content  : '';
                        position : absolute;
                        top      : 0;
                        left     : 0;
                        bottom   : 0;
                        right    : 0;
                        @include radius(0 7px 7px 7px);
                    }
                    .info_program {
                        padding : 16px;
                        .txt_offer {
                            margin : 0;
                            .titre_h2, .titre_h3 {
                                font-size   : 15px;
                                line-height : 24px;
                            }
                            p, ul li {
                                font-size   : 12px;
                                line-height : 18px;
                            }
                        }
                    }
                }
                img {
                    display : block;
                    width   : 100%;
                    height  : 100%;
                    @include imageFit;
                }
                &.swiper-slide-active {
                    .day {
                        background : $grisfonce;
                    }
                    .content_program {
                        &:after {
                            border : 1px solid $grisfonce;
                        }
                    }
                }
                &:only-child {
                    padding-top : 0;
                    .content_program {
                        @include radius(7px);
                        &:after {
                            display : none;
                        }
                    }
                }
            }
        }
    }
}
