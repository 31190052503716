$pageOfferMap : (
    '.page_offer_preview' : (
        'padding' : (
            12px,
            24px
        ),
    ),
    '.page_offer_preview .back_to_list' : (
        'padding-bottom' : (
            16px,
            24px
        ),
    ),
    '.page_offer_preview .back_to_list a, .page_offer_preview .back_to_list span, .page_offer_preview .back_to_compo a, .page_offer_preview .back_to_compo span' : (
        'font-size' : (
            11px,
            12px
        ),
        'line-height' : (
            14px,
            16px
        ),
    ),
    '.page_offer_preview .title_offer' : (
        'margin-bottom' : (
            24px,
            40px
        ),
    ),
    '.page_offer_preview .title_offer .tag' : (
        'font-size' : (
            10px,
            12px
        ),
        'line-height' : (
            10px,
            12px
        ),
        'padding-top' : (
            3px,
            4px
        ),
        'padding-bottom' : (
            5px,
            6px
        ),
        'padding-left' : (
            12px,
            16px
        ),
        'padding-right' : (
            12px,
            16px
        ),
    ),
    '.page_offer_preview .title_offer .title, .page_offer_preview .title_offer' : (
        'font-size' : (
            18px,
            24px
        ),
        'line-height' : (
            24px,
            32px
        ),
    ),
    '.page_offer_preview .title_offer .infos' : (
        'margin-top' : (
            4px,
            8px
        ),
    ),
    '.page_offer_preview .title_offer .infos > div' : (
        'margin-top' : (
            4px,
            8px
        ),
    ),
    '.page_offer_preview .title_offer .infos span' : (
        'font-size' : (
            12px,
            14px
        ),
        'line-height' : (
            16px,
            18px
        ),
    ),
    '.page_offer_preview .offer_pictures' : (
        'margin-bottom' : (
            24px,
            40px
        ),
    ),
    '.page_offer_preview .offer_pictures .col_right .see_more span' : (
        'font-size' : (
            13px,
            15px
        ),
        'line-height' : (
            15px,
            18px
        ),
    ),
    '.page_offer_preview .txt_offer' : (
        'margin-bottom' : (
            12px,
            24px
        ),
    ),
    '.page_offer_preview .txt_offer + .txt_offer' : (
        'padding-top' : (
            12px,
            24px
        ),
    ),
    '.page_offer_preview .txt_offer, .page_offer_preview .txt_offer p' : (
        'font-size' : (
            13px,
            14px
        ),
        'line-height' : (
            18px,
            21px
        ),
    ),
    '.page_offer_preview .txt_offer p:not(:last-child)' : (
        'margin-bottom' : (
            12px,
            16px
        ),
    ),
    '.page_offer_preview .txt_offer .title' : (
        'font-size' : (
            16px,
            22px
        ),
        'line-height' : (
            22px,
            27px
        ),
        'margin-bottom' : (
            12px,
            16px
        ),
    ),
    '.page_offer_preview .txt_offer .title_2' : (
        'font-size' : (
            14px,
            16px
        ),
        'line-height' : (
            18px,
            22px
        ),
        'margin-bottom' : (
            8px,
            8px
        ),
    ),
    '.page_offer_preview .txt_offer .title + .condition' : (
        'margin-top' : (
            -6px,
            -8px
        ),
    ),
    '.page_offer_preview .txt_offer .condition' : (
        'font-size' : (
            12px,
            13px
        ),
        'line-height' : (
            15px,
            16px
        ),
        'margin-bottom' : (
            8px,
            8px
        ),
    ),
    '.page_offer_preview .txt_offer .interest span' : (
        'padding-left' : (
            14px,
            16px
        ),
    ),
    '.page_offer_preview .txt_offer .interest span:not(:last-child)' : (
        'margin-right' : (
            16px,
            24px
        ),
    ),
    '.page_offer_preview .txt_offer .see_more span' : (
        'font-size' : (
            12px,
            14px
        ),
        'line-height' : (
            18px,
            21px
        ),
    ),
    '.page_offer_preview .txt_offer.confirmation' : (
        'padding' : (
            16px,
            24px
        ),
    ),
    '.page_offer_preview .txt_offer.little' : (
        'margin-bottom' : (
            8px,
            12px
        ),
    ),
    '.page_offer_preview .txt_offer.little p' : (
        'font-size' : (
            12px,
            13px
        ),
        'line-height' : (
            16px,
            18px
        ),
    ),
    '.page_offer_preview .txt_offer.little .title_2' : (
        'font-size' : (
            13px,
            15px
        ),
        'line-height' : (
            17px,
            20px
        ),
        'margin-bottom' : (
            4px,
            4px
        ),
    ),
    '.page_offer_preview .txt_offer .provider' : (
        'width' : (
            80px,
            150px
        ),
    ),
    '.page_offer_preview .infos_program' : (
        'margin-bottom' : (
            24px,
            40px
        ),
    ),
    '.page_offer_preview .infos_program .nav_slider_program' : (
        'margin-bottom' : (
            16px,
            24px
        ),
    ),
    '.page_offer_preview .infos_program .slider_program .swiper-slide' : (
        'padding-top' : (
            22px,
            28px
        ),
    ),
    '.page_offer_preview .infos_program .slider_program .swiper-slide .day' : (
        'height' : (
            22px,
            28px
        ),
        'line-height' : (
            22px,
            28px
        ),
        'padding-left' : (
            16px,
            24px
        ),
        'padding-right' : (
            16px,
            24px
        ),
        'font-size' : (
            12px,
            14px
        ),
    ),
    '.page_offer_preview .infos_program .slider_program .swiper-slide .content_program .info_program' : (
        'padding' : (
            12px,
            16px
        ),
    ),
    '.page_offer_preview .infos_program .slider_program .swiper-slide .content_program .info_program .txt_offer, .page_offer_preview .infos_program .slider_program .swiper-slide .content_program .info_program .txt_offer p, .page_offer_preview .infos_program .slider_program .swiper-slide .content_program .info_program .txt_offer ul li' : (
        'font-size' : (
            11px,
            12px
        ),
        'line-height' : (
            16px,
            18px
        ),
    ),
    '.page_offer_preview .infos_program .slider_program .swiper-slide .content_program .info_program .txt_offer .titre_h2, .page_offer_preview .infos_program .slider_program .swiper-slide .content_program .info_program .txt_offer .titre_h3' : (
        'font-size' : (
            13px,
            15px
        ),
        'line-height' : (
            22px,
            24px
        ),
    ),
    '.page_offer_preview .map_container' : (
        'height' : (
            300px,
            400px
        ),
    ),
);

@include fluid($pageOfferMap);

.page_offer_preview {
    display    : block;
    width      : 100%;
    overflow-x : hidden;
    margin     : auto;
    max-width  : 1200px;
    .back_to_list, .back_to_compo {
        position : relative;
        a, span {
            text-transform  : uppercase;
            color           : $grisfonce;
            text-decoration : underline;
            cursor          : pointer;
            @include transition($transition);
            &:before {
                display        : inline-block;
                vertical-align : middle;
                @include transform(rotate(90deg));
                margin-right   : 12px;
                font-size      : 1em;
                margin-top     : -1px;
            }
        }
        #print {
            position : absolute;
            top      : 0;
            right    : 0;
            &:before {
                font-size : 1.3em;
                @include transform(rotate(0deg));
                color     : inherit;
            }
        }
    }
    .back_to_list {
        border-bottom : 1px solid $txt;
    }
    .back_to_compo {
        span {
            text-transform  : initial;
            text-decoration : none;
            &:before {
                color     : inherit;
                font-size : 0.8em;
            }
        }
        + .choice_date {
            margin-top : 24px;
        }
    }

    .offer_booking {
        border-bottom : 1px solid $txt;
        margin-bottom : 24px;
        padding       : 24px 0;

        .quotation_composition {
            border-bottom : 1px solid $txt;
        }
    }
    .choice_date {
        position : relative;
        .item_date {
            display       : block;
            width         : 100%;
            margin-bottom : 16px;
            .composition-step-label {
                display       : block;
                width         : 100%;
                height        : 32px;
                line-height   : 32px;
                padding       : 0 16px;
                background    : $blanc;
                color         : $noir;
                font-size     : 16px;
                border        : 1px solid $noir;
                border-bottom : none;
                @include radius(3px 3px 0 0);
                &.active {
                    background : $noir;
                    color      : $blanc;
                    + .composition-step-content {
                        @include radius(0 0 3px 3px);
                        border-width     : 2px;
                        border-top-width : 0;
                    }
                }
            }
            .composition-step-content {
                display : block;
                width   : 100%;
                @include radius(3px);
                border  : 1px solid $noir;
            }
            &.disabled {
                .composition-step-label {
                    opacity : 0.6;
                }
                .composition-step-content {
                    opacity        : 0.6;
                    pointer-events : none;
                }
            }
            .choice_item {
                position : relative;
                z-index  : 1;
                &:after {
                    content       : '';
                    position      : absolute;
                    top           : 0;
                    left          : 0;
                    bottom        : -1px;
                    right         : -1px;
                    border-right  : 1px solid $noir;
                    border-bottom : 1px solid $noir;
                    z-index       : 1;
                }
                .content_item {
                    display  : block;
                    width    : 100%;
                    height   : 56px;
                    position : relative;
                    z-index  : 2;
                    label, .label {
                        position    : absolute;
                        left        : 12px;
                        right       : 12px;
                        top         : 8px;
                        font-size   : 13px;
                        line-height : 16px;
                        z-index     : 1;
                    }
                    select, .value, .input {
                        display       : block;
                        width         : 100%;
                        height        : 100%;
                        padding-left  : 12px;
                        padding-right : 12px;
                        padding-top   : 20px;
                        font-size     : 14px;
                        line-height   : 30px;
                        opacity       : 0.8;
                        position      : relative;
                        z-index       : 2;
                        cursor        : pointer;
                        border        : none;
                    }
                }
                &.choice_people {
                    background      : transparent url("../../images/chevron.svg") no-repeat top 36px right 12px;
                    background-size : 13px;
                }
                &.choice_children {
                    .content_item {
                        height : auto;
                        .input {
                            height : 56px;
                        }
                        .fv-plugins-message-container {
                            padding     : 8px 12px;
                            padding-top : 0;
                            div {
                                margin : 0;
                            }
                        }
                    }
                }
                &.disabled {
                    background : $gris2;
                    z-index    : 0;
                    .content_item {
                        select, .value, .input {
                            cursor : default;
                        }
                    }
                }
                .flatpickr-calendar {
                    top       : 100% !important;
                    left      : 0 !important;
                    min-width : 290px;
                }
            }
            .info_step {
                display     : block;
                width       : 100%;
                margin-top  : 8px;
                font-size   : 13px;
                line-height : 16px;
                &.error {
                    color : $danger;
                }
                &.icon_info {
                    position     : relative;
                    padding-left : 24px;
                    &:before {
                        content         : '';
                        @include y_align_absolute;
                        left            : 0;
                        width           : 20px;
                        height          : 20px;
                        background      : transparent url("../../images/icon_info.png") no-repeat center;
                        background-size : 22px;
                    }
                }
            }
        }
        #composition-step-date:not(.col_md_6) {
            .item_date {
                &:not(.disabled) {
                    margin-top : 24px;
                }
                &.disabled {
                    display : none;
                }
            }
        }
        #child-informations-container {
            //&:not(:empty) {
            //    + div {
            //        flex-basis              : 100%;
            //        -webkit-flex-basis      : 100%;
            //        -ms-flex-preferred-size : 100%;
            //        max-width               : 100%;
            //        padding-right           : 12px;
            //    }
            //}
            .composition-data-wrapper {
                .composition-step-content {
                    border        : none;
                    padding-right : 1px;
                    .choice_item:after {
                        border-top  : 1px solid $noir;
                        border-left : 1px solid $noir;
                    }
                }
            }
        }
        .recap_date {
            display    : block;
            width      : 100%;
            margin-top : 12px;
            .name {
                display     : block;
                font-size   : 14px;
                line-height : 18px;
                color       : $grisfonce;
                padding-top : 8px;
                @include media_x_max('sm') {
                    font-size   : 13px;
                    line-height : 16px;
                }
            }
            .price {
                display     : block;
                font-weight : bold;
                font-size   : 18px;
                line-height : 24px;
                color       : $vert;
                @include media_x_max('sm') {
                    font-size   : 16px;
                    line-height : 22px;
                }
            }
            .price-ht {
                display    : block;
                font-size  : 0.7em;
                color      : $noir;
                opacity    : 0.5;
                text-align : right;
            }
            .total {
                .name {
                    opacity : 0.8;
                }
                .price {
                    font-size   : 24px;
                    line-height : 28px;
                    color       : $grisfonce;
                    @include media_x_max('sm') {
                        font-size   : 20px;
                        line-height : 26px;
                    }
                }
            }
            > div {
                + div {
                    margin-top : 6px;
                }
            }
        }
        .validate_date {
            margin-top : 24px;
            @include media_x_max('md') {
                > div + div {
                    margin-top : 16px;
                }
            }
        }
        .mat-mdc-form-field-error {
            padding : 0;
            div {
                margin-top  : 16px;
                font-size   : 12px;
                line-height : 14px;
            }
        }
        .origin_step_form {
            .item_date .choice_item.choice_people.choice_children {
                @extend .col_6;
                @extend .col_md_6;
                @extend .col_lg_4;
            }
        }
        @include media_x_min('md') {
            .content-col-date {
                position : relative;
                &:before {
                    content         : '';
                    position        : absolute;
                    width           : 32px;
                    height          : 32px;
                    top             : 44px;
                    left            : 50%;
                    margin-left     : -16px;
                    background      : transparent url("../../images/chevron.svg") no-repeat center;
                    background-size : 24px;
                    @include transform(rotate(-90deg));
                    opacity         : 0.6;
                }
            }
            .semi-col-date {
                padding-right : 40px;
                + .semi-col-date {
                    padding-left  : 40px;
                    padding-right : 12px;
                }
            }
        }
        @include media_x_max('md') {
            .item_date {
                .composition-step-label {
                    font-size : 15px;
                }
                .choice_item {
                    .content_item {
                        height : 50px;
                        label, .label {
                            left        : 8px;
                            right       : 8px;
                            top         : 5px;
                            font-size   : 12px;
                            line-height : 14px;
                        }
                        select, .value, .input {
                            padding-left  : 8px;
                            padding-right : 8px;
                            padding-top   : 17px;
                            font-size     : 14px;
                            line-height   : 28px;
                        }
                    }
                    &.choice_people {
                        background-position : top 31px right 10px;
                        background-size     : 11px;
                    }
                    &.choice_children {
                        .content_item {
                            .input {
                                height : 50px;
                            }
                            .fv-plugins-message-container {
                                padding     : 6px 8px;
                                padding-top : 0;
                            }
                        }
                    }
                }
            }
            #composition-step-date {
                .item_date {
                    &:not(.disabled) {
                        margin-top : 24px;
                    }
                    &.disabled {
                        display : none;
                    }
                }
            }
        }
    }
    #offer-permanent-options-container {
        margin-top : 24px;
    }
    .recap {
        display    : block;
        margin-top : 24px;
        .title {
            font-weight : bold;
            font-size   : 16px;
            line-height : 20px;
        }
        .ck-content {
            display    : block;
            margin-top : 16px;
            padding    : 8px 16px;
            border     : 1px solid $gris;
            background : $grisclair;
            @include radius(4px);
        }
    }
    .form_booking {
        margin-top : 24px;
    }
    .title_offer {
        .tag {
            display    : block;
            color      : $blanc;
            background : $noir;
            @include radius(6px);
        }
        .title {
            font-weight   : bold;
            margin-bottom : 0;
        }
        ::slotted(.title) {
            font-weight   : bold !important;
            margin-bottom : 0 !important;
            font-size     : 24px !important;
            line-height   : 32px !important;
            @include media_x_max('lg') {
                font-size   : 22px !important;
                line-height : 30px !important;
            }
            @include media_x_max('md') {
                font-size   : 20px !important;
                line-height : 26px !important;
            }
            @include media_x_max('sm') {
                font-size   : 18px !important;
                line-height : 24px !important;
            }
        }
        .infos {
            span {
                display        : inline-block;
                vertical-align : middle;
                color          : rgba($grisfonce, 0.8);
            }
            .offer_type {
                &:before {
                    display        : inline-block;
                    vertical-align : middle;
                    margin-right   : 8px;
                    font-size      : 1.2em;
                    margin-top     : -2px;
                }
                &:after {
                    content        : '';
                    display        : inline-block;
                    vertical-align : middle;
                    width          : 8px;
                    height         : 2px;
                    @include radius(2px);
                    margin         : 0 8px;
                }
            }
        }
    }
    .offer_pictures {
        margin-left  : -4px;
        margin-right : -4px;
        img {
            display    : block;
            width      : 100%;
            height     : 100%;
            @include imageFit;
            background : $grisclair;
        }
        .col_left {
            padding : 0 4px;
            img {
                @include radius(15px 0 0 15px);
                @include media_x_max('md') {
                    @include radius(15px 15px 0 0);
                }
            }
            &:only-child {
                img {
                    @include radius(15px)
                }
            }
        }
        .col_right {
            padding : 0 4px;
            .row {
                @include media_x_max('md') {
                    height : auto;
                }
                margin : 0 -4px;
                .col_6 {
                    padding : 0 4px;
                }
            }
            .col_6 {
                @include media_x_min('md') {
                    height : calc(50% - 4px);
                }
                position : relative;
                @include media_x_max('md') {
                    margin-top : 8px;
                }
                img {
                    position : relative;
                    z-index  : 1;
                }
                .see_more {
                    position   : absolute;
                    top        : 0;
                    left       : 4px;
                    bottom     : 0;
                    right      : 4px;
                    z-index    : 2;
                    background : rgba($grisfonce, 0.6);
                    @include radius(0 0 15px 0);
                    cursor     : pointer;
                    @include transition($transition);
                    span {
                        @include center_align_absolute;
                        color       : $blanc;
                        font-weight : bold;
                        width       : 100%;
                        text-align  : center;
                        &:before {
                            content       : '+';
                            display       : block;
                            margin-bottom : 8px;
                            font-size     : 1.4em;
                        }
                    }
                    @include hover {
                        background : rgba($grisfonce, 0.8);
                    }
                }
                &:nth-of-type(3), &:nth-of-type(4) {
                    margin-top : 8px;
                }
                @include media_x_min('md') {
                    &:nth-of-type(2) img {
                        @include radius(0 15px 0 0)
                    }
                    &:nth-of-type(4) img {
                        @include radius(0 0 15px 0)
                    }
                }
                @include media_x_max('md') {
                    //&:last-child{
                    &:nth-of-type(even) {
                        &:last-child {
                            img, .see_more {
                                @include radius(0 0 15px 0);
                            }
                        }
                        &:nth-last-child(2):not(:nth-child(2)) {
                            img, .see_more {
                                @include radius(0 0 15px 0);
                            }
                        }
                    }
                    &:nth-of-type(odd) {
                        &:last-child {
                            img, .see_more {
                                @include radius(0 0 0 15px);
                            }
                        }
                        &:nth-last-child(2):not(:nth-child(2)) {
                            img, .see_more {
                                @include radius(0 0 0 15px);
                            }
                        }
                    }
                    //}
                }
            }
        }
    }
    .txt_offer {
        display : block;
        width   : 100%;
        + .txt_offer {
            border-top : 1px solid $txt;
        }
        &, p {
            color : $grisfonce;
            strong {
                font-weight : bold;
            }
        }
        .argument {
            color : $txt;
        }
        .title, .title_2 {
            font-weight : bold;
        }
        ::slotted(.title) {
            font-weight   : bold;
            font-size     : 22px !important;
            line-height   : 27px !important;
            margin-bottom : 16px !important;
            @include media_x_max('lg') {
                font-size   : 20px !important;
                line-height : 25px !important;
            }
            @include media_x_max('md') {
                font-size     : 18px !important;
                line-height   : 24px !important;
                margin-bottom : 15px !important;
            }
            @include media_x_max('sm') {
                font-size     : 16px !important;
                line-height   : 22px !important;
                margin-bottom : 12px !important;
            }
        }
        .interest {
            span {
                display        : inline-block;
                vertical-align : middle;
                position       : relative;
                &:before {
                    content     : "\e003";
                    font-family : "ty-win";
                    @include y_align_absolute;
                    left        : 0;
                    font-size   : 0.9em;
                }
            }
        }
        .description_offer {
            max-height : 168px;
            overflow   : hidden;
            &.open {
                max-height : inherit;
            }
        }
        .see_more {
            display  : block;
            width    : 100%;
            position : relative;
            span {
                font-weight : bold;
                cursor      : pointer;
                position    : relative;
                z-index     : 2;
                @include hover {
                    text-decoration : underline;
                }
            }
            &:before {
                content    : '';
                position   : absolute;
                left       : 0;
                bottom     : 0;
                right      : 0;
                height     : 32px;
                background : $blanc;
                background : linear-gradient(180deg, rgba($blanc, 0) 0%, rgba($blanc, 1) 100%);
            }
        }
        &.confirmation {
            margin-top : 16px;
            @include radius(4px);
        }
        &.little {
            .title_2 {
                font-weight : normal;
            }
        }
    }
    .list_providers {
        border-top    : 1px solid $txt;
        border-bottom : 1px solid $txt;
        margin        : 16px 0;
        padding       : 8px 0;
        .providers {
            > div {
                margin-bottom : 16px;
                margin-top    : 16px;
            }
        }
        .provider {
            display    : block;
            width      : 100%;
            height     : 100%;
            text-align : center;
            .visuel {
                height : 100px;
            }
            img {
                display    : block;
                max-width  : 100%;
                max-height : 100%;
                margin     : auto;
            }
            .name {
                p {
                    display       : block;
                    font-size     : 13px;
                    line-height   : 16px;
                    width         : 100%;
                    margin-bottom : 0;
                    &:first-child {
                        margin-top : 6px;
                    }
                }
            }
        }
    }
    .accordions {
        display       : block;
        width         : 100%;
        margin-bottom : 24px;
        .accordion {
            .txt_offer {
                border-top  : none !important;
                padding-top : 0 !important;
                &:last-child {
                    margin-bottom : 0;
                }
            }
        }
    }
    .infos_program {
        .nav_slider_program {
            button {
                display        : inline-flex;
                vertical-align : middle;
                + button {
                    margin-left : 16px;
                }
            }
        }
        .slider_program {
            overflow : visible;
            margin   : 0 -8px;
            width    : calc(100% + 16px);
            .swiper-pagination {
                display : none;
            }
            .swiper-wrapper {
                height : auto;
                .swiper-slide {
                    position      : relative;
                    padding-left  : 8px;
                    padding-right : 8px;
                    //		opacity       : 0;
                    @include transition($transition);
                    .day {
                        position : absolute;
                        top      : 0;
                        left     : 8px;
                        color    : $blanc;
                        @include radius(7px 7px 0 0);
                    }
                    .content_program {
                        @include radius(0 7px 7px 7px);
                        @include shadow();
                        background : $blanc;
                        position   : relative;
                        overflow   : hidden;
                        &:after {
                            content  : '';
                            position : absolute;
                            top      : 0;
                            left     : 0;
                            bottom   : 0;
                            right    : 0;
                            @include radius(0 7px 7px 7px);
                        }
                        .info_program {
                            .txt_offer {
                                margin : 0;
                            }
                        }
                    }
                    img {
                        display : block;
                        width   : 100%;
                        height  : 100%;
                        @include imageFit;
                    }
                    &:only-child {
                        padding-top : 0;
                        .content_program {
                            @include radius(7px);
                            &:after {
                                display : none;
                            }
                        }
                    }
                    //		&.swiper-slide-active, &.swiper-slide-next {
                    //			opacity : 1;
                    //		}
                }
            }
        }
    }
    .map_container {
        svg {
            .line_map {
                stroke : rgba($grisfonce, 0.4);
            }
        }
        .leaflet-popup-tip-container {
            bottom : -10px;
            div {
                @include shadow(none);
            }
        }
        .leaflet-tooltip {
            padding     : 2px 8px;
            font-weight : bold !important;
            font-size   : 13px;
        }
        .leaflet-popup-content {
            padding : 13px 19px;
            p {
                margin      : 0;
                font-size   : 13px;
                line-height : 17px;
                &:nth-of-type(1) {
                    font-style    : italic;
                    font-size     : 0.9em;
                    margin-bottom : 6px;
                }
                &:nth-of-type(2) {
                    font-weight   : bold;
                    font-size     : 1.2em;
                    margin-bottom : 2px;
                }
            }
        }
    }
    .option_offer {
        display : block;
        width   : 100%;
        .option {
            width    : 100%;
            border   : 1px solid $grisfonce;
            @include radius(7px 7px 0 7px);
            overflow : hidden;
            .visuel {
                display  : block;
                width    : 100%;
                height   : 100%;
                position : relative;
				img {
					display : block;
					width   : 100%;
				}
            }
            .infos {
                padding : 16px;
                .users {
                    p {
                        display        : inline-block;
                        vertical-align : middle;
                        font-size      : 14px;
                        margin         : 0;
                        &:before {
                            display        : inline-block;
                            vertical-align : middle;
                            font-size      : 1.2em;
                            margin-right   : 8px;
                        }
                        + p {
                            margin-left : 24px;
                        }
                    }
                }
                a.terms_and_condition {
                    text-decoration : underline;
                    cursor          : pointer;
                    @include hover {
                        color : $vert;
                    }
                }
                @include media_x_max('sm') {
                    padding : 12px;
                    .form-group {
                        select {
                            padding-left        : 8px;
                            padding-right       : 8px;
                            background-position : center right 4px;
                            ~ label {
                                left          : 8px;
                                white-space   : nowrap;
                                overflow      : hidden;
                                text-overflow : ellipsis;
                            }
                        }
                    }
                }
            }
        }
        .check_option > div {
            padding : 16px;
            @include radius(0 0 7px 7px);
            .form-group {
                .checkbox {
                    margin : 0;
                    label {
                        font-size   : 15px;
                        font-weight : bold;
                    }
                }
            }
        }
        + .option_offer {
            margin-top : 40px;
        }
        .choice_day {
            > div {
                display : block;
                width   : 100%;
            }
            span.label span {
                display : inline;
            }
            input[type=range] {
                margin-top         : 12px;
                background         : $gris;
                padding            : 0;
                height             : 6px;
                @include radius(3px);
                width              : 100%;
                max-width          : 130px;
                appearance         : none;
                -moz-appearance    : none;
                -webkit-appearance : none;
                &::-webkit-slider-thumb {
                    appearance         : none;
                    -moz-appearance    : none;
                    -webkit-appearance : none;
                    border-radius      : 50%;
                    cursor             : pointer;
                    width              : 16px;
                    height             : 16px;
                }
                &::-moz-range-thumb {
                    appearance         : none;
                    -moz-appearance    : none;
                    -webkit-appearance : none;
                    border-radius      : 50%;
                    cursor             : pointer;
                    width              : 16px;
                    height             : 16px;
                }
                &::-ms-thumb {
                    appearance         : none;
                    -moz-appearance    : none;
                    -webkit-appearance : none;
                    border-radius      : 50%;
                    cursor             : pointer;
                    width              : 16px;
                    height             : 16px;
                }
                &:disabled {
                    cursor : default;
                }
            }
        }
    }
}
