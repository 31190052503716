app-gift-voucher-configuration-form {

    p.title {
        font-weight   : bold;
        margin-bottom : 20px;
    }
    .border {
        border  : 1px solid #eaeaea;
        padding : 10px;

        & > div {
            margin-bottom : 20px;
        }
    }

    .flags {
        position         : absolute;
        left             : 0;
        top              : 0;
        right            : 0;
        padding          : 12px;
        padding-bottom   : 0;
        background-color : rgba($blanc, 0.4);
        z-index          : 2;
        .flag {
            display       : inline-block;
            width         : 18px;
            height        : 12px;
            margin-bottom : 6px;
            &:not(:last-child) {
                margin-right : 6px;
            }
        }
        .flag-fr {
            background      : url("../../images/icons/icon_flag_fr.svg") no-repeat center;
            background-size : contain;
        }

        .flag-en {
            background      : url("../../images/icons/icon_flag_en.svg") no-repeat center;
            background-size : cover;
        }

        .flag-pt {
            background      : url("../../images/icons/icon_flag_pt.svg") no-repeat center;
            background-size : contain;
        }

        .flag-it {
            background      : url("../../images/icons/icon_flag_it.svg") no-repeat center;
            background-size : contain;
        }

        .flag-es {
            background      : url("../../images/icons/icon_flag_es.svg") no-repeat center;
            background-size : contain;
        }

        .flag-de {
            background      : url("../../images/icons/icon_flag_de.svg") no-repeat center;
            background-size : contain;
        }

        .flag-nl {
            background      : url("../../images/icons/icon_flag_nl.svg") no-repeat center;
            background-size : contain;
        }
    }

    // Visuel des templates
    .content_card {
        max-width : 280px;
    }

    .card_template {
        display       : block;
        width         : 100%;
        height        : 100%;
        border        : 1px solid $gris;
        @include transition($transition);
        @include shadow(0 0 11px 0 rgba($gris, 0.5));
        border-radius : 6px;
        position      : relative;

        .btn_cta {
            text-transform : uppercase;
            font-size      : .9em;

        }

        &.selected {
            border : 2px solid $vert;

            .btn_cta {
                background : $vert;
                color      : white;

                &:hover {
                    background : $vert;
                    color      : white;
                }
            }
        }

        .card_top {
            padding : 10px 0;

        }

        .buttons {

            position : absolute;
            right    : 0;
            bottom   : 0;
            z-index  : 2;

            button {
                display  : block;
                margin   : 10px;
                width    : 36px;
                height   : 36px;
                position : relative;
                @include radius(50%);

                mat-icon {
                    @include center_align_absolute;
                    font-size   : 22px;
                    line-height : 22px;
                }

                &:nth-child(1) {
                    background : white;
                }

                &:nth-child(2) {
                    background : red;
                    color      : white;
                }
            }
        }

        .card_bottom {

            .visual {
                overflow      : hidden;
                height        : 150px;
                margin-bottom : 10px;
                position      : relative;
                img {
                    position : relative;
                    z-index  : 1;
                }
                .delivery {
                    position : absolute;
                    z-index  : 2;
                    left     : 5px;
                    bottom   : 5px;
                    > div {
                        display        : inline-block;
                        vertical-align : middle;
                        margin         : 5px;
                        width          : 32px;
                        height         : 32px;
                        @include radius(50%);
                        background     : rgba($noir, 0.6);
                        position       : relative;
                        &:after {
                            content : '';
                            @include center_align_absolute;
                            width   : 18px;
                            height  : 18px;
                        }
                        &.demat:after {
                            background      : url("../../images/icons/icon_bc_dematerialized.png") no-repeat center;
                            background-size : cover;
                            margin-left     : -1px;
                        }
                        &.physic:after {
                            background      : url("../../images/icons/icon_bc_physical.png") no-repeat center;
                            background-size : cover;
                        }
                    }
                }
            }

            .infos {
                padding : 10px 10px 15px 10px;
            }

            img {
                display  : block;
                position : relative;
                z-index  : 1;
                width    : 100%;
                height   : 100%;
                @include imageFit;
            }
        }
    }

}

.gift-voucher-generique-tab {
    .mat-mdc-tab-header {
        display : none;
    }
}
