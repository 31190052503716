.multiple_selection {
	margin-bottom : 16px;
}

.subscription_information, .self_reference, .provider_description, .price_config_description, .deletable_information, .offer_multiple_translations {
	padding       : 16px;
	border        : 1px solid $vert;
	background    : rgba($vert, 0.2);
	margin-bottom : 10px;
	margin-top    : 5px;
	text-align    : center;
	@include radius(4px);
	a {
		font-weight : bold;
		color       : $noir;
	}
	p {
		margin-bottom : 0;
		mat-icon {
			display        : inline-block;
			vertical-align : middle;
		}
	}
	&.added_bottom_margin {
		margin-bottom : 20px;
	}
	&.align_left {
		text-align : left;
	}
}

.search_no_result {
	padding       : 16px;
	border        : 1px solid $warning;
	background    : rgba($warning, 0.2);
	margin-bottom : 10px;
	margin-top    : 10px;
	text-align    : center;
	@include radius(4px);
	a {
		font-weight : bold;
		color       : $noir;
	}
	p {
		margin-bottom : 0;
	}
}

.self_reference {

	.self_reference_value {
		font-weight : bold;
	}
}

.offer_dates {
	margin-bottom : 10px;
}

.bloc_offers {
	width : 100%;
	@include radius(8px);
	@include shadow();

	.offers_list {
		display     : block;
		width       : 100%;
		position    : relative;
		padding     : 32px;
		padding-top : 64px;
		min-height  : 450px;

		.list_links {
			position : absolute;
			top      : 16px;
			left     : calc(32px + 12px); // Same padding as offers_list + padding left on .content_offers to have box shadow visible
			right    : calc(32px + 12px); // Same padding as offers_list + padding right on .content_offers to have box shadow visible
			z-index  : 2;

			[class*='ty-'] {
				font-size : 14px;
				@include font_lato_bold;
				color     : $grisfonce;

				&:before {
					display        : inline-block;
					vertical-align : middle;
					font-size      : 18px;
					margin         : 0 8px;
				}
			}

			.night_filter_container {
				display        : inline-block;
				vertical-align : middle;
				margin-right   : 16px;
				.night_filter_toggle {
					background : rgba($vert, 0.2);
					@include radius(20px);
					.night_filter_tile_wrapper {
						margin : 0;
						height : 100%;
						.night_filter_tile {
							border      : none;
							font-weight : 500;
							padding     : 8px 24px;
							background  : transparent;
							cursor      : pointer;
							@include shadow(none);
							@include radius(20px);
							font-size   : 14px;
							line-height : 20px;
							height      : 40px;
							&.tile_checked {
								background : $vert;
							}
						}
					}
					@include media_x_max('sm') {
						.night_filter_tile_wrapper {
							.night_filter_tile {
								padding     : 8px 16px;
								font-size   : 13px;
								line-height : 20px;
								height      : 100%;
							}
						}
					}
				}
			}

			mat-radio-group {
				display        : inline-block;
				vertical-align : middle;
				margin-right   : 16px;

				mat-radio-button + mat-radio-button {
					margin-left : 16px;
				}
				.mdc-radio__background {
					&:before {
						display : none;
					}
					.mdc-radio__outer-circle {
						width        : 18px;
						height       : 18px;
						border-color : rgba($grisfonce, 0.5)
					}
					.mdc-radio__inner-circle {
						width  : 18px;
						height : 18px;
						top    : -1px;
						left   : -1px;
					}
				}

				.mat-ripple {
					display : none;
				}
				mat-radio-button.mat-mdc-radio-checked {
					.mdc-radio__background {
						.mdc-radio__outer-circle {
							border-color : $vert !important;
						}
						.mdc-radio__inner-circle {
							border-color : $vert !important;
						}
					}
				}
			}
		}

		.content_offers {
			display  : block;
			width    : 100%;
			position : relative;
			z-index  : 1;
			padding  : 0 12px;

			app-core-offer-list {
				display : block;
				.separator {
					border : 2px solid $vert;
					margin : 24px 0;
				}
			}

			app-core-offer-map {
				display : block;
				height  : 600px;

				.map_container {
					height  : 100%;
					z-index : 1;
				}
			}

			app-core-offer-card {
				display : block;
				width   : 100%;
				height  : 100%;
			}

			.more_offer {
				margin-top : 32px;
			}
		}

		.hashtag_filter_item_container {
			display      : flex;
			margin-right : 24px;
			align-items  : center;
			height       : 100%;
			.title {
				font-weight : 500;
			}
			.filter_item {
				@include d_flex;
				@include y_items_center;
				width : calc(20% - ((12px * 4) / 5) - (24px / 5)); // 5 is the number of filters
				> * {
					display : block;
					width   : 100%;
				}
				& + .filter_item {
					margin-left : 12px;
				}

				// All filters
				.mat-mdc-form-field {
					width : 100%;
					.mat-mdc-form-field-subscript-wrapper {
						display : none;
					}
					.mat-mdc-text-field-wrapper {
						padding : 0;
						width   : 100%;
						.mat-mdc-form-field-bottom-align, .mdc-line-ripple, .mat-mdc-form-field-focus-overlay {
							display : none
						}
						.mat-mdc-form-field-flex {
							background-color : $blanc;
							padding          : .25em 0;
							.mat-mdc-form-field-infix {
								padding          : 0;
								border-top-width : 0.5em;
								width            : auto;
								min-height       : inherit;
								mat-select {
									display : block;
									width   : 100%;
									height  : 100%;
									padding : 0;
									.mat-mdc-select-trigger {
										display : block;
										&, .mat-mdc-select-value {
											width     : 100%;
											max-width : 100%;
											height    : 100%;
											padding   : 0;
											position  : relative;
										}
										.mat-mdc-select-value {
											@include d_flex;
											@include x_start;
											@include y_items_center;
											font-size     : 13px;
											color         : $noir !important;
											padding-right : 14px;
											padding-top   : 5px;
										}
									}
									.mat-mdc-select-arrow-wrapper {
										@include y_align_absolute;
										right  : 0;
										width  : 12px;
										height : 12px;
										&:after {
											content     : "\e004";
											font-family : "ty-win";
											display     : block;
											font-size   : 11px;
											color       : $noir;
										}
										> * {
											display : none;
										}
									}
								}
								label {
									position    : absolute;
									font-size   : 13px;
									line-height : 16px;
									top         : 6px;
									left        : 0;
									padding     : 0;
									margin      : 0;
									width       : 100%;
									color       : $noir !important;
									opacity     : 0.5;
									@include transform(translateY(0) !important);
									@include transition($transition);
									&.mdc-floating-label--float-above {
										top         : 0;
										font-size   : 10px;
										line-height : 12px;
									}
								}
							}
						}
					}
				}
			}

			.hashtag_filter_item {
				margin-left : 24px;
				width       : 160px;
			}
		}

        .part_no_result {
            font-weight: 500;
            padding: 16px;
            margin-bottom: 10px;
            margin-top: 24px;
            text-align: center;
            a {
                font-weight : bold;
                color       : $noir;
            }
            p {
                margin-bottom : 0;
            }
        }
	}
	.filters_checked {
		padding   : 10px;
		display   : flex;
		flex-wrap : wrap;

		& > span {
			@include font-lato_regular;
			display     : flex;
			flex-shrink : 0;
		}

		.filter {
			position         : relative;
			display          : flex;
			align-items      : center;
			background-color : $noir;
			color            : $blanc;
			@include radius(30px);
			cursor           : pointer;
			padding          : 5px 25px 5px 10px;
			margin           : 5px 8px 0 0;

			.close {
				position : absolute;
				right    : 0;
				top      : 5px;

				mat-icon {
					font-size : 20px;
				}
			}
			&:hover {
				background : #999999;
			}
		}
	}
}

.selected_offers {
	margin-bottom : 10px;
	display       : flex;
	margin-top    : 24px;

	span {
		@include font-lato_regular;
		display      : flex;
		flex-shrink  : 0;
		margin-right : 12px;
		padding-top  : 8px; // Align with li's text
	}

	ul {
		display   : flex;
		flex-wrap : wrap;

		li {
			background-color : $noir;
			color            : $blanc;
			@include radius(30px);
			padding          : 8px 12px;
			margin-bottom    : 4px;

			mat-icon {
				display        : inline-block;
				vertical-align : middle;
				margin-left    : 5px;
				font-size      : 16px;
				line-height    : 16px;
				width          : 16px;
				height         : 16px;
				margin-top     : -2px;
				cursor         : pointer;
			}
		}

		li + li {
			margin-left : 8px;
		}
	}
}

.selected_items {
	margin-bottom : 12px;
	display       : flex;
	margin-top    : 12px;

	span {
		@include font-lato_regular;
		display      : flex;
		flex-shrink  : 0;
		margin-right : 12px;
		padding-top  : 8px; // Align with li's text
	}

	ul {
		display   : flex;
		flex-wrap : wrap;

		li {
			background-color : $noir;
			color            : $blanc;
			@include radius(30px);
			padding          : 8px 12px;
			margin-bottom    : 4px;

			mat-icon {
				display        : inline-block;
				vertical-align : middle;
				margin-left    : 5px;
				font-size      : 16px;
				line-height    : 16px;
				width          : 16px;
				height         : 16px;
				margin-top     : -2px;
				cursor         : pointer;
			}
		}

		li + li {
			margin-left : 8px;
		}
	}
}

.offer-search-list {
	display       : block;
	width         : 100%;
	margin-bottom : 30px;
	.offer-search-item {
		border-bottom : 1px solid $gris;
		padding       : 10px 0;
		a, span, img {
			display        : inline-block;
			vertical-align : middle;
			font-size      : 14px;
			line-height    : 18px;
			color          : $noir;
		}
		a {
			margin-right : 16px;
			@include hover {
				text-decoration : underline;
			}
		}
		img {
			width       : 16px;
			margin-left : 8px;
			margin-top  : -1px;
		}
		.offer-reference {
			opacity : 0.5;
		}
	}
}
