$compAccordionMap : (
    '.accordion' : (
        'margin-bottom' : (
            12px,
            16px
        ),
    ),
    '.accordion .accordion_header' : (
        'padding-top' : (
            8px,
            12px
        ),
        'padding-bottom' : (
            8px,
            12px
        ),
        'padding-left' : (
            12px,
            16px
        ),
        'padding-right' : (
            12px,
            16px
        ),
    ),
    '.accordion .accordion_header span' : (
        'font-size' : (
            12px,
            14px
        ),
        'line-height' : (
            18px,
            21px
        ),
    ),
    '.accordion .accordion_body' : (
        'padding-left' : (
            12px,
            16px
        ),
        'padding-right' : (
            12px,
            16px
        ),
    ),
    '.accordion.open .accordion_body' : (
        'padding' : (
            12px,
            16px
        ),
    ),
);

@include fluid($compAccordionMap);

.accordion {
    border     : 1px solid $grisfonce;
    @include radius(4px !important);
    box-shadow : none !important;
    .accordion_header {
        cursor : pointer;
        span {
            display    : block;
            margin-top : -2px;
            position   : relative;
            color      : $grisfonce;
            &:after {
                content     : "\e004";
                font-family : "ty-win";
                position    : absolute;
                right       : 0;
                width       : 16px;
                height      : 16px;
                line-height : 16px;
                font-size   : 12px;
                text-align  : center;
                top         : 50%;
                margin-top  : -7px;
                @include transition($transition);
            }
        }
    }
    .accordion_body {
        height     : 0;
        overflow   : hidden;
        border-top : 0 solid transparent;
        @include transition($transition);
        > div {
            visibility : hidden;
            opacity    : 0;
            @include transition($transition);
        }
    }
    &.open {
        .accordion_header {
            span:after {
                @include transform(rotate(-180deg))
            }
        }
        .accordion_body {
            height     : auto;
            border-top : 1px solid $gris;
            > div {
                visibility : visible;
                opacity    : 1;
            }
        }
    }
}
